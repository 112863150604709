import React, { Fragment } from "react"
import { Col, FormControl, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected, setValue } from "~/redux/action"
import {
	SET_ADM_CUSTOM_DEFAULT_NEW_INTERVENTION_ACTIVE,
	SET_ADM_CUSTOM_DEFAULT_NEW_INTERVENTION_DESCRIPTION,
	SET_ADM_CUSTOM_DEFAULT_NEW_INTERVENTION_NAME,
} from "~/redux/type"
import ToggleCheckBox from "~/view/component/toggle/ToggleCheckBox"

class ConfigInterventionModalNDInfo extends React.Component {
	constructor(props) {
		super(props)

		this.onName = this.onName.bind(this)
		this.onDescription = this.onDescription.bind(this)
		this.onActive = this.onActive.bind(this)
	}

	componentDidMount() {
		let intervention = this.props.intervention.selectedIntervention.intervention
		if (intervention) {
			this.props.setValue(SET_ADM_CUSTOM_DEFAULT_NEW_INTERVENTION_NAME, intervention.name)
			this.props.setValue(SET_ADM_CUSTOM_DEFAULT_NEW_INTERVENTION_DESCRIPTION, intervention.description)
			this.props.setValue(SET_ADM_CUSTOM_DEFAULT_NEW_INTERVENTION_ACTIVE, intervention.active)
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_ADM_CUSTOM_DEFAULT_NEW_INTERVENTION_NAME, "")
		this.props.setValue(SET_ADM_CUSTOM_DEFAULT_NEW_INTERVENTION_DESCRIPTION, "")
		this.props.setValue(SET_ADM_CUSTOM_DEFAULT_NEW_INTERVENTION_ACTIVE, true)
	}

	onName(event) {
		this.props.setValue(SET_ADM_CUSTOM_DEFAULT_NEW_INTERVENTION_NAME, event.target.value)
	}

	onDescription(event) {
		this.props.setValue(SET_ADM_CUSTOM_DEFAULT_NEW_INTERVENTION_DESCRIPTION, event.target.value)
	}

	onActive(event) {
		this.props.setValue(SET_ADM_CUSTOM_DEFAULT_NEW_INTERVENTION_ACTIVE, event.target.checked)
	}

	render() {
		return (
			<Fragment>
				<Row>
					<Col md={3}>
						<label>{translate(ResourceAssistance.Message.name)}</label>
					</Col>
					<Col>
						<FormControl
							required
							type={ResourceAssistance.FormControl.type.text}
							value={this.props.intervention.modalNewIntervention.name}
							onChange={this.onName}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={3}>
						<label>{translate(ResourceAssistance.Message.description)}</label>
					</Col>
					<Col>
						<FormControl
							required
							as={ResourceAssistance.FormControl.as.textArea}
							rows={5}
							value={this.props.intervention.modalNewIntervention.description}
							onChange={this.onDescription}
						/>
					</Col>
				</Row>
				<Row className={"g-0"}>
					<Col md={3}>
						<label>{translate(ResourceAssistance.Message.active)}</label>
					</Col>
					<Col>
						<ToggleCheckBox onClick={this.onActive} checked={this.props.intervention.modalNewIntervention.isActive} />
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	intervention: state.admin.customDefaultConfig.intervention,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ConfigInterventionModalNDInfo))
