import React from "react"
import { connect } from "react-redux"
import { Route } from "react-router-dom"
import { ResourceAssistance } from "~/i18n"
import ReportFinanceARA from "~/report/finance/_modal/accounts_receivable_aging/ReportFinanceARA"
import ReportFinanceGeneralLedger from "~/report/finance/_modal/general_ledger/ReportFinanceGeneralLedger"
import ReportCashierAdmissionFilter from "~/report/hospitel/cashier/modal/admission/ReportCashierAdmissionFilter"
import ReportCashierBillingToTodayFilter from "~/report/hospitel/cashier/modal/billingToToday/ReportCashierBillingToTodayFilter"
import ReportCashierUserFilter from "~/report/hospitel/cashier/modal/user/ReportCashierUserFilter"
import ReportHospitelPatientFilter from "~/report/hospitel/hospitel/modal/patient/ReportHospitelPatientFilter"
import ReportLabCovid19LabFilter from "~/report/hospitel/lab/modal/covid19/ReportLabCovid19LabFilter"
import ReportInvByLocationFilter from "~/report/inv/modal/ReportInvByLocationFilter"
import ReportPOByIdFilter from "~/report/po/modal/report_by_id/ReportPOByIdFilter"
import ReportPOByLocationFilter from "~/report/po/modal/report_by_location/ReportPOByLocationFilter"
import ReportTFByLocationFilter from "~/report/tf/modal/report_by_location/ReportTFByLocationFilter"
import DepositModalSearchPatient from "~/view/HIS/deposit/modal/search-patient/DepositModalSearchPatient"
import DepositModalWithdraw from "~/view/HIS/deposit/modal/withdraw/DepositModalWithdraw"
import FrontInventoryModalEdit from "~/view/HIS/front-inventory/_modal/edit/FrontInventoryModalEdit"
import FIModalSplit from "~/view/HIS/front-inventory/_modal/split/FIModalSplit"
import FIModalSplitEdit from "~/view/HIS/front-inventory/_modal/split/edit/FIModalSplitEdit"
import FrontInventoryModalWaste from "~/view/HIS/front-inventory/_modal/waste/FrontInventoryModalWaste"
import FrontInventoryModalWasteEdit from "~/view/HIS/front-inventory/_modal/waste/edit/FrontInventoryModalWasteEdit"
import OPDModalAdjustment from "~/view/HIS/opd/modal/adjustment/OPDModalAdjustment"
import OPDModalAppointmentEdit from "~/view/HIS/opd/modal/appointment-edit/OPDModalAppointmentEdit"
import OPDModalAppointment from "~/view/HIS/opd/modal/appointment/OPDModalAppointment"
import OPDModalDoctorInstruction from "~/view/HIS/opd/modal/doctor-instruction/OPDModalDoctorInstruction"
import OutpatientModalDoctorOrderInfo from "~/view/HIS/opd/modal/doctor-order-info/OutpatientModalDoctorOrderInfo"
import OPDModalDoctorService from "~/view/HIS/opd/modal/doctor-service/OPDModalDoctorService"
import OutpatientModalDoctor from "~/view/HIS/opd/modal/doctor/OutpatientModalDoctor"
import OPDModalGCS from "~/view/HIS/opd/modal/glasgow-coma-scale/OPDModalGCS"
import OPDModalICD from "~/view/HIS/opd/modal/icd/OPDModalICD"
import OPDModalIntakeOutput from "~/view/HIS/opd/modal/intake-output/OPDModalIntakeOutput"
import OPDModalLocation from "~/view/HIS/opd/modal/location/OPDModalLocation"
import OPDModalMedicalCertificate from "~/view/HIS/opd/modal/medical-certificate/OPDModalMedicalCertificate"
import OPDModalMedicalRecord from "~/view/HIS/opd/modal/medical-record/OPDModalMedicalRecord"
import OPDModalMedicalSupply from "~/view/HIS/opd/modal/medical-supply/OPDModalMedicalSupply"
import OPDModalModifyDate from "~/view/HIS/opd/modal/modify-date/OPDModalModifyDate"
import OutpatientModalNurseOrderInfo from "~/view/HIS/opd/modal/nurse-order-info/OutpatientModalNurseOrderInfo"
import OPDModalNursePreOperativeCheckList from "~/view/HIS/opd/modal/nurse-pre-operative-checklist/OPDModalNursePreOperativeCheckList"
import OPDModalNurseService from "~/view/HIS/opd/modal/nurse-service/OPDModalNurseService"
import OPDModalNurse from "~/view/HIS/opd/modal/nurse/OPDModalNurse"
import OutpatientModalORCalendar from "~/view/HIS/opd/modal/operating-room-calendar/OutpatientModalORCalendar"
import OutpatientModalORRequestInfo from "~/view/HIS/opd/modal/operating-room-request-info/OutpatientModalORRequestInfo"
import OutpatientModalOR from "~/view/HIS/opd/modal/operating-room/OutpatientModalOR"
import OPDModalPainScale from "~/view/HIS/opd/modal/pain-scale/OPDModalPainScale"
import OPDModalPupilSize from "~/view/HIS/opd/modal/pupil-size/OPDModalPupilSize"
import OPDModalSearch from "~/view/HIS/opd/modal/search/OPDModalSearch"
import OPDModalVitalSign from "~/view/HIS/opd/modal/vital-sign/OPDModalVitalSign"
import OperatingRoomModalIntraAneAirway from "~/view/HIS/operating-room/_modal/anesthesia-intra/airway-management/OperatingRoomModalIntraAneAirway"
import OperatingRoomModalIntraAneTechnique from "~/view/HIS/operating-room/_modal/anesthesia-intra/anesthetic-technique/OperatingRoomModalIntraAneTechnique"
import OperatingRoomModalIntraAneBabyDelivery from "~/view/HIS/operating-room/_modal/anesthesia-intra/baby-delivery/OperatingRoomModalIntraAneBabyDelivery"
import ORModalIntraAneIO from "~/view/HIS/operating-room/_modal/anesthesia-intra/intake-output/ORModalIntraAneIO"
import ORModalIntraAneMedication from "~/view/HIS/operating-room/_modal/anesthesia-intra/medication/ORModalIntraAneMedication"
import OperatingRoomModalIntraAnePatientCondition from "~/view/HIS/operating-room/_modal/anesthesia-intra/patient-condition/OperatingRoomModalIntraAnePatientCondition"
import OperatingRoomModalIntraAnePatientSafety from "~/view/HIS/operating-room/_modal/anesthesia-intra/patient-safety/OperatingRoomModalIntraAnePatientSafety"
import OperatingRoomModalIntraAnePreinductionAssessment from "~/view/HIS/operating-room/_modal/anesthesia-intra/preinduction-assessment/OperatingRoomModalIntraAnePreinductionAssessment"
import OperatingRoomModalIntraAnePremedication from "~/view/HIS/operating-room/_modal/anesthesia-intra/premedication/OperatingRoomModalIntraAnePremedication"
import OperatingRoomModalIntraAneProblem from "~/view/HIS/operating-room/_modal/anesthesia-intra/problem/OperatingRoomModalIntraAneProblem"
import OperatingRoomModalIntraAneRecordTime from "~/view/HIS/operating-room/_modal/anesthesia-intra/record-time/OperatingRoomModalIntraAneRecordTime"
import OperatingRoomModalIntraAneRecord from "~/view/HIS/operating-room/_modal/anesthesia-intra/record/OperatingRoomModalIntraAneRecord"
import ORModalIntraAneService from "~/view/HIS/operating-room/_modal/anesthesia-intra/service/ORModalIntraAneService"
import OperatingRoomModalIntraAneTransfer from "~/view/HIS/operating-room/_modal/anesthesia-intra/transfer/OperatingRoomModalIntraAneTransfer"
import ORModalIntraAneVS from "~/view/HIS/operating-room/_modal/anesthesia-intra/vital-sign/ORModalIntraAneVS"
import ORModalIntraAneVSNew from "~/view/HIS/operating-room/_modal/anesthesia-intra/vital-sign/_modal/ORModalIntraAneVSNew"
import OperatingRoomModalPostAneComplication from "~/view/HIS/operating-room/_modal/anesthesia-post/complication/OperatingRoomModalPostAneComplication"
import OperatingRoomModalPostAneDischarge from "~/view/HIS/operating-room/_modal/anesthesia-post/discharge-criteria/OperatingRoomModalPostAneDischarge"
import ORModalPostAneIO from "~/view/HIS/operating-room/_modal/anesthesia-post/intake_output/ORModalPostAneIO"
import ORModalPostAneMedication from "~/view/HIS/operating-room/_modal/anesthesia-post/medication/ORModalPostAneMedication"
import OperatingRoomModalPostAneNurse from "~/view/HIS/operating-room/_modal/anesthesia-post/nurse/OperatingRoomModalPostAneNurse"
import OperatingRoomModalPostAnePADSS from "~/view/HIS/operating-room/_modal/anesthesia-post/paddss/OperatingRoomModalPostAnePADSS"
import OperatingRoomModalPostAneRecordTime from "~/view/HIS/operating-room/_modal/anesthesia-post/record-time/OperatingRoomModalPostAneRecordTime"
import OperatingRoomModalPostAneRecord from "~/view/HIS/operating-room/_modal/anesthesia-post/record/OperatingRoomModalPostAneRecord"
import ORModalPostAneService from "~/view/HIS/operating-room/_modal/anesthesia-post/service/ORModalPostAneService"
import OperatingRoomModalPostAneTransfer from "~/view/HIS/operating-room/_modal/anesthesia-post/transfer/OperatingRoomModalPostAneTransfer"
import ORModalPostAneVS from "~/view/HIS/operating-room/_modal/anesthesia-post/vital-sign/ORModalPostAneVS"
import ORModalPostAneVSNew from "~/view/HIS/operating-room/_modal/anesthesia-post/vital-sign/_modal/ORModalPostAneVSNew"
import OperatingRoomModalCancel from "~/view/HIS/operating-room/_modal/cancel/OperatingRoomModalCancel"
import OperatingRoomModalEditPreOperativeCheckList from "~/view/HIS/operating-room/_modal/edit-pre-operative-check-lists/OperatingRoomModalEditPreOperativeCheckList"
import OperatingRoomModalEditPre from "~/view/HIS/operating-room/_modal/edit-pre-operative/OperatingRoomModalEditPre"
import OperatingRoomModalEditRequest from "~/view/HIS/operating-room/_modal/edit-request/OperatingRoomModalEditRequest"
import OperatingRoomModalLocation from "~/view/HIS/operating-room/_modal/location/OperatingRoomModalLocation"
import OperatingRoomModalRemark from "~/view/HIS/operating-room/_modal/remark/OperatingRoomModalRemark"
import OperatingRoomModalSchedule from "~/view/HIS/operating-room/_modal/schedule/OperatingRoomModalSchedule"
import OperatingRoomModalSurgicalSafetyCheckListSignIn from "~/view/HIS/operating-room/_modal/surgical-safety-checklist-sign-in/OperatingRoomModalSurgicalSafetyCheckListSignIn"
import OperatingRoomModalSurgicalSafetyCheckListSignOut from "~/view/HIS/operating-room/_modal/surgical-safety-checklist-sign-out/OperatingRoomModalSurgicalSafetyCheckListSignOut"
import OperatingRoomModalSurgicalSafetyCheckListTimeOut from "~/view/HIS/operating-room/_modal/surgical-safety-checklist-time-out/OperatingRoomModalSurgicalSafetyCheckListTimeOut"
import OperatingRoomModalVitalSign from "~/view/HIS/operating-room/_modal/vital-sign/OperatingRoomModalVitalSign"
import PharmacyModalLocation from "~/view/HIS/pharmacy/_modal/location/PharmacyModalLocation"
import PharmacyModalReturn from "~/view/HIS/pharmacy/_modal/return/PharmacyModalReturn"
import PharmacyModalReturnEdit from "~/view/HIS/pharmacy/_modal/return/edit/PharmacyModalReturnEdit"
import PharmacyModalSearch from "~/view/HIS/pharmacy/_modal/search/PharmacyModalSearch"
import PharmacyModalVerify from "~/view/HIS/pharmacy/_modal/verify/PharmacyModalVerify"
import SurgicalAuthModalBudget from "~/view/HIS/surgical-authorization/_modal/budget/SurgicalAuthModalBudget"
import SurgicalAuthModalCost from "~/view/HIS/surgical-authorization/_modal/cost/SurgicalAuthModalCost"
import SurgicalAuthModalInspect from "~/view/HIS/surgical-authorization/_modal/inspect/SurgicalAuthModalInspect"
import SurgicalAuthModalLocation from "~/view/HIS/surgical-authorization/_modal/location/SurgicalAuthModalLocation"
import SurgicalAuthModalReject from "~/view/HIS/surgical-authorization/_modal/reject/SurgicalAuthModalReject"
import SurgicalAuthModalSearch from "~/view/HIS/surgical-authorization/_modal/search/SurgicalAuthModalSearch"
import APModalGeneralLedger from "~/view/accounting/accounts-payable/_modal/GL/APModalGeneralLedger"
import APModalLocation from "~/view/accounting/accounts-payable/_modal/location/APModalLocation"
import APModalSearch from "~/view/accounting/accounts-payable/_modal/search/APModalSearch"
import ARModalAdjustment from "~/view/accounting/accounts-receivable/_modal/adjustment/ARModalAdjustment"
import ARModalEditInvoice from "~/view/accounting/accounts-receivable/_modal/edit-invoice/ARModalEditInvoice"
import ARModalGeneralLedger from "~/view/accounting/accounts-receivable/_modal/general_ledger/ARModalGeneralLedger"
import ARModalLocation from "~/view/accounting/accounts-receivable/_modal/location/ARModalLocation"
import ARModalSearch from "~/view/accounting/accounts-receivable/_modal/search/ARModalSearch"
import ARCModalGeneralLedger from "~/view/accounting/accounts-receive/_modal/general_ledger/ARCModalGeneralLedger"
import ARCModalLocation from "~/view/accounting/accounts-receive/_modal/location/ARCModalLocation"
import ARCModalSearch from "~/view/accounting/accounts-receive/_modal/search/ARCModalSearch"
import ContractBillingModalEdit from "~/view/accounting/contract-billing/_modal/edit-contract-billing/ContractBillingModalEdit"
import ContractBillingModalInvoiceInfo from "~/view/accounting/contract-billing/_modal/invoice-info/ContractBillingModalInvoiceInfo"
import ContractBillingModalLocation from "~/view/accounting/contract-billing/_modal/location/ContractBillingModalLocation"
import ContractBillingModalNew from "~/view/accounting/contract-billing/_modal/new/ContractBillingModalNew"
import ContractBillingModalSearch from "~/view/accounting/contract-billing/_modal/search/ContractBillingModalSearch"
import DebtModalEditInvoice from "~/view/accounting/debt/_modal/edit-invoice/DebtModalEditInvoice"
import DebtModalLocation from "~/view/accounting/debt/_modal/location/DebtModalLocation"
import DebtModalNew from "~/view/accounting/debt/_modal/new/DebtModalNew"
import DebtModalPayment from "~/view/accounting/debt/_modal/payment-info/DebtModalPayment"
import DebtModalSearchMore from "~/view/accounting/debt/_modal/search-more/DebtModalSearchMore"
import DebtModalSearch from "~/view/accounting/debt/_modal/search/DebtModalSearch"
import FOModalEditRequest from "~/view/accounting/financial-operations/_modal/edit-financial-request/FOModalEditRequest"
import FOModalLocation from "~/view/accounting/financial-operations/_modal/location/FOModalLocation"
import FOModalNew from "~/view/accounting/financial-operations/_modal/new/FOModalNew"
import FOModalSearchMore from "~/view/accounting/financial-operations/_modal/search-more/FOModalSearchMore"
import FOModalSearch from "~/view/accounting/financial-operations/_modal/search/FOModalSearch"
import PSModalGeneralLedger from "~/view/accounting/payment-slip/_modal/GL/PSModalGeneralLedger"
import PSModalLocation from "~/view/accounting/payment-slip/_modal/location/PSModalLocation"
import PSModalNew from "~/view/accounting/payment-slip/_modal/new/PSModalNew"
import PSModalPaymentPlan from "~/view/accounting/payment-slip/_modal/paymentPlan/PSModalPaymentPlan"
import PSModalSearch from "~/view/accounting/payment-slip/_modal/search/PSModalSearch"
import PVModalLocation from "~/view/accounting/payment-voucher/_modal/location/PVModalLocation"
import PVModalNew from "~/view/accounting/payment-voucher/_modal/new/PVModalNew"
import PVModalPurchase from "~/view/accounting/payment-voucher/_modal/purchase/PVModalPurchase"
import PVModalSearch from "~/view/accounting/payment-voucher/_modal/search/PVModalSearch"
import PGLModalEntry from "~/view/accounting/postingGL/_modal/entry/PGLModalEntry"
import PGLModalLocation from "~/view/accounting/postingGL/_modal/location/PGLModalLocation"
import PGLModalNew from "~/view/accounting/postingGL/_modal/new-PGL/PGLModalNew"
import PGLModalSearch from "~/view/accounting/postingGL/_modal/search/PGLModalSearch"
import PIModalLink from "~/view/accounting/purchase-invoice/_modal/edit-link/PIModalLink"
import PIModalLocation from "~/view/accounting/purchase-invoice/_modal/location/PIModalLocation"
import PIModalNew from "~/view/accounting/purchase-invoice/_modal/new/PIModalNew"
import PIModalPurchase from "~/view/accounting/purchase-invoice/_modal/purchase/PIModalPurchase"
import PIModalSearch from "~/view/accounting/purchase-invoice/_modal/search/PIModalSearch"
import Warning from "~/view/component/modal/Warning"
import Authorization from "~/view/component/modal/authorization/Authorization"
import ModalSaveAll from "~/view/component/save-all/ModalSaveAll"
import ConfigServiceModalNS from "~/view/container/admin/configService/service/modal/ConfigServiceModalNS"
import AdmFModalNF from "~/view/container/admin/itemConfig/form/modal/AdmFModalNF"
import AdmIModalNI from "~/view/container/admin/itemConfig/item/modal/new-item/AdmIModalNI"
import AdmISModalNIS from "~/view/container/admin/itemConfig/item_source/modal/AdmISModalNIS"
import AdmSModalNS from "~/view/container/admin/itemConfig/supplier/modal/AdmSModalNS"
import AdmTModalNT from "~/view/container/admin/itemConfig/tax/modal/AdmTModalNT"
import AdmUModalNU from "~/view/container/admin/itemConfig/unit/modal/AdmUModalNU"
import NewLocation from "~/view/container/admin/location/modal/NewLocation"
import AbstractTypeModal from "~/view/container/admin/type/modal/AbstractTypeModal"
import AdmRoleModalNR from "~/view/container/admin/userConfig/role/modal/AdmRoleModalNR"
import AdmUserModalCPW from "~/view/container/admin/userConfig/user/modal/AdmUserModalCPW"
import AdmUserModalNU from "~/view/container/admin/userConfig/user/modal/AdmUserModalNU"
import AddInventory from "~/view/container/inventory/modal/AddInventory"
import EditInventory from "~/view/container/inventory/modal/EditInventory"
import POEdit from "~/view/container/purchase_order/modal/edit-po/POEdit"
import PORejectReason from "~/view/container/purchase_order/modal/edit-po/PORejectReason"
import AbstractPrModal from "~/view/container/purchase_request/modals/AbstractPrModal"
import RCModalCancel from "~/view/container/receive/modal/RCModalCancel"
import RCModalCancelDetail from "~/view/container/receive/modal/RCModalCancelDetail"
import RCModalReceive from "~/view/container/receive/modal/RCModalReceive"
import RCModalReceiveDetail from "~/view/container/receive/modal/RCModalReceiveDetail"
import TFModalCancelTRI from "~/view/container/transfer/modal/cancelRequestItem/TFModalCancelTRI"
import TFModalNR from "~/view/container/transfer/modal/newRequest/TFModalNR"
import TFModalNT from "~/view/container/transfer/modal/newTransfer/TFModalNT"
import CashierModalAdjustment from "~/view/hospitel/cashier/modal/adjustment/CashierModalAdjustment"
import CashierModalCertificate from "~/view/hospitel/cashier/modal/certificate/CashierModalCertificate"
import CashierModalDoctor from "~/view/hospitel/cashier/modal/doctor/CashierModalDoctor"
import CashierModalFinancialPlan from "~/view/hospitel/cashier/modal/financial-plan/CashierModalFinancialPlan"
import CashierModalLab from "~/view/hospitel/cashier/modal/lab/CashierModalLab"
import CashierModalLocation from "~/view/hospitel/cashier/modal/location/CashierModalLocation"
import CashierModalNurse from "~/view/hospitel/cashier/modal/nurse/CashierModalNurse"
import CashierModalPayment from "~/view/hospitel/cashier/modal/payment/CashierModalPayment"
import CashierModalSearchPatient from "~/view/hospitel/cashier/modal/search-patient/CashierModalSearchPatient"
import HospitelModalAdjustmentService from "~/view/hospitel/hospitel/modal/adjustment-service/HospitelModalAdjustmentService"
import HospitelModalAdjustment from "~/view/hospitel/hospitel/modal/adjustment/HospitelModalAdjustment"
import HospitelModalDefaultOrder from "~/view/hospitel/hospitel/modal/doctor-instruction/HospitelModalDefaultOrder"
import InpatientModalDoctorSOAPInfo from "~/view/hospitel/hospitel/modal/doctor-note-info/InpatientModalDoctorSOAPInfo"
import HospitelModalDoctorNote from "~/view/hospitel/hospitel/modal/doctor-note/HospitelModalDoctorNote"
import InpatientModalDoctorOrderInfo from "~/view/hospitel/hospitel/modal/doctor-order-info/InpatientModalDoctorOrderInfo"
import HospitelModalDoctorOrder from "~/view/hospitel/hospitel/modal/doctor-order/HospitelModalDoctorOrder"
import HospitelModalDoctorService from "~/view/hospitel/hospitel/modal/doctor-service/HospitelModalDoctorService"
import IPDModalGCS from "~/view/hospitel/hospitel/modal/gcs/IPDModalGCS"
import IPDModalICD from "~/view/hospitel/hospitel/modal/icd/IPDModalICD"
import IPDModalIntakeOutput from "~/view/hospitel/hospitel/modal/io/IPDModalIntakeOutput"
import HospitelModalLocation from "~/view/hospitel/hospitel/modal/location/HospitelModalLocation"
import IPDModalMedicalCertificate from "~/view/hospitel/hospitel/modal/medical-certificate/IPDModalMedicalCertificate"
import HospitelModalMedicalSupply from "~/view/hospitel/hospitel/modal/medical-supply/HospitelModalMedicalSupply"
import InpatientModalNurseRecordInfo from "~/view/hospitel/hospitel/modal/nurse-record-info/InpatientModalNurseRecordInfo"
import HospitelModalNurseRecord from "~/view/hospitel/hospitel/modal/nurse-record/HospitelModalNurseRecord"
import HospitelModalNurseService from "~/view/hospitel/hospitel/modal/nurse-service/HospitelModalNurseService"
import IPDModalPainScale from "~/view/hospitel/hospitel/modal/pain-scale/IPDModalPainScale"
import IPDModalCreatePRNRecord from "~/view/hospitel/hospitel/modal/prn-create/IPDModalCreatePRNRecord"
import IPDModalPRNRecord from "~/view/hospitel/hospitel/modal/prn-record/IPDModalPRNRecord"
import IPDModalPupilSize from "~/view/hospitel/hospitel/modal/pupil-size/IPDModalPupilSize"
import HospitelModalRegister from "~/view/hospitel/hospitel/modal/register/HospitelModalRegister"
import HospitelModalSearch from "~/view/hospitel/hospitel/modal/search/HospitelModalSearch"
import HospitelModalStop from "~/view/hospitel/hospitel/modal/stop/HospitelModalStop"
import HospitelModalVitalSign from "~/view/hospitel/hospitel/modal/vital-sign/HospitelModalVitalSign"
import RegModalAdjustment from "~/view/hospitel/ipd/modal/adjustment/RegModalAdjustment"
import RegModalBudget from "~/view/hospitel/ipd/modal/budget/RegModalBudget"
import RegModalExpenseEst from "~/view/hospitel/ipd/modal/expense-estimation/RegModalExpenseEst"
import RegisterModalClinicalRecord from "~/view/hospitel/ipd/modal/register-clinical-record/RegisterModalClinicalRecord"
import IPDModalRegister from "~/view/hospitel/ipd/modal/register/IPDModalRegister"
import IPDModalSearch from "~/view/hospitel/ipd/modal/search/IPDModalSearch"
import CLModalApprove from "~/view/hospitel/lab/modal/approve/CLModalApprove"
import CLModalNewLab from "~/view/hospitel/lab/modal/lab/CLModalNewLab"
import CLModalNegative from "~/view/hospitel/lab/modal/negative/CLModalNegative"
import CLModalPositive from "~/view/hospitel/lab/modal/positive/CLModalPositive"
import CLModalRegister from "~/view/hospitel/lab/modal/register/CLModalRegister"
import CLModalSearchPatient from "~/view/hospitel/lab/modal/search-patient/CLModalSearchPatient"
import HospitalModalPatientClinicalRecord from "~/view/hospitel/modal/clinical-record/HospitalModalPatientClinicalRecord"
import Notification from "~/view/menu/notification/Notification"
import NotificationSearch from "~/view/menu/notification/NotificationSearch"
import NotificationModalNew from "~/view/menu/notification/modal/message/NotificationModalNew"
import CMenuSelectableAccountsCode from "~/view/menu/selectable-accounts-code/CMenuSelectableAccountsCode"
import Timeline from "~/view/menu/timeline/Timeline"
import TimelineModalSearch from "~/view/menu/timeline/search/TimelineModalSearch"
import ReportFinanceAPA from "../../../report/finance/_modal/accounts_payable_aging/ReportFinanceAPA"
import DebtModalBSAdjustment from "../../accounting/debt/_modal/adjustment/DebtModalAdjustment"
import ConfigCOAModalAdd from "../admin/configChartOfAccounts/assets/_modal/ConfigCOAModalAdd"
import ConfigCOAEquityModalAdd from "../admin/configChartOfAccounts/equity/_modal/ConfigCOAEquityModalAdd"
import ConfigCOAExpensesModalAdd from "../admin/configChartOfAccounts/expenses/_modal/ConfigCOAExpensesModalAdd"
import ConfigCOALiabilitiesModalAdd from "../admin/configChartOfAccounts/liabilities/_modal/ConfigCOALiabilitiesModalAdd"
import ConfigCOARevenuesModalAdd from "../admin/configChartOfAccounts/revenues/_modal/ConfigCOARevenuesModalAdd"
import ConfigGLModalPayable from "../admin/configGeneralLedger/accounts_payable/_modal/ConfigGLModalPayable"
import ConfigGLModalReceivable from "../admin/configGeneralLedger/accounts_receivable/_modal/ConfigGLModalReceivable"
import ConfigICDModalN from "../admin/configICD/icd/modal/ConfigICDModalN"
import ConfigLevelModalN from "../admin/configICD/level/modal/ConfigLevelModalN"
import ConfigAntigenCommentModalN from "../admin/configLaboratory/antigen_comment/modal/ConfigAntigenCommentModalN"
import ConfigPCRCommentModalN from "../admin/configLaboratory/pcr_comment/modal/ConfigPCRCommentModalN"
import ConfigEclaimUcNewItem from "../admin/configWorkflowProperty/configEclaim_uc/modal/ConfigEclaimUcNewItem"
import ConfigFocusModalNF from "../admin/customDefault/focus/modal/ConfigFocusModalNF"
import ConfigInterventionModalND from "../admin/customDefault/intervention/modal/ConfigInterventionModalND"
import AdmItemSourceModalUnit from "../admin/itemConfig/item/modal/config-unit/AdmItemModalUnit"
import AdmIModalInfo from "../admin/itemConfig/item/modal/info/AdmIModalInfo"
import AdmCategoryInventoryModalPayable from "../admin/type/modal/accounting_paybale/AdmCategoryInventoryModalPayable"
import AdmCategoryInventoryModalReceivable from "../admin/type/modal/accounting_receivable/AdmCategoryInventoryModalReceivable"
import AdmBankModalBranch from "../admin/type/modal/bank/branch/AdmBankModalBranch"
import AdmHealthCarePlanModalAR from "../admin/type/modal/healthcarePlan/accounts_receivable/AdmHealthCarePlanModalAR"
import AdmHealthCarePlanModalCompany from "../admin/type/modal/healthcarePlan/company/AdmHealthCarePlanModalCompany"
import AdmPaymentModalAccountsPayable from "../admin/type/modal/payment/accounts_payable/AdmPaymentModalAccountsPayable"
import AdmUserModalSignature from "../admin/userConfig/user/modal/signature/AdmUserModalSignature"
import POModalEditItem from "../purchase_order/modal/edit-item/POModalEditItem"
import RCModalSearch from "../receive/modal/search/RCModalSearch"
import DepositModalSave from "./../../HIS/deposit/modal/save/DepositModalSave"
import "./modal.css"

class Modal extends React.Component {
	render() {
		return (
			<Route>
				<Route exact path={ResourceAssistance.Path.transfer}>
					<Route component={TFModalCancelTRI} />
					<Route component={TFModalNR} />
					<Route component={TFModalNT} />
				</Route>
				<Route exact path={ResourceAssistance.Path.pr} component={AbstractPrModal} />
				<Route exact path={ResourceAssistance.Path.po}>
					<Route component={POEdit} />
					<Route component={PORejectReason} />
					<Route component={POModalEditItem} />
				</Route>
				<Route exact path={ResourceAssistance.Path.rc}>
					<Route component={RCModalReceive} />
					<Route component={RCModalCancel} />
					<Route component={RCModalReceiveDetail} />
					<Route component={RCModalCancelDetail} />
					<Route component={RCModalSearch} />
				</Route>
				<Route exact path={ResourceAssistance.Path.inventory}>
					<Route component={AddInventory} />
					<Route component={EditInventory} />
				</Route>
				<Route exact path={ResourceAssistance.Path.type}>
					<Route component={AbstractTypeModal} />
					<Route component={AdmHealthCarePlanModalCompany} />
					<Route component={AdmHealthCarePlanModalAR} />
					<Route component={AdmBankModalBranch} />
					<Route component={AdmCategoryInventoryModalReceivable} />
					<Route component={AdmCategoryInventoryModalPayable} />
					<Route component={AdmPaymentModalAccountsPayable} />
				</Route>
				<Route exact path={ResourceAssistance.Path.configLoc} component={NewLocation} />
				<Route exact path={ResourceAssistance.Path.configItem}>
					<Route component={AdmSModalNS} />
					<Route component={AdmUModalNU} />
					<Route component={AdmFModalNF} />
					<Route component={AdmTModalNT} />
					<Route component={AdmIModalNI} />
					<Route component={AdmISModalNIS} />
					<Route component={AdmItemSourceModalUnit} />
					<Route component={AdmIModalInfo} />
				</Route>
				<Route exact path={ResourceAssistance.Path.configUser}>
					<Route component={AdmUserModalNU} />
					<Route component={AdmUserModalCPW} />
					<Route component={AdmRoleModalNR} />
					<Route component={AdmUserModalSignature} />
				</Route>
				<Route exact path={ResourceAssistance.Path.configService}>
					<Route component={ConfigServiceModalNS} />
				</Route>
				<Route exact path={ResourceAssistance.Path.configCustomDefault}>
					<Route component={ConfigFocusModalNF} />
					<Route component={ConfigInterventionModalND} />
				</Route>
				<Route exact path={ResourceAssistance.Path.configICD}>
					<Route component={ConfigICDModalN} />
					<Route component={ConfigLevelModalN} />
				</Route>
				<Route exact path={ResourceAssistance.Path.configLaboratory}>
					<Route component={ConfigPCRCommentModalN} />
					<Route component={ConfigAntigenCommentModalN} />
				</Route>
				<Route exact path={ResourceAssistance.Path.configCOA}>
					<Route component={ConfigCOAModalAdd} />
					<Route component={ConfigCOALiabilitiesModalAdd} />
					<Route component={ConfigCOAEquityModalAdd} />
					<Route component={ConfigCOAExpensesModalAdd} />
					<Route component={ConfigCOARevenuesModalAdd} />
				</Route>
				<Route exact path={ResourceAssistance.Path.configGL}>
					<Route component={ConfigGLModalReceivable} />
					<Route component={ConfigGLModalPayable} />
				</Route>
				<Route exact path={ResourceAssistance.Path.configWP}>
					<Route component={ConfigEclaimUcNewItem} />
				</Route>

				{/* ***********************************************Report**************************************************************************************** */}
				{/* Inventory filters*/}
				<Route exact path={ResourceAssistance.Path.reportInv}>
					<Route component={ReportInvByLocationFilter} />
				</Route>

				{/* PO filters*/}
				<Route exact path={ResourceAssistance.Path.reportPo}>
					<Route component={ReportPOByLocationFilter} />
					<Route component={ReportPOByIdFilter} />
				</Route>

				{/* Transfer filters */}
				<Route exact path={ResourceAssistance.Path.reportTf}>
					<Route component={ReportTFByLocationFilter} />
				</Route>

				{/* Cashier filters */}
				<Route exact path={ResourceAssistance.Path.Hospitel.reportCashier}>
					<Route component={ReportCashierAdmissionFilter} />
					<Route component={ReportCashierUserFilter} />
					<Route component={ReportCashierBillingToTodayFilter} />
				</Route>

				{/* Hospitel filters */}
				<Route exact path={ResourceAssistance.Path.Hospitel.reportHospitel}>
					<Route component={ReportHospitelPatientFilter} />
				</Route>

				{/* Laboratory filters */}
				<Route exact path={ResourceAssistance.Path.Hospitel.reportLab}>
					<Route component={ReportLabCovid19LabFilter} />
				</Route>
				{/* ********************************************************************************************************************************************* */}

				{/* Warning */}
				<Route
					render={(props) => (
						<Warning
							doneBtnRef={this.props.modal.warning.doneBtnRef}
							closeBtnRef={this.props.modal.warning.closeBtnRef}
							isShow={this.props.modal.isWarningDisplay}
							msg={this.props.modal.warning.msg}
							{...this.props}
						/>
					)}
				/>

				{/* Authorization */}
				<Route component={Authorization} />

				<Route render={(props) => <HospitalModalPatientClinicalRecord patient={this.props.modal.hospital.clinicalRecord.patient} {...props} />} />

				{/* ***********************************************************************HIS******************************************************************* */}
				{/* Cashier */}
				<Route exact path={ResourceAssistance.Path.Hospitel.cashier}>
					<Route component={CashierModalLocation} />
					<Route component={CashierModalFinancialPlan} />
					<Route component={CashierModalSearchPatient} />
					<Route component={CashierModalLab} />
					<Route component={CashierModalCertificate} />
					<Route component={CashierModalAdjustment} />
					<Route component={CashierModalDoctor} />
					<Route component={CashierModalNurse} />
					<Route component={CashierModalPayment} />
				</Route>
				{/* IPD */}
				<Route exact path={ResourceAssistance.Path.Hospitel.hospitel}>
					<Route component={HospitelModalLocation} />
					<Route component={HospitelModalRegister} />
					<Route component={HospitelModalSearch} />
					<Route component={HospitelModalDoctorOrder} />
					<Route component={HospitelModalStop} />
					<Route component={HospitelModalNurseRecord} />
					<Route component={HospitelModalDoctorNote} />
					<Route component={HospitelModalVitalSign} />
					<Route component={HospitelModalDefaultOrder} />
					<Route component={HospitelModalMedicalSupply} />
					<Route component={HospitelModalNurseService} />
					<Route component={HospitelModalAdjustment} />
					<Route component={HospitelModalAdjustmentService} />
					<Route component={HospitelModalDoctorService} />
					<Route component={ModalSaveAll} />
					<Route component={IPDModalPRNRecord} />
					<Route component={IPDModalCreatePRNRecord} />
					<Route component={IPDModalMedicalCertificate} />
					<Route component={IPDModalICD} />
					<Route component={IPDModalIntakeOutput} />
					<Route component={IPDModalGCS} />
					<Route component={IPDModalPainScale} />
					<Route component={IPDModalPupilSize} />
					<Route component={InpatientModalDoctorOrderInfo} />
					<Route component={InpatientModalNurseRecordInfo} />
					<Route component={InpatientModalDoctorSOAPInfo} />
				</Route>
				{/* Lab */}
				<Route exact path={ResourceAssistance.Path.Hospitel.covid19Lab}>
					<Route component={CLModalRegister} />
					<Route component={CLModalSearchPatient} />
					<Route component={CLModalNegative} />
					<Route component={CLModalPositive} />
					<Route component={CLModalNewLab} />
					<Route component={CLModalApprove} />
				</Route>
				{/* Registration */}
				<Route exact path={ResourceAssistance.Path.Hospitel.registration}>
					<Route component={IPDModalRegister} />
					<Route component={IPDModalSearch} />
					<Route component={RegisterModalClinicalRecord} />
					<Route component={RegModalExpenseEst} />
					<Route component={HospitelModalAdjustmentService} />
					<Route component={RegModalAdjustment} />
					<Route component={RegModalBudget} />
				</Route>
				{/* Deposit */}
				<Route exact path={ResourceAssistance.Path.HIS.deposit}>
					<Route component={DepositModalSearchPatient} />
					<Route component={DepositModalSave} />
					<Route component={DepositModalWithdraw} />
				</Route>
				{/* OPD */}
				<Route exact path={ResourceAssistance.Path.HIS.opd}>
					<Route component={OPDModalLocation} />
					<Route component={OPDModalAppointment} />
					<Route component={OPDModalSearch} />
					<Route component={OPDModalMedicalRecord} />
					<Route component={OutpatientModalDoctor} />
					<Route component={OPDModalModifyDate} />
					<Route component={OPDModalMedicalCertificate} />
					<Route component={OPDModalDoctorService} />
					<Route component={ModalSaveAll} />
					<Route component={HospitelModalAdjustmentService} />
					<Route component={OPDModalDoctorInstruction} />
					<Route component={OPDModalNurse} />
					<Route component={OPDModalVitalSign} />
					<Route component={OPDModalMedicalSupply} />
					<Route component={OPDModalAdjustment} />
					<Route component={OPDModalNurseService} />
					<Route component={OPDModalAppointmentEdit} />
					<Route component={OPDModalICD} />
					<Route component={OPDModalIntakeOutput} />
					<Route component={OPDModalGCS} />
					<Route component={OPDModalPainScale} />
					<Route component={OPDModalPupilSize} />
					<Route component={OutpatientModalNurseOrderInfo} />
					<Route component={OutpatientModalDoctorOrderInfo} />
					<Route component={OutpatientModalOR} />
					<Route component={OutpatientModalORRequestInfo} />
					<Route component={OutpatientModalORCalendar} />
					<Route component={OPDModalNursePreOperativeCheckList} />
				</Route>
				{/* Pharmacy */}
				<Route exact path={ResourceAssistance.Path.HIS.pharmacy}>
					<Route component={PharmacyModalLocation} />
					<Route component={PharmacyModalSearch} />
					<Route component={PharmacyModalVerify} />
					<Route component={PharmacyModalReturn} />
					<Route component={PharmacyModalReturnEdit} />
				</Route>
				{/* Font Inventory */}
				<Route exact path={ResourceAssistance.Path.HIS.frontInventory}>
					<Route component={FrontInventoryModalEdit} />
					<Route component={FrontInventoryModalWaste} />
					<Route component={FrontInventoryModalWasteEdit} />
					<Route component={FIModalSplit} />
					<Route component={FIModalSplitEdit} />
				</Route>
				{/* Operating Room */}
				<Route exact path={ResourceAssistance.Path.HIS.operatingRoom}>
					<Route component={OperatingRoomModalLocation} />
					<Route component={OperatingRoomModalSchedule} />
					<Route component={OperatingRoomModalRemark} />
					<Route component={OperatingRoomModalCancel} />
					<Route component={OperatingRoomModalEditRequest} />
					<Route component={OperatingRoomModalEditPre} />
					<Route component={OperatingRoomModalVitalSign} />
					<Route component={OperatingRoomModalEditPreOperativeCheckList} />
					<Route component={OperatingRoomModalSurgicalSafetyCheckListSignIn} />
					<Route component={OperatingRoomModalSurgicalSafetyCheckListTimeOut} />
					<Route component={OperatingRoomModalSurgicalSafetyCheckListSignOut} />
					<Route component={OperatingRoomModalIntraAnePatientSafety} />
					<Route component={OperatingRoomModalIntraAnePreinductionAssessment} />
					<Route component={OperatingRoomModalIntraAneProblem} />
					<Route component={OperatingRoomModalIntraAnePremedication} />
					<Route component={OperatingRoomModalIntraAnePatientCondition} />
					<Route component={OperatingRoomModalIntraAneTechnique} />
					<Route component={OperatingRoomModalIntraAneAirway} />
					<Route component={OperatingRoomModalIntraAneBabyDelivery} />
					<Route component={OperatingRoomModalIntraAneTransfer} />
					<Route component={OperatingRoomModalIntraAneRecord} />
					<Route component={OperatingRoomModalIntraAneRecordTime} />
					<Route component={ORModalIntraAneIO} />
					<Route component={ORModalIntraAneMedication} />
					<Route component={ORModalIntraAneService} />
					<Route component={ORModalIntraAneVS} />
					<Route component={ORModalIntraAneVSNew} />
					<Route component={OperatingRoomModalPostAneNurse} />
					<Route component={OperatingRoomModalPostAneComplication} />
					<Route component={OperatingRoomModalPostAneDischarge} />
					<Route component={OperatingRoomModalPostAneTransfer} />
					<Route component={OperatingRoomModalPostAnePADSS} />
					<Route component={OperatingRoomModalPostAneRecord} />
					<Route component={OperatingRoomModalPostAneRecordTime} />
					<Route component={ORModalPostAneMedication} />
					<Route component={ORModalPostAneService} />
					<Route component={ORModalPostAneIO} />
					<Route component={ORModalPostAneVS} />
					<Route component={ORModalPostAneVSNew} />
				</Route>
				{/* Surgical Authorization */}
				<Route exact path={ResourceAssistance.Path.HIS.surgicalAuthorization}>
					<Route component={SurgicalAuthModalLocation} />
					<Route component={SurgicalAuthModalSearch} />
					<Route component={SurgicalAuthModalBudget} />
					<Route component={SurgicalAuthModalCost} />
					<Route component={SurgicalAuthModalInspect} />
					<Route component={SurgicalAuthModalReject} />
				</Route>
				{/* ********************************************************************************************************************************************* */}

				{/************************************************************ Accounting Back Office *************************************************************/}
				<Route exact path={ResourceAssistance.Path.ACC.accountsReceivable}>
					<Route component={ARModalLocation} />
					<Route component={ARModalEditInvoice} />
					<Route component={ARModalAdjustment} />
					<Route component={ARModalGeneralLedger} />
					<Route component={ARModalSearch} />
				</Route>
				<Route exact path={ResourceAssistance.Path.ACC.accountsReceive}>
					<Route component={ARCModalSearch} />
					<Route component={ARCModalLocation} />
					<Route component={ARCModalGeneralLedger} />
				</Route>
				<Route exact path={ResourceAssistance.Path.ACC.contractBilling}>
					<Route component={ContractBillingModalLocation} />
					<Route component={ContractBillingModalSearch} />
					<Route component={ContractBillingModalNew} />
					<Route component={ContractBillingModalInvoiceInfo} />
					<Route component={ContractBillingModalEdit} />
				</Route>
				<Route exact path={ResourceAssistance.Path.ACC.debt}>
					<Route component={DebtModalLocation} />
					<Route component={DebtModalSearch} />
					<Route component={DebtModalNew} />
					<Route component={DebtModalSearchMore} />
					<Route component={DebtModalPayment} />
					<Route component={DebtModalEditInvoice} />
					<Route component={DebtModalBSAdjustment} />
				</Route>
				<Route exact path={ResourceAssistance.Path.ACC.accountsPayable}>
					<Route component={APModalLocation} />
					<Route component={APModalSearch} />
					<Route component={APModalGeneralLedger} />
				</Route>
				<Route exact path={ResourceAssistance.Path.ACC.paymentSlip}>
					<Route component={PSModalLocation} />
					<Route component={PSModalNew} />
					<Route component={PSModalSearch} />
					<Route component={PSModalPaymentPlan} />
					<Route component={PSModalGeneralLedger} />
				</Route>
				<Route exact path={ResourceAssistance.Path.ACC.financialOperations}>
					<Route component={FOModalLocation} />
					<Route component={FOModalSearch} />
					<Route component={FOModalNew} />
					<Route component={FOModalSearchMore} />
					<Route component={FOModalEditRequest} />
				</Route>
				<Route exact path={ResourceAssistance.Path.ACC.purchaseInvoice}>
					<Route component={PIModalLocation} />
					<Route component={PIModalNew} />
					<Route component={PIModalPurchase} />
					<Route component={PIModalSearch} />
					<Route component={PIModalLink} />
				</Route>
				<Route exact path={ResourceAssistance.Path.ACC.paymentVoucher}>
					<Route component={PVModalLocation} />
					<Route component={PVModalSearch} />
					<Route component={PVModalNew} />
					<Route component={PVModalPurchase} />
				</Route>
				<Route exact path={ResourceAssistance.Path.ACC.postingGL}>
					<Route component={PGLModalLocation} />
					<Route component={PGLModalNew} />
					<Route component={PGLModalEntry} />
					<Route component={PGLModalSearch} />
				</Route>
				{/* ********************************************************************************************************************************************* */}

				{/* Menu */}
				{/* Notification */}
				<Route component={Notification} />
				<Route component={NotificationSearch} />
				<Route component={NotificationModalNew} />
				{/* Hospital Report */}
				<Route component={ReportCashierAdmissionFilter} />
				<Route component={ReportCashierUserFilter} />
				<Route component={ReportCashierBillingToTodayFilter} />
				<Route component={ReportHospitelPatientFilter} />
				<Route component={ReportLabCovid19LabFilter} />
				{/* Stock Report */}
				<Route component={ReportInvByLocationFilter} />
				<Route component={ReportTFByLocationFilter} />
				<Route component={ReportPOByLocationFilter} />
				<Route component={ReportPOByIdFilter} />
				{/* Finance Report */}
				<Route component={ReportFinanceGeneralLedger} />
				<Route component={ReportFinanceAPA} />
				<Route component={ReportFinanceARA} />
				{/* Timeline */}
				<Route component={Timeline} />
				<Route component={TimelineModalSearch} />
				{/* Selectable Accounts Code */}
				<Route render={(props) => <CMenuSelectableAccountsCode {...this.props} />} />
			</Route>
		)
	}
}

const mapStateToProps = (state) => ({
	modal: state.modal,
	modalAdjustment: state.hospitel.cashier.modalAdjustment,
})

export default connect(mapStateToProps, {})(Modal)
