import { Button, Descriptions } from "antd"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { FiRefreshCw } from "react-icons/fi"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { Utils } from "~/utils/Utils"

class DepositBalance extends React.Component {
	constructor(props) {
		super(props)

		this.onRefresh = this.onRefresh.bind(this)
	}

	onRefresh() {
		this.props.getPatientById(this.props.deposit.patient.id)
	}

	render() {
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col>
					<fieldset>
						<legend>{translate(ResourceAssistance.Hospitel.balance)}</legend>
						<Descriptions
							style={{ flex: "unset", display: "unset" }}
							size={"small"}
							column={1}
							extra={
								<Col md="auto">
									<Button
										type="primary"
										size="middle"
										shape="circle"
										icon={<FiRefreshCw size={ResourceAssistance.ReactIcon.size} />}
										onClick={this.onRefresh}
									/>
								</Col>
							}
						>
							<Descriptions.Item
								labelStyle={{ fontSize: "24px" }}
								contentStyle={{ fontSize: "24px", color: ResourceAssistance.CSS.Color.blue }}
								label={translate(ResourceAssistance.Message.currentBalance)}
							>
								{Utils.formatNumWithComma(
									this.props.deposit.patient.transactions
										.reduce((total, cur) => {
											return (total = total.plus(cur.amount))
										}, Utils.BigNumber(0))
										.toFixed(2)
								)}
							</Descriptions.Item>
							<Descriptions.Item
								labelStyle={{ fontSize: "16px" }}
								contentStyle={{ fontSize: "16px", color: ResourceAssistance.CSS.Color.dark_green }}
								label={translate(ResourceAssistance.Message.totalDeposit)}
							>
								{Utils.formatNumWithComma(
									this.props.deposit.patient.transactions
										.reduce((total, cur) => {
											if (cur.amount > 0) {
												total = total.plus(cur.amount)
											}
											return total
										}, Utils.BigNumber(0))
										.toFixed(2)
								)}
							</Descriptions.Item>
							<Descriptions.Item
								labelStyle={{ fontSize: "16px" }}
								contentStyle={{ fontSize: "16px", color: ResourceAssistance.CSS.Color.red }}
								label={translate(ResourceAssistance.Message.totalWithdraw)}
							>
								{Utils.formatNumWithComma(
									this.props.deposit.patient.transactions
										.reduce((total, cur) => {
											if (cur.amount < 0) {
												total = total.plus(cur.amount)
											}
											return total
										}, Utils.BigNumber(0))
										.negated()
										.toFixed(2)
								)}
							</Descriptions.Item>
						</Descriptions>
					</fieldset>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	deposit: state.hospital.deposit,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(DepositBalance)
