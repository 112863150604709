import { Divider, Select } from "antd"
import _ from "lodash"
import React, { Fragment } from "react"
import { Button, Col, Container, FormCheck, FormControl, FormGroup, InputGroup, Row } from "react-bootstrap"
import { IoMdDoneAll } from "react-icons/io"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue, setSelected } from "~/redux/action"
import {
	SET_OPD_MODAL_NURSE_ASSESSMENT,
	SET_OPD_MODAL_NURSE_ASSESSMENT_OTHER,
	SET_OPD_MODAL_NURSE_EVALUATION,
	SET_OPD_MODAL_NURSE_INTERVENTION,
	SET_OPD_MODAL_NURSE_INTERVENTION_SELECTED_CATEGORY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import { MdRemoveDone } from "react-icons/md"

const ASEESSMENT = "ASSESSMENT"
const EAssessment = {
	ASYMPTOMATIC: "ASSESSMENT_ASYMPTOMATIC",
	MILD_SYMPTOMS: "ASSESSMENT_MILD_SYMPTOMS",
	OTHER: "ASSESSMENT_OTHER",
}

class OPDModalNurseAIE extends React.Component {
	constructor(props) {
		super(props)

		this.onAssessment = this.onAssessment.bind(this)
		this.onIntervention = this.onIntervention.bind(this)
		this.onEvaluation = this.onEvaluation.bind(this)
		this.onAssessmentOther = this.onAssessmentOther.bind(this)
		this.onCategory = this.onCategory.bind(this)
		this.onSelectIntervention = this.onSelectIntervention.bind(this)
		this.onDeselectIntervention = this.onDeselectIntervention.bind(this)
		this.onSelectAll = this.onSelectAll.bind(this)
	}

	componentDidMount() {
		if (this.props.opd.selectedNurseNote.nurseNote) {
			this.props.setValue(SET_OPD_MODAL_NURSE_ASSESSMENT, this.props.opd.selectedNurseNote.nurseNote.assessment)
			this.props.setValue(SET_OPD_MODAL_NURSE_ASSESSMENT_OTHER, this.props.opd.selectedNurseNote.nurseNote.assessmentNote)
			this.props.setValue(SET_OPD_MODAL_NURSE_INTERVENTION, this.props.opd.selectedNurseNote.nurseNote.intervention)
			this.props.setValue(SET_OPD_MODAL_NURSE_EVALUATION, this.props.opd.selectedNurseNote.nurseNote.evaluation)
		}
	}

	componentWillUnmount() {
		this.reset()
	}

	reset() {
		this.props.setValue(SET_OPD_MODAL_NURSE_ASSESSMENT, ResourceAssistance.CONSTANT.ASYMPTOMATIC)
		this.props.setValue(SET_OPD_MODAL_NURSE_ASSESSMENT_OTHER, "")
		this.props.setValue(SET_OPD_MODAL_NURSE_INTERVENTION, "")
		this.props.setValue(SET_OPD_MODAL_NURSE_EVALUATION, "")
		this.props.setSelected(SET_OPD_MODAL_NURSE_INTERVENTION_SELECTED_CATEGORY, -1, null)
	}

	getValues() {
		let values = []
		if (this.props.opd.modalNurse.selectedInterventionCategory.category) {
			this.props.opd.modalNurse.selectedInterventionCategory.category.interventions.forEach((intervention, idx) => {
				if (_.includes(this.props.opd.modalNurse.intervention, intervention.description)) {
					values.push(idx)
				}
			})
		}
		return values
	}

	modifyIntervention(intervention, isAdd) {
		let value = this.props.opd.modalNurse.intervention
		if (isAdd) {
			value = value.concat(_.isEmpty(value) ? "" : "\n", intervention)
		} else {
			intervention.split("\n").forEach((each) => {
				value = value.replace(each, "")
			})
		}
		value = Utils.replaceDuplicateEmptyLine(value)
		this.props.setValue(SET_OPD_MODAL_NURSE_INTERVENTION, Utils.trim(value))
	}

	onAssessment(event) {
		this.props.setValue(SET_OPD_MODAL_NURSE_ASSESSMENT_OTHER, "")
		switch (event.target.value) {
			case EAssessment.ASYMPTOMATIC:
				this.props.setValue(SET_OPD_MODAL_NURSE_ASSESSMENT, ResourceAssistance.CONSTANT.ASYMPTOMATIC)
				break

			case EAssessment.MILD_SYMPTOMS:
				this.props.setValue(SET_OPD_MODAL_NURSE_ASSESSMENT, ResourceAssistance.CONSTANT.MILD_SYMPTOMS)
				break

			case EAssessment.OTHER:
				this.props.setValue(SET_OPD_MODAL_NURSE_ASSESSMENT, ResourceAssistance.CONSTANT.OTHER)
				break

			default:
				break
		}
	}

	onAssessmentOther(event) {
		this.props.setValue(SET_OPD_MODAL_NURSE_ASSESSMENT_OTHER, event.target.value)
	}

	onIntervention(event) {
		this.props.setValue(SET_OPD_MODAL_NURSE_INTERVENTION, event.target.value)
	}

	onEvaluation(event) {
		this.props.setValue(SET_OPD_MODAL_NURSE_EVALUATION, event.target.value)
	}

	onCategory(value) {
		this.props.setSelected(
			SET_OPD_MODAL_NURSE_INTERVENTION_SELECTED_CATEGORY,
			value > -1 ? Number(value) : -1,
			value > -1 ? this.props.opd.modalNurse.categories[value] : null
		)
	}

	onSelectIntervention(value) {
		this.modifyIntervention(this.props.opd.modalNurse.selectedInterventionCategory.category.interventions[value].description, true)
	}

	onDeselectIntervention(value) {
		this.modifyIntervention(this.props.opd.modalNurse.selectedInterventionCategory.category.interventions[value].description, false)
	}

	onSelectAll(event) {
		let interventions = ""
		this.props.opd.modalNurse.selectedInterventionCategory.category.interventions.forEach((each) => {
			if (!_.includes(this.props.opd.modalNurse.intervention, each.description)) {
				interventions = interventions.concat(each.description, "\n")
			}
		})
		this.modifyIntervention(
			!_.isEmpty(interventions)
				? interventions
				: this.props.opd.modalNurse.selectedInterventionCategory.category.interventions.reduce((total, cur) => {
						return (total = total.concat(cur.description, "\n"))
				  }, ""),
			!_.isEmpty(interventions)
		)
	}

	render() {
		return (
			<fieldset className={ResourceAssistance.CSS.fullFlex}>
				<legend>{translate(ResourceAssistance.Hospitel.aie)}</legend>
				<Row>
					<Col>
						<fieldset>
							<legend>{translate(ResourceAssistance.Hospitel.assessment)}</legend>
							<Container fluid="small">
								<Row>
									<Col md={"auto"}>
										<Row>
											<Col md={"auto"}>
												<FormGroup controlId={EAssessment.ASYMPTOMATIC}>
													<FormCheck
														type={ResourceAssistance.FormControl.type.radio}
														name={ASEESSMENT}
														value={EAssessment.ASYMPTOMATIC}
														checked={_.isEqual(this.props.opd.modalNurse.assessment, ResourceAssistance.CONSTANT.ASYMPTOMATIC)}
														onChange={this.onAssessment}
														label={"Asymptomatic"}
													/>
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col md={"auto"}>
												<FormGroup controlId={EAssessment.MILD_SYMPTOMS}>
													<FormCheck
														type={ResourceAssistance.FormControl.type.radio}
														name={ASEESSMENT}
														value={EAssessment.MILD_SYMPTOMS}
														checked={_.isEqual(this.props.opd.modalNurse.assessment, ResourceAssistance.CONSTANT.MILD_SYMPTOMS)}
														onChange={this.onAssessment}
														label={"Mild Symptoms"}
													/>
												</FormGroup>
											</Col>
										</Row>

										<Row>
											<Col md={"auto"}>
												<FormGroup controlId={EAssessment.OTHER}>
													<FormCheck
														type={ResourceAssistance.FormControl.type.radio}
														name={ASEESSMENT}
														value={EAssessment.OTHER}
														checked={_.isEqual(this.props.opd.modalNurse.assessment, ResourceAssistance.CONSTANT.OTHER)}
														onChange={this.onAssessment}
														label={"Other"}
													/>
												</FormGroup>
											</Col>
										</Row>
									</Col>
									<Col>
										<Row>
											<Col>
												<InputGroup>
													<FormControl
														required
														as={ResourceAssistance.FormControl.as.textArea}
														rows={3}
														disabled={this.props.opd.modalNurse.assessment !== ResourceAssistance.CONSTANT.OTHER}
														value={this.props.opd.modalNurse.assessmentOther}
														onChange={this.onAssessmentOther}
													/>
												</InputGroup>
											</Col>
										</Row>
									</Col>
								</Row>
							</Container>
						</fieldset>
					</Col>
				</Row>
				<Row>
					<Col>
						<fieldset>
							<legend>{translate(ResourceAssistance.Hospitel.intervention)}</legend>
							<Container fluid="small" className="full-flex">
								<Row>
									<Col>
										<Select
											showSearch
											value={
												this.props.opd.modalNurse.selectedInterventionCategory.category
													? this.props.opd.modalNurse.selectedInterventionCategory.index
													: undefined
											}
											size={ResourceAssistance.Select.size.small}
											style={{ flexGrow: 1, textAlign: "center" }}
											optionFilterProp="children"
											filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
											filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
											onChange={this.onCategory}
										>
											{Utils.renderSelects(this.props.opd.modalNurse.categories, false)}
										</Select>
									</Col>
								</Row>
								<Row>
									<Col>
										<Select
											mode={ResourceAssistance.Select.mode.multiple}
											size={ResourceAssistance.Select.size.small}
											style={{ flexGrow: 1, textAlign: "center" }}
											disabled={!this.props.opd.modalNurse.selectedInterventionCategory.category}
											value={this.getValues()}
											maxTagCount="responsive"
											optionFilterProp="children"
											filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
											filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
											onSelect={this.onSelectIntervention}
											onDeselect={this.onDeselectIntervention}
											dropdownRender={(menu) => (
												<Fragment>
													{menu}
													{this.props.opd.modalNurse.selectedInterventionCategory.category &&
														!_.isEmpty(this.props.opd.modalNurse.selectedInterventionCategory.category.interventions) && (
															<Fragment>
																<Divider style={{ margin: "4px 0" }} />
																<Row>
																	<Col />
																	<Col md="auto">
																		<Button
																			variant={ResourceAssistance.Button.variant.primary}
																			disabled={
																				!this.props.opd.modalNurse.selectedInterventionCategory.category ||
																				_.isEmpty(this.props.opd.modalNurse.selectedInterventionCategory.category.interventions)
																			}
																			onMouseDown={(e) => e.preventDefault()}
																			onClick={this.onSelectAll}
																		>
																			{this.props.opd.modalNurse.selectedInterventionCategory.category &&
																			this.props.opd.modalNurse.selectedInterventionCategory.category.interventions.every((intervention) =>
																				this.props.opd.modalNurse.intervention.includes(intervention.description)
																			) ? (
																				<Fragment>
																					<MdRemoveDone size={ResourceAssistance.ReactIcon.size} />
																					{translate(ResourceAssistance.Message.deselectAll)}
																				</Fragment>
																			) : (
																				<Fragment>
																					<IoMdDoneAll size={ResourceAssistance.ReactIcon.size} />
																					{translate(ResourceAssistance.Message.selectAll)}
																				</Fragment>
																			)}
																		</Button>
																	</Col>
																	<Col />
																</Row>
															</Fragment>
														)}
												</Fragment>
											)}
										>
											{Utils.renderSelects(
												this.props.opd.modalNurse.selectedInterventionCategory.category
													? this.props.opd.modalNurse.selectedInterventionCategory.category.interventions
													: [],
												false,
												-1,
												"description"
											)}
										</Select>
									</Col>
								</Row>
								<Row className="full-size">
									<Col>
										<InputGroup>
											<FormControl
												as={ResourceAssistance.FormControl.as.textArea}
												rows={5}
												value={this.props.opd.modalNurse.intervention}
												onChange={this.onIntervention}
											/>
										</InputGroup>
									</Col>
								</Row>
							</Container>
						</fieldset>
					</Col>
				</Row>
				<Row>
					<Col>
						<fieldset>
							<legend>{translate(ResourceAssistance.Hospitel.evaluation)}</legend>
							<InputGroup>
								<FormControl
									required
									autoFocus
									as={ResourceAssistance.FormControl.as.textArea}
									rows={5}
									value={this.props.opd.modalNurse.evaluation}
									onChange={this.onEvaluation}
								/>
							</InputGroup>
						</fieldset>
					</Col>
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OPDModalNurseAIE))
