export const PR_DONE_HANDLER = "PR_DONE_HANDLER"
export const PR_MIN_STOCK = "MIN_STOCK"
export const PR_NEW = "NEW_PR"
export const PR_UPDATE = "PR_UPDATE"
export const SET_ADD_INVENTORY_DISPLAY = "SET_ADD_INVENTORY_DISPLAY"
export const SET_ADM_CUSTOM_DEFAULT_NEW_FOCUS_DISPLAY = "SET_ADM_CUSTOM_DEFAULT_NEW_FOCUS_DISPLAY"
export const SET_ADM_CUSTOM_DEFAULT_NEW_INTERVENTION_DISPLAY = "SET_ADM_CUSTOM_DEFAULT_NEW_INTERVENTION_DISPLAY"
export const SET_ADM_FORM_NEW_FORM_DISPLAY = "SET_ADM_FORM_NEW_FORM_DISPLAY"
export const SET_ADM_HEALTH_CARE_PLAN_COMPANY_DISPLAY = "SET_ADM_HEALTH_CARE_PLAN_COMPANY_DISPLAY"
export const SET_ADM_HEALTH_CARE_PLAN_ACCOUNTS_RECEIVABLE_DISPLAY = "SET_ADM_HEALTH_CARE_PLAN_ACCOUNTS_RECEIVABLE_DISPLAY"
export const SET_ADM_ITEM_CONFIG_UNIT_DISPLAY = "SET_ADM_ITEM_CONFIG_UNIT_DISPLAY"
export const SET_ADM_ITEM_NEW_ITEM_DISPLAY = "SET_ADM_ITEM_NEW_ITEM_DISPLAY"
export const SET_ADM_ITEM_SOURCE_NEW_ITEM_DISPLAY = "SET_ADM_ITEM_SOURCE_NEW_ITEM_DISPLAY"
export const SET_ADM_ROLE_NEW_ROLE_DISPLAY = "SET_ADM_ROLE_NEW_ROLE_DISPLAY"
export const SET_ADM_SUPPLIER_NEW_SUPPLIER_DISPLAY = "SET_ADM_SUPPLIER_NEW_SUPPLIER_DISPLAY"
export const SET_ADM_TAX_NEW_TAX_DISPLAY = "SET_ADM_TAX_NEW_TAX_DISPLAY"
export const SET_ADM_UNIT_NEW_UNIT_DISPLAY = "SET_ADM_UNIT_NEW_UNIT_DISPLAY"
export const SET_ADM_USER_CHANGE_PASSWORD_DISPLAY = "SET_ADM_USER_CHANGE_PASSWORD_DISPLAY"
export const SET_ADM_USER_NEW_USER_DISPLAY = "SET_ADM_USER_NEW_USER_DISPLAY"
export const SET_ADM_USER_SIGNATURE_DISPLAY = "SET_ADM_USER_SIGNATURE_DISPLAY"
export const SET_AUTHORIZATION_DISPLAY = "SET_AUTHORIZATION_DISPLAY"
export const SET_CASHIER_FINANCIAL_PLAN_DISPLAY = "SET_CASHIER_FINANCIAL_PLAN_DISPLAY"
export const SET_CASHIER_PAYMENT_DISPLAY = "SET_CASHIER_PAYMENT_DISPLAY"
export const SET_CONFIG_ICD_NEW_DISPLAY = "SET_CONFIG_ICD_NEW_DISPLAY"
export const SET_CONFIG_LEVEL_NEW_DISPLAY = "SET_CONFIG_LEVEL_NEW_DISPLAY"
export const SET_DEPOSIT_SAVING_DISPLAY = "SET_DEPOSIT_SAVING_DISPLAY"
export const SET_DEPOSIT_SEARCH_PATIENT_DISPLAY = "SET_DEPOSIT_SEARCH_PATIENT_DISPLAY"
export const SET_DEPOSIT_WITHDRAW_DISPLAY = "SET_DEPOSIT_WITHDRAW_DISPLAY"
export const SET_HOSPITAL_MODAL_CLINICAL_RECORD_CALLBACK = "SET_HOSPITAL_MODAL_CLINICAL_RECORD_CALLBACK"
export const SET_HOSPITAL_MODAL_CLINICAL_RECORD_DISPLAY = "SET_HOSPITAL_MODAL_CLINICAL_RECORD_DISPLAY"
export const SET_HOSPITAL_MODAL_CLINICAL_RECORD_EDIT_DISPLAY = "SET_HOSPITAL_MODAL_CLINICAL_RECORD_EDIT_DISPLAY"
export const SET_HOSPITAL_MODAL_PATIENT_CLINICAL_RECORD_PATIENT = "SET_HOSPITAL_MODAL_PATIENT_CLINICAL_RECORD_PATIENT"
export const SET_HOSPITAL_MODAL_SAVE_ALL_DISABLE_END_DATE_IND = "SET_HOSPITAL_MODAL_SAVE_ALL_DISABLE_END_DATE_IND"
export const SET_HOSPITAL_MODAL_SAVE_ALL_DISPLAY = "SET_HOSPITAL_MODAL_SAVE_ALL_DISPLAY"
export const SET_HOSPITAL_MODAL_SAVE_ALL_END_DATE_TIME = "SET_HOSPITAL_MODAL_SAVE_ALL_END_DATE_TIME"
export const SET_HOSPITAL_MODAL_SAVE_ALL_START_DATE_TIME = "SET_HOSPITAL_MODAL_SAVE_ALL_START_DATE_TIME"
export const SET_INVENTORY_EDIT_INVENTORY_DISPLAY = "SET_INVENTORY_EDIT_INVENTORY_DISPLAY"
export const SET_INVENTORY_MIN_STOCK_DISPLAY = "SET_INVENTORY_MIN_STOCK_DISPLAY"
export const SET_INVENTORY_NEW_LOCATION_ADDRESS_DISPLAY = "SET_INVENTORY_NEW_LOCATION_ADDRESS_DISPLAY"
export const SET_IPD_CREATE_PRN_RECORD_DISPLAY = "SET_IPD_CREATE_PRN_RECORD_DISPLAY"
export const SET_IPD_PRN_RECORD_DISPLAY = "SET_IPD_PRN_RECORD_DISPLAY"
export const SET_MODAL_WARNING_SIZE = "SET_MODAL_WARNING_SIZE"
export const SET_NEW_LOCATION_DISPLAY = "SET_NEW_LOCATION_DISPLAY"
export const SET_NEW_LOCATION_PARENT_ORG_DISPLAY = "SET_NEW_LOCATION_PARENT_ORG_DISPLAY"
export const SET_NEW_LOCATION_TAX_NO_DISPLAY = "SET_NEW_LOCATION_TAX_NO_DISPLAY"
export const SET_NEW_PR_TYPE_DISPLAY = "SET_NEW_PR_TYPE_DISPLAY"
export const SET_NEW_TYPE_DISPLAY = "SET_NEW_TYPE_DISPLAY"
export const SET_OPD_ADJUSTMENT_DISPLAY = "SET_OPD_ADJUSTMENT_DISPLAY"
export const SET_OPD_APPOINTMENT_DISPLAY = "SET_OPD_APPOINTMENT_DISPLAY"
export const SET_OPD_APPOINTMENT_EDIT_DISPLAY = "SET_OPD_APPOINTMENT_EDIT_DISPLAY"
export const SET_OPD_DOCTOR_DISPLAY = "SET_OPD_DOCTOR_DISPLAY"
export const SET_OPD_DOCTOR_INSTRUCTION_DISPLAY = "SET_OPD_DOCTOR_INSTRUCTION_DISPLAY"
export const SET_OPD_DOCTOR_SERVICE_DISPLAY = "SET_OPD_DOCTOR_SERVICE_DISPLAY"
export const SET_OPD_LOACTION_DISPLAY = "SET_OPD_LOACTION_DISPLAY"
export const SET_OPD_MEDICAL_CERTIFICATE_DISPLAY = "SET_OPD_MEDICAL_CERTIFICATE_DISPLAY"
export const SET_OPD_MEDICAL_RECORD_DISPLAY = "SET_OPD_MEDICAL_RECORD_DISPLAY"
export const SET_OPD_MODIFY_DATE_DISPLAY = "SET_OPD_MODIFY_DATE_DISPLAY"
export const SET_OPD_NURSE_DISPLAY = "SET_OPD_NURSE_DISPLAY"
export const SET_OPD_NURSE_ORDER_DISPLAY = "SET_OPD_NURSE_ORDER_DISPLAY"
export const SET_OPD_NURSE_SERVICE_DISPLAY = "SET_OPD_NURSE_SERVICE_DISPLAY"
export const SET_OPD_SEARCH_DISPLAY = "SET_OPD_SEARCH_DISPLAY"
export const SET_OPD_SELECTED_MEDICAL_CERTIFICATE = "SET_OPD_SELECTED_MEDICAL_CERTIFICATE"
export const SET_OPD_VITAL_SIGN_DISPLAY = "SET_OPD_VITAL_SIGN_DISPLAY"
export const SET_PO_EDIT_DISPLAY = "SET_PO_EDIT_DISPLAY"
export const SET_PO_REJECT_REASON_DISPLAY = "SET_PO_REJECT_REASON_DISPLAY"
export const SET_RC_CANCEL_DISPLAY = "SET_RC_CANCEL_DISPLAY"
export const SET_RC_CANCEL_REASON_DISPLAY = "SET_RC_CANCEL_REASON_DISPLAY"
export const SET_RC_RECEIVE_DETAIL_DISPLAY = "SET_RC_RECEIVE_DETAIL_DISPLAY"
export const SET_RC_RECEIVE_DISPLAY = "SET_RC_RECEIVE_DISPLAY"
export const SET_REPORT_INV_FILTER_BY_LOCATION = "SET_REPORT_INV_FILTER_BY_LOCATION"
export const SET_REPORT_PO_FILTER_BY_ID = "SET_REPORT_PO_FILTER_BY_ID"
export const SET_REPORT_PO_FILTER_BY_LOCATION = "SET_REPORT_PO_FILTER_BY_LOCATION"
export const SET_REPORT_TF_FILTER_BY_LOCATION = "SET_REPORT_TF_FILTER_BY_LOCATION"
export const SET_TF_CANCEL_REQUEST_ITEM_DISPLAY = "SET_TF_CANCEL_REQUEST_ITEM_DISPLAY"
export const SET_TF_NEW_REQUEST_DISPLAY = "SET_TF_NEW_REQUEST_DISPLAY"
export const SET_TF_NEW_TRANSFER_DISPLAY = "SET_TF_NEW_TRANSFER_DISPLAY"
export const SET_WARNING_CLOSE = "SET_WARNING_CLOSE"
export const SET_WARNING_CONTINUE = "SET_WARNING_CONTINUE"
export const SET_WARNING_DISPLAY = "SET_WARNING_DISPLAY"
export const SET_WARNING_ID = "SET_WARNING_ID"
export const SET_WARNING_MESSAGE = "SET_WARNING_MESSAGE"
