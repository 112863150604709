import React from "react"
import { ButtonGroup, Col, Dropdown, Row } from "react-bootstrap"
import DropdownItem from "react-bootstrap/esm/DropdownItem"
import DropdownMenu from "react-bootstrap/esm/DropdownMenu"
import DropdownToggle from "react-bootstrap/esm/DropdownToggle"
import { RiFileUploadLine } from "react-icons/ri"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setModalDoneIndAction } from "~/redux/action"
import { SET_PAGE_LOADING, SET_PO_EDIT_DISPLAY, SET_PO_EDIT_DONE_IND } from "~/redux/type"
import { Utils } from "~/utils/Utils"

class POEditAction extends React.Component {
	constructor(props) {
		super(props)

		this.onUploadAttachment = this.onUploadAttachment.bind(this)
	}

	uploadFile = (url, file) => {
		const formData = new FormData()
		formData.append("poId", this.props.po.selectedPo.po.id)
		formData.append("supplierCode", this.props.po.selectedSupplier.supplier.code)
		formData.append("supplier", this.props.po.selectedSupplier.supplier.name)
		formData.append(
			"name",
			this.props.po.selectedPo.po.id +
				"_" +
				Utils.trim(this.props.po.selectedSupplier.supplier.code) +
				"_" +
				this.props.po.selectedSupplier.supplier.name
		)
		formData.append("description", file.name)
		formData.append("file", file)

		let params = {
			method: "POST",
			url: url,
			withCredentials: true,
			headers: {
				"content-type": "multipart/form-data",
			},
			data: formData,
		}
		let callback = (res) => {
			this.props.onModalDisplayAction(SET_PO_EDIT_DISPLAY, false)
			this.props.setModalDoneIndAction(SET_PO_EDIT_DONE_IND, true, res.data.orders)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onUploadAttachment(event) {
		let idFileBlob = event.target.files[0]
		if (!idFileBlob || !idFileBlob.name.toLowerCase().endsWith(".pdf")) {
			return
		}
		this.uploadFile(ResourceAssistance.Url.po.uploadAttachmentFile, idFileBlob)
	}

	render() {
		return (
			<Row>
				<Col md="auto">
					<Dropdown
						as={ButtonGroup}
						variant={ResourceAssistance.Button.variant.primary}
						size={ResourceAssistance.FormControl.size.sm}
					>
						<DropdownToggle variant={ResourceAssistance.Button.variant.primary}>
							<RiFileUploadLine size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.upload)}
						</DropdownToggle>
						<DropdownMenu variant={"info"}>
							<label htmlFor="attachment" className={`dropdown-item`}>
								{translate(ResourceAssistance.Message.attachment)}
							</label>
							<DropdownItem
								id={"attachment"}
								as={"input"}
								type={ResourceAssistance.FormControl.type.file}
								style={{ display: "none" }}
								accept={"application/pdf"}
								onChange={this.onUploadAttachment}
							/>
						</DropdownMenu>
					</Dropdown>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	po: state.purchaseOrder,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setModalDoneIndAction,
			onModalDisplayAction,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(POEditAction)
