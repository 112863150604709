import React from "react"
import { Button, Col, Nav, Row, Tab } from "react-bootstrap"
import { AiOutlineLineChart, AiOutlineTable } from "react-icons/ai"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import OutpatientRightGCS from "./OutpatientRightGCS"
import OutpatientRightIntakeOutput from "./OutpatientRightIntakeOutput"
import OutpatientRightPainScale from "./OutpatientRightPainScale"
import OutpatientRightPupilSize from "./OutpatientRightPupilSize"
import OutpatientRightVitalSign from "./OutpatientRightVitalSign"

class OutpatientRightDataTab extends React.Component {
	EDataTab = {
		VITAL_SIGN: "OPD_TAB_VITAL_SIGN",
		INTAKE_OUTPUT: "OPD_TAB_INTAKE_OUTPUT",
		GLASGOW_COMA_SCALE: "OPD_TAB_GLASGOW_COMA_SCALE",
		PAIN_SCALE: "OPD_TAB_PAIN_SCALE",
		PUPIL_SIZE: "OPD_TAB_PUPIL_SIZE",
	}
	constructor(props) {
		super(props)

		this.state = {
			tab: this.EDataTab.VITAL_SIGN,
			graphic: false,
		}
		this.onTabClick = this.onTabClick.bind(this)
		this.onGraphic = this.onGraphic.bind(this)
	}

	onTabClick(event) {
		this.setState({ tab: event })
	}

	onGraphic(event) {
		this.setState({
			graphic: !this.state.graphic,
		})
	}

	render() {
		return (
			<Row className={"full-size"}>
				<Col>
					<Tab.Container activeKey={this.state.tab} onSelect={this.onTabClick} mountOnEnter={false}>
						<Row style={{ marginBottom: 0 }}>
							<Col md="auto">
								<Nav variant="tabs">
									<Nav.Item>
										<Nav.Link eventKey={this.EDataTab.VITAL_SIGN}>{translate(ResourceAssistance.Hospitel.vitalSign)}</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey={this.EDataTab.INTAKE_OUTPUT}>{translate(ResourceAssistance.Message.intakeOutput)}</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey={this.EDataTab.GLASGOW_COMA_SCALE}>{translate(ResourceAssistance.Message.glasgowComaScale)}</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey={this.EDataTab.PAIN_SCALE}>{translate(ResourceAssistance.Message.painScale)}</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey={this.EDataTab.PUPIL_SIZE}>{translate(ResourceAssistance.Message.pupilSize)}</Nav.Link>
									</Nav.Item>
								</Nav>
							</Col>
							<Col md="auto">
								<Button
									size={ResourceAssistance.Button.size.sm}
									variant={ResourceAssistance.Button.variant.primary}
									disabled={this.props.opd.selectedMedicalRecord.index === -1}
									onClick={this.onGraphic}
								>
									{this.state.graphic ? (
										<AiOutlineTable size={ResourceAssistance.ReactIcon.size} />
									) : (
										<AiOutlineLineChart size={ResourceAssistance.ReactIcon.size} />
									)}
									{this.state.graphic ? translate(ResourceAssistance.Message.table) : translate(ResourceAssistance.Message.graph)}
								</Button>
							</Col>
						</Row>
						<Row className="full-size">
							<Col>
								<Tab.Content>
									<Tab.Pane eventKey={this.EDataTab.VITAL_SIGN}>
										{this.state.tab === this.EDataTab.VITAL_SIGN && <OutpatientRightVitalSign {...this.state} />}
									</Tab.Pane>
									<Tab.Pane eventKey={this.EDataTab.INTAKE_OUTPUT}>
										{this.state.tab === this.EDataTab.INTAKE_OUTPUT && <OutpatientRightIntakeOutput {...this.state} />}
									</Tab.Pane>
									<Tab.Pane eventKey={this.EDataTab.GLASGOW_COMA_SCALE}>
										{this.state.tab === this.EDataTab.GLASGOW_COMA_SCALE && <OutpatientRightGCS {...this.state} />}
									</Tab.Pane>
									<Tab.Pane eventKey={this.EDataTab.PAIN_SCALE}>
										{this.state.tab === this.EDataTab.PAIN_SCALE && <OutpatientRightPainScale {...this.state} />}
									</Tab.Pane>
									<Tab.Pane eventKey={this.EDataTab.PUPIL_SIZE}>
										{this.state.tab === this.EDataTab.PUPIL_SIZE && <OutpatientRightPupilSize {...this.state} />}
									</Tab.Pane>
								</Tab.Content>
							</Col>
						</Row>
					</Tab.Container>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OutpatientRightDataTab)
