import { ResourceAssistance } from "~/i18n"
import {
	PR_DONE_HANDLER,
	PR_MIN_STOCK,
	PR_NEW,
	SET_ADD_INVENTORY_DISPLAY,
	SET_ADM_BANK_NEW_BRANCH_DISPLAY,
	SET_ADM_CATEGORY_COA_PAYABLE_DISPLAY,
	SET_ADM_CATEGORY_COA_RECEIABLE_DISPLAY,
	SET_ADM_CATEGORY_PAYMENT_PAYABLE_DISPLAY,
	SET_ADM_COA_ASSETS_ADD_DISPLAY,
	SET_ADM_COA_EQUITY_ADD_DISPLAY,
	SET_ADM_COA_EXPENSES_ADD_DISPLAY,
	SET_ADM_COA_LIABILITIES_ADD_DISPLAY,
	SET_ADM_COA_REVENUES_ADD_DISPLAY,
	SET_ADM_CUSTOM_DEFAULT_NEW_FOCUS_DISPLAY,
	SET_ADM_CUSTOM_DEFAULT_NEW_INTERVENTION_DISPLAY,
	SET_ADM_FORM_NEW_FORM_DISPLAY,
	SET_ADM_GL_PAYABLE_DISPLAY,
	SET_ADM_GL_RECEIVABLE_DISPLAY,
	SET_ADM_HEALTH_CARE_PLAN_ACCOUNTS_RECEIVABLE_DISPLAY,
	SET_ADM_HEALTH_CARE_PLAN_COMPANY_DISPLAY,
	SET_ADM_ITEM_CONFIG_UNIT_DISPLAY,
	SET_ADM_ITEM_INFO_DISPLAY,
	SET_ADM_ITEM_NEW_ITEM_DISPLAY,
	SET_ADM_ITEM_SOURCE_NEW_ITEM_DISPLAY,
	SET_ADM_LOC_MODAL_NEW_ACCOUNTS_PAYABLE_DISPLAY,
	SET_ADM_LOC_MODAL_NEW_ACCOUNTS_RECEIVABLE_DISPLAY,
	SET_ADM_LOC_MODAL_NEW_BILLING_ADDRESS_DISPLAY,
	SET_ADM_LOC_MODAL_NEW_LOCATION_NAME_ENG_DISPLAY,
	SET_ADM_LOC_MODAL_NEW_LOCATION_PHARMACY_IND_DISPLAY,
	SET_ADM_ROLE_NEW_ROLE_DISPLAY,
	SET_ADM_SERVICE_NEW_SERVICE_DISPLAY,
	SET_ADM_SUPPLIER_NEW_SUPPLIER_DISPLAY,
	SET_ADM_TAX_NEW_TAX_DISPLAY,
	SET_ADM_UNIT_NEW_UNIT_DISPLAY,
	SET_ADM_USER_CHANGE_PASSWORD_DISPLAY,
	SET_ADM_USER_NEW_USER_DISPLAY,
	SET_ADM_USER_SIGNATURE_DISPLAY,
	SET_AP_MODAL_GENERAL_LEDGER_DISPLAY,
	SET_AP_MODAL_LOCATION_DISPLAY,
	SET_AP_SEARCH_DISPLAY,
	SET_ARC_MODAL_GENERAL_LEDGER_DISPLAY,
	SET_ARC_MODAL_LOCATION_DISPLAY,
	SET_ARC_SEARCH_DISPLAY,
	SET_AR_ADJUSTMENT_DISPLAY,
	SET_AR_EDIT_INVOICE_DISPLAY,
	SET_AR_MODAL_GENERAL_LEDGER_DISPLAY,
	SET_AR_MODAL_LOCATION_DISPLAY,
	SET_AR_SEARCH_DISPLAY,
	SET_AUTHORIZATION_DISPLAY,
	SET_CASHIER_CERTIFICATE_DISPLAY,
	SET_CASHIER_DOCTOR_DISPLAY,
	SET_CASHIER_FINANCIAL_PLAN_DISPLAY,
	SET_CASHIER_LAB_DISPLAY,
	SET_CASHIER_LOCATION_DISPLAY,
	SET_CASHIER_MODAL_ADJUSTMENT_DISPLAY,
	SET_CASHIER_NURSE_DISPLAY,
	SET_CASHIER_PAYMENT_DISPLAY,
	SET_CASHIER_SEARCH_PATIENT_DISPLAY,
	SET_CONFIG_ICD_NEW_DISPLAY,
	SET_CONFIG_LABORATORY_ANTIGEN_COMMENT_NEW_DISPLAY,
	SET_CONFIG_LABORATORY_PCR_COMMENT_NEW_DISPLAY,
	SET_CONFIG_LEVEL_NEW_DISPLAY,
	SET_CONTRACT_BILLING_INFO_DISPLAY,
	SET_CONTRACT_BILLING_INVOICE_INFO_DISPLAY,
	SET_CONTRACT_BILLING_LOCATION_DISPLAY,
	SET_CONTRACT_BILLING_NEW_DISPLAY,
	SET_CONTRACT_BILLING_SEARCH_DISPLAY,
	SET_DEBT_ADJUSTMENT_DISPLAY,
	SET_DEBT_EDIT_INVOICE_DISPLAY,
	SET_DEBT_MODAL_LOCATION_DISPLAY,
	SET_DEBT_MODAL_PAYMENT_DISPLAY,
	SET_DEBT_NEW_DISPLAY,
	SET_DEBT_SEARCH_DISPLAY,
	SET_DEBT_SEARCH_MORE_DISPLAY,
	SET_DEPOSIT_SAVING_DISPLAY,
	SET_DEPOSIT_SEARCH_PATIENT_DISPLAY,
	SET_DEPOSIT_WITHDRAW_DISPLAY,
	SET_FO_EDIT_REQUEST_DISPLAY,
	SET_FO_LOCATION_DISPLAY,
	SET_FO_NEW_DISPLAY,
	SET_FO_SEARCH_DISPLAY,
	SET_FO_SEARCH_MORE_DISPLAY,
	SET_FRONT_INVENTORY_EDIT_DISPLAY,
	SET_FRONT_INVENTORY_SPLIT_DISPLAY,
	SET_FRONT_INVENTORY_SPLIT_EDIT_DISPLAY,
	SET_FRONT_INVENTORY_WASTE_DISPLAY,
	SET_FRONT_INVENTORY_WASTE_EDIT_DISPLAY,
	SET_HOSPITAL_MODAL_CLINICAL_RECORD_CALLBACK,
	SET_HOSPITAL_MODAL_CLINICAL_RECORD_DISPLAY,
	SET_HOSPITAL_MODAL_CLINICAL_RECORD_EDIT_DISPLAY,
	SET_HOSPITAL_MODAL_PATIENT_CLINICAL_RECORD_PATIENT,
	SET_HOSPITAL_MODAL_SAVE_ALL_DISABLE_END_DATE_IND,
	SET_HOSPITAL_MODAL_SAVE_ALL_DISPLAY,
	SET_HOSPITAL_MODAL_SAVE_ALL_END_DATE_TIME,
	SET_HOSPITAL_MODAL_SAVE_ALL_START_DATE_TIME,
	SET_HOSPITEL_HOSPITEL_NEW_DISPLAY,
	SET_HOSPITEL_HOSPITEL_SEARCH_DISPLAY,
	SET_HOSPITEL_IPD_REGISTER_DISPLAY,
	SET_HOSPITEL_IPD_SEARCH_DISPLAY,
	SET_HOSPITEL_LAB_APPROVE_DISPLAY,
	SET_HOSPITEL_LAB_LAB_DISPLAY,
	SET_HOSPITEL_LAB_NEGATIVE_DISPLAY,
	SET_HOSPITEL_LAB_POSITIVE_DISPLAY,
	SET_HOSPITEL_LAB_REGISTER_PATIENT,
	SET_HOSPITEL_LAB_SEARCH_PATIENT,
	SET_HOSPITEL_MODAL_ADJUSTMENT_DISPLAY,
	SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_DISPLAY,
	SET_HOSPITEL_MODAL_DEFAULT_ORDER_DISPLAY,
	SET_HOSPITEL_MODAL_DOCTOR_NOTE_DISPLAY,
	SET_HOSPITEL_MODAL_DOCTOR_ORDER_DISPLAY,
	SET_HOSPITEL_MODAL_DOCTOR_SERVICE_DISPLAY,
	SET_HOSPITEL_MODAL_LOCATION_DISPLAY,
	SET_HOSPITEL_MODAL_NURSE_ORDER_DISPLAY,
	SET_HOSPITEL_MODAL_NURSE_RECORD_DISPLAY,
	SET_HOSPITEL_MODAL_NURSE_SERVICE_DISPLAY,
	SET_HOSPITEL_MODAL_VITAL_SIGN_DISPLAY,
	SET_HOSPITEL_REGISTER_CLINICAL_RECORD_DISPLAY,
	SET_HOSPITEL_STOP_DISPLAY,
	SET_INVENTORY_EDIT_INVENTORY_DISPLAY,
	SET_INVENTORY_MIN_STOCK_DISPLAY,
	SET_INVENTORY_NEW_LOCATION_ADDRESS_DISPLAY,
	SET_IPD_CREATE_PRN_RECORD_DISPLAY,
	SET_IPD_DOCTOR_INFO_DISPLAY,
	SET_IPD_DOCTOR_SOAP_INFO_DISPLAY,
	SET_IPD_GCS_DISPLAY,
	SET_IPD_ICD_DISPLAY,
	SET_IPD_INTAKE_OUTPUT_DISPLAY,
	SET_IPD_MEDICAL_CERTIFICATE_DISPLAY,
	SET_IPD_NURSE_INFO_DISPLAY,
	SET_IPD_PAIN_SCALE_DISPLAY,
	SET_IPD_PRN_RECORD_DISPLAY,
	SET_IPD_PUPIL_SIZE_DISPLAY,
	SET_MODAL_WARNING_SIZE,
	SET_NEW_LOCATION_DISPLAY,
	SET_NEW_LOCATION_PARENT_ORG_DISPLAY,
	SET_NEW_LOCATION_TAX_NO_DISPLAY,
	SET_NEW_TYPE_DISPLAY,
	SET_NOTIFICATION_DISPLAY,
	SET_NOTIFICATION_NEW_DISPLAY,
	SET_NOTIFICATION_SEARCH_PATIENT_DISPLAY,
	SET_OPD_ADJUSTMENT_DISPLAY,
	SET_OPD_APPOINTMENT_DISPLAY,
	SET_OPD_APPOINTMENT_EDIT_DISPLAY,
	SET_OPD_DOCTOR_DISPLAY,
	SET_OPD_DOCTOR_INFO_DISPLAY,
	SET_OPD_DOCTOR_INSTRUCTION_DISPLAY,
	SET_OPD_DOCTOR_SERVICE_DISPLAY,
	SET_OPD_GCS_DISPLAY,
	SET_OPD_ICD_DISPLAY,
	SET_OPD_INTAKE_OUTPUT_DISPLAY,
	SET_OPD_LOACTION_DISPLAY,
	SET_OPD_MEDICAL_CERTIFICATE_DISPLAY,
	SET_OPD_MEDICAL_RECORD_DISPLAY,
	SET_OPD_MODIFY_DATE_DISPLAY,
	SET_OPD_NURSE_DISPLAY,
	SET_OPD_NURSE_INFO_DISPLAY,
	SET_OPD_NURSE_ORDER_DISPLAY,
	SET_OPD_NURSE_PRE_OPERATIVE_CHECK_LIST_DISPLAY,
	SET_OPD_NURSE_SERVICE_DISPLAY,
	SET_OPD_OPERATING_ROOM_CALENDAR_DISPLAY,
	SET_OPD_OPERATING_ROOM_DISPLAY,
	SET_OPD_OPERATING_ROOM_REQUEST_INFO_DISPLAY,
	SET_OPD_PAIN_SCALE_DISPLAY,
	SET_OPD_PUPIL_SIZE_DISPLAY,
	SET_OPD_SEARCH_DISPLAY,
	SET_OPD_VITAL_SIGN_DISPLAY,
	SET_OR_ANESTHESIOLOGIST_EDIT_PRE_DISPLAY,
	SET_OR_CANCEL_DISPLAY,
	SET_OR_EDIT_REQUEST_DISPLAY,
	SET_OR_INTRA_ANESTHESIA_AIRWAY_MANAGEMENT_DISPLAY,
	SET_OR_INTRA_ANESTHESIA_BABY_DELIVERY_DISPLAY,
	SET_OR_INTRA_ANESTHESIA_INTAKE_OUTPUT_DISPLAY,
	SET_OR_INTRA_ANESTHESIA_MEDICATION_DISPLAY,
	SET_OR_INTRA_ANESTHESIA_NEW_VITAL_SIGN_DISPLAY,
	SET_OR_INTRA_ANESTHESIA_PATIENT_CONDITION_DISPLAY,
	SET_OR_INTRA_ANESTHESIA_PATIENT_SAFETY_DISPLAY,
	SET_OR_INTRA_ANESTHESIA_PREINDUCTION_ASSESSMENT_DISPLAY,
	SET_OR_INTRA_ANESTHESIA_PREMEDICATION_DISPLAY,
	SET_OR_INTRA_ANESTHESIA_PROBLEM_DISPLAY,
	SET_OR_INTRA_ANESTHESIA_RECORD_DISPLAY,
	SET_OR_INTRA_ANESTHESIA_RECORD_TIME_DISPLAY,
	SET_OR_INTRA_ANESTHESIA_SERVICE_DISPLAY,
	SET_OR_INTRA_ANESTHESIA_TECHNIQUE_DISPLAY,
	SET_OR_INTRA_ANESTHESIA_TRANSFER_DISPLAY,
	SET_OR_INTRA_ANESTHESIA_VITAL_SIGN_DISPLAY,
	SET_OR_LOCATION_DISPLAY,
	SET_OR_NURSE_EDIT_PRE_OPERATIVE_CHECK_LIST,
	SET_OR_POST_ANESTHESIA_COMPLICATION_DISPLAY,
	SET_OR_POST_ANESTHESIA_DISCHARGE_DISPLAY,
	SET_OR_POST_ANESTHESIA_INTAKE_OUTPUT_DISPLAY,
	SET_OR_POST_ANESTHESIA_MEDICATION_DISPLAY,
	SET_OR_POST_ANESTHESIA_NEW_VITAL_SIGN_DISPLAY,
	SET_OR_POST_ANESTHESIA_NURSE_DISPLAY,
	SET_OR_POST_ANESTHESIA_PADSS_DISPLAY,
	SET_OR_POST_ANESTHESIA_RECORD_DISPLAY,
	SET_OR_POST_ANESTHESIA_RECORD_TIME_DISPLAY,
	SET_OR_POST_ANESTHESIA_SERVICE_DISPLAY,
	SET_OR_POST_ANESTHESIA_TRANSFER_DISPLAY,
	SET_OR_POST_ANESTHESIA_VITAL_SIGN_DISPLAY,
	SET_OR_REMARK_DISPLAY,
	SET_OR_SCHEDULE_DISPLAY,
	SET_OR_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_DISPLAY,
	SET_OR_SURGICAL_SAFETY_CHECKLIST_SIGN_OUT_DISPLAY,
	SET_OR_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_DISPLAY,
	SET_OR_VITAL_SIGN_DISPLAY,
	SET_PGL_ENTRY_DISPLAY,
	SET_PGL_LOCATION_DISPLAY,
	SET_PGL_NEW_DISPLAY,
	SET_PGL_SEARCH_DISPLAY,
	SET_PHARMACY_LOCATION_DISPLAY,
	SET_PHARMACY_RETURN_DISPLAY,
	SET_PHARMACY_RETURN_EDIT_DISPLAY,
	SET_PHARMACY_SEARCH_DISPLAY,
	SET_PHARMACY_VERIFY_DISPLAY,
	SET_PI_LINK_DISPLAY,
	SET_PI_LOCATION_DISPLAY,
	SET_PI_NEW_DISPLAY,
	SET_PI_PURCHASE_DISPLAY,
	SET_PI_SEARCH_DISPLAY,
	SET_PO_EDIT_DISPLAY,
	SET_PO_EDIT_ITEM_DISPLAY,
	SET_PO_REJECT_REASON_DISPLAY,
	SET_PS_GENERAL_LEDGER_DISPLAY,
	SET_PS_LOCATION_DISPLAY,
	SET_PS_NEW_DISPLAY,
	SET_PS_PAYMENT_PLAN_DISPLAY,
	SET_PS_SEARCH_DISPLAY,
	SET_PV_LOCATION_DISPLAY,
	SET_PV_NEW_DISPLAY,
	SET_PV_PURCHASE_DISPLAY,
	SET_PV_SEARCH_DISPLAY,
	SET_RC_CANCEL_DISPLAY,
	SET_RC_CANCEL_REASON_DISPLAY,
	SET_RC_RECEIVE_DETAIL_DISPLAY,
	SET_RC_RECEIVE_DISPLAY,
	SET_RC_SEARCH_DISPLAY,
	SET_REGISTRATION_ADJUSTMENT_DISPLAY,
	SET_REGISTRATION_BUDGET_DISPLAY,
	SET_REGISTRATION_EXPENSE_ESTIMATION_DISPLAY,
	SET_REPORT_FINANCE_APA_FILTER_DISPLAY,
	SET_REPORT_FINANCE_ARA_FILTER_DISPLAY,
	SET_REPORT_FINANCE_GL_FILTER_DISPLAY,
	SET_REPORT_HOSPITAL_CASHIER_ADMISSION_FILTER_DISPLAY,
	SET_REPORT_HOSPITAL_CASHIER_BILLING_TO_TODAY_FILTER_DISPLAY,
	SET_REPORT_HOSPITAL_CASHIER_USER_ROLE_FILTER_DISPLAY,
	SET_REPORT_HOSPITAL_HOSPITEL_PATIENT_FILTER_DISPLAY,
	SET_REPORT_HOSPITAL_LAB_COVID19_FILTER_DISPLAY,
	SET_REPORT_INV_FILTER_BY_LOCATION,
	SET_REPORT_PO_FILTER_BY_ID,
	SET_REPORT_PO_FILTER_BY_LOCATION,
	SET_REPORT_TF_FILTER_BY_LOCATION,
	SET_SA_BUDGET_DISPLAY,
	SET_SA_COST_DISPLAY,
	SET_SA_INSPECT_DISPLAY,
	SET_SA_LOCATION_DISPLAY,
	SET_SA_REJECT_DISPLAY,
	SET_SA_SEARCH_DISPLAY,
	SET_SELECTABLE_ACCOUNTS_CODE_DISPLAY,
	SET_TF_CANCEL_REQUEST_ITEM_DISPLAY,
	SET_TF_NEW_REQUEST_DISPLAY,
	SET_TF_NEW_TRANSFER_DISPLAY,
	SET_TIMELINE_DISPLAY,
	SET_TIMELINE_SEARCH_PATIENT_DISPLAY,
	SET_WARNING_CLOSE,
	SET_WARNING_CONTINUE,
	SET_WARNING_DISPLAY,
	SET_WARNING_ID,
	SET_WARNING_MESSAGE,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"

const init = {
	createPR: false,
	isAPGeneralLedgerDisplay: false,
	isARAdjustmentDisplay: false,
	isAREditInvoiceDisplay: false,
	isARGeneralLedgerDisplay: false,
	isAccountsPayableLocationDisplay: false,
	isAccountsPayableSearchDisplay: false,
	isAccountsReceivableLocationDisplay: false,
	isAccountsReceivableSearchDisplay: false,
	isAccountsReceiveGeneralLedgerDisplay: false,
	isAccountsReceiveLocationDisplay: false,
	isAccountsReceiveSearchDisplay: false,
	isAddInventoryDisplay: false,
	isAdmBankNewBranchDisplay: false,
	isAdmCategoryInventoryPayableDisplay: false,
	isAdmCategoryInventoryReceivableDisplay: false,
	isAdmCategoryPaymentAccountsPayableDisplay: false,
	isAdmCustomDefaultNewFocusDisplay: false,
	isAdmCustomDefaultNewInterventionDisplay: false,
	isAdmFNewFormDisplay: false,
	isAdmHealthCarePlanAccountsReceivableDisplay: false,
	isAdmHealthCarePlanCompanyDisplay: false,
	isAdmIInfoDisplay: false,
	isAdmINewItemDisplay: false,
	isAdmISNewItemDisplay: false,
	isAdmItemConfigUnitDisplay: false,
	isAdmRoleNewRoleDisplay: false,
	isAdmSNewSupplierDisplay: false,
	isAdmServiceNewServiceDisplay: false,
	isAdmTNewTaxDisplay: false,
	isAdmUNewUnitDisplay: false,
	isAdmUserChangePasswordDisplay: false,
	isAdmUserNewUserDisplay: false,
	isAdmUserSignatureDisplay: false,
	isAuthorizationDisplay: false,
	isCashierFinancialPlanDisplay: false,
	isCashierLocationDisplay: false,
	isCashierPaymentDisplay: false,
	isConfigAssetsAddDisplay: false,
	isConfigEquityAddDisplay: false,
	isConfigExpenseAddDisplay: false,
	isConfigGLPayableDisplay: false,
	isConfigGLReceivableDisplay: false,
	isConfigICDNewDisplay: false,
	isConfigLaboratoryAntigenCommentNewDisplay: false,
	isConfigLaboratoryPCRCommentNewDisplay: false,
	isConfigLevelNewDisplay: false,
	isConfigLiabilitiesAddDisplay: false,
	isConfigRevenueAddDisplay: false,
	isContractBillingInfoDisplay: false,
	isContractBillingInvoiceInfoDisplay: false,
	isContractBillingLocationDisplay: false,
	isContractBillingNewDisplay: false,
	isContractBillingSearchDisplay: false,
	isDebtAdjustmentDisplay: false,
	isDebtEditInvoiceDisplay: false,
	isDebtLocationDisplay: false,
	isDebtNewDisplay: false,
	isDebtPaymentDisplay: false,
	isDebtSearchDisplay: false,
	isDebtSearchMoreDisplay: false,
	isDepositSavingDisplay: false,
	isDepositSearchPatientDisplay: false,
	isDepositWithdrawDisplay: false,
	isEditInventoryDisplay: false,
	isFinancialOperationsEditRequestDisplay: false,
	isFinancialOperationsLocationDisplay: false,
	isFinancialOperationsNewDisplay: false,
	isFinancialOperationsSearchDisplay: false,
	isFinancialOperationsSearchMoreDisplay: false,
	isFrontInventoryEditDisplay: false,
	isFrontInventorySplitDisplay: false,
	isFrontInventorySplitEditDisplay: false,
	isFrontInventoryWasteDisplay: false,
	isFrontInventoryWasteEditDisplay: false,
	isIPDCreatePRNRecordDisplay: false,
	isIPDPRNRecordDisplay: false,
	isInpatientDoctorInfoDisplay: false,
	isInpatientDoctorSOAPInfoDisplay: false,
	isInpatientGCSDisplay: false,
	isInpatientICDDisplay: false,
	isInpatientIntakeOuputDisplay: false,
	isInpatientMedicalCertificateDisplay: false,
	isInpatientNurseInfoDisplay: false,
	isInpatientPainScaleDisplay: false,
	isInpatientPupilSizeDisplay: false,
	isInventoryMinStockDisplay: false,
	isMenuNotificationDisplay: false,
	isMenuSelectableAccountsCodeDisplay: false,
	isMenuNotificationNewDisplay: false,
	isMenuNotificationSearchDisplay: false,
	isMenuTimelineDisplay: false,
	isMenuTimelineSearchDisplay: false,
	isMinStockDisplay: false,
	isNewLocationDisplay: false,
	isNewTypeDisplay: false,
	isOutpatientAdjustmentDisplay: false,
	isOutpatientAppointmentDisplay: false,
	isOutpatientAppointmentEditDisplay: false,
	isOutpatientDoctorDisplay: false,
	isOutpatientDoctorInfoDisplay: false,
	isOutpatientDoctorInstructionDisplay: false,
	isOutpatientDoctorServiceDisplay: false,
	isOutpatientGCSDisplay: false,
	isOutpatientICDDisplay: false,
	isOutpatientIntakeOuputDisplay: false,
	isOutpatientLocationDisplay: false,
	isOutpatientMedicalCertificateDisplay: false,
	isOutpatientMedicalRecordDisplay: false,
	isOutpatientModifyDateDisplay: false,
	isOutpatientNurseDisplay: false,
	isOutpatientNurseInfoDisplay: false,
	isOutpatientNurseOrderDisplay: false,
	isOutpatientNurseServiceDisplay: false,
	isOutpatientPainScaleDisplay: false,
	isOutpatientNursePreOperativeCheckListDisplay: false,
	isOutpatientPupilSizeDisplay: false,
	isOutpatientSearchDisplay: false,
	isOutpatientVitalSignDisplay: false,
	isPOEditDisplay: false,
	isPOEditItemDisplay: false,
	isPORejectDisplay: false,
	isPSGeneralLedgerDisplay: false,
	isPaymentSlipLocationDisplay: false,
	isPaymentSlipNewDisplay: false,
	isPaymentSlipPaymentPlanDisplay: false,
	isPaymentSlipSearchDisplay: false,
	isPaymentVoucherLocationDisplay: false,
	isPaymentVoucherNewDisplay: false,
	isPaymentVoucherPurchaseDisplay: false,
	isPaymentVoucherSearchDisplay: false,
	isPharmacyLocationDisplay: false,
	isPharmacyReturnDisplay: false,
	isPharmacyReturnEditDisplay: false,
	isPharmacySearchDisplay: false,
	isPharmacyVerifyDisplay: false,
	isPostingGLEntryDisplay: false,
	isPostingGLLocationDisplay: false,
	isPostingGLNewDisplay: false,
	isPostingGLSearchDisplay: false,
	isPurchaseInvoiceLocationDisplay: false,
	isPurchaseInvoiceNewDisplay: false,
	isPurchaseInvoicePurchaseDisplay: false,
	isPurchaseInvoiceSearchDisplay: false,
	isPurchaseInvoiceLinkDisplay: false,
	isRCCancelDisplay: false,
	isRCCancelReasonDisplay: false,
	isRCReceiveDetailDisplay: false,
	isRCReceiveDisplay: false,
	isRCSearchDisplay: false,
	isRegistrationAdjustmentDisplay: false,
	isRegistrationBudgetDisplay: false,
	isRegistrationExpenseEstimationDisplay: false,
	isReportFinanceAccountsPayableAgingDisplay: false,
	isReportFinanceAccountsReceivableAgingDisplay: false,
	isReportFinanceGeneralLedgerDisplay: false,
	isTFCancelRequestItemDisplay: false,
	isTFNewRequestDisplay: false,
	isTFNewTransferDisplay: false,
	isWarningDisplay: false,
	isOutpatientOperatingRoomDisplay: false,
	isOutpatientOperatingRoomRequestInfoDisplay: false,
	isOperatingRoomLocationDisplay: false,
	isOutpatientOperatingRoomCalendarDisplay: false,
	isSurgicalAuthorizationLocationDisplay: false,
	isSurgicalAuthBudgetDisplay: false,
	isSurgicalAuthCostDisplay: false,
	isSurgicalAuthRejectDisplay: false,
	isSurgicalAuthInspectDisplay: false,
	isSurgicalAuthSearchDisplay: false,
	isOperatingRoomScheduleDisplay: false,
	isOperatingRoomRemarkDisplay: false,
	isOperatingRoomCancelDisplay: false,
	isOperatingRoomEditRequestDisplay: false,
	isOperatingRoomEditAnesthesiologistPreDisplay: false,
	isOperatingRoomVitalSignDisplay: false,
	isOperatingRoomNurseEditPreOperativeCheckListDisplay: false,
	isOperatingRoomSurgicalSafetyCheckListSignInDisplay: false,
	isOperatingRoomSurgicalSafetyCheckListTimeOutDisplay: false,
	isOperatingRoomSurgicalSafetyCheckListSignOutDisplay: false,
	isOperatingRoomIntraAnesthesiaPatientSafetyDisplay: false,
	isOperatingRoomIntraAnesthesiaPreinductionAssessmentDisplay: false,
	isOperatingRoomIntraAnesthesiaProblemDisplay: false,
	isOperatingRoomIntraAnesthesiaPremedicationDisplay: false,
	isOperatingRoomIntraAnesthesiaPatientConditionDisplay: false,
	isOperatingRoomIntraAnesthesiaTechniqueDisplay: false,
	isOperatingRoomIntraAnesthesiaAirwayManagementDisplay: false,
	isOperatingRoomIntraAnesthesiaBabyDeliveryDisplay: false,
	isOperatingRoomIntraAnesthesiaTransferDisplay: false,
	isOperatingRoomIntraAnesthesiaRecordDisplay: false,
	isOperatingRoomIntraAnesthesiaRecordTimeDisplay: false,
	isOperatingRoomIntraAnesthesiaIntakeOuputDisplay: false,
	isOperatingRoomIntraAnesthesiaMedicationDisplay: false,
	isOperatingRoomIntraAnesthesiaServiceDisplay: false,
	isOperatingRoomIntraAnesthesiaVitalSignDisplay: false,
	isOperatingRoomIntraAnesthesiaNewVitalSignDisplay: false,
	isOperatingRoomPostAnesthesiaNurseDisplay: false,
	isOperatingRoomPostAnesthesiaComplicationDisplay: false,
	isOperatingRoomPostAnesthesiaDischargeDisplay: false,
	isOperatingRoomPostAnesthesiaTransferDisplay: false,
	isOperatingRoomPostAnesthesiaPADSSDisplay: false,
	isOperatingRoomPostAnesthesiaRecordDisplay: false,
	isOperatingRoomPostAnesthesiaRecordTimeDisplay: false,
	isOperatingRoomPostAnesthesiaMedicationDisplay: false,
	isOperatingRoomPostAnesthesiaServiceDisplay: false,
	isOperatingRoomPostAnesthesiaIntakeOuputDisplay: false,
	isOperatingRoomPostAnesthesiaVitalSignDisplay: false,
	isOperatingRoomPostAnesthesiaNewVitalSignDisplay: false,
	newPr: {
		type: "",
	},
	warning: {
		id: "",
		isClose: false,
		isContinue: false,
		msg: "",
		size: ResourceAssistance.Modal.size.sm,
	},
	newLoc: {
		isParentOrgDisplay: false,
		isAddressDisplay: false,
		isBillingAddressDisplay: false,
		isTaxNoDisplay: false,
		isNameEngDisplay: false,
		isPharmacyIndDisplay: false,
		isAccountsReceivableDisplay: false,
		isAccountsPayableDisplay: false,
	},
	report: {
		inv: {
			isReportByLocationDisplay: false,
		},
		tf: {
			isReportByLocationDisplay: false,
		},
		po: {
			isReportByIdDisplay: false,
			isReportByLocationDisplay: false,
		},
		hospital: {
			cashier: {
				isAdmissionDisplay: false,
				isUserRoleDisplay: false,
				isBillingToTodayDisplay: false,
			},
			hospitel: {
				isPatientDisplay: false,
			},
			lab: {
				isCovid19Display: false,
			},
		},
	},
	hospitel: {
		lab: {
			isRegisterPatientDisplay: false,
			isSearchPatientDisplay: false,
			isPositiveDisplay: false,
			isNewLabDisplay: false,
			isNegativeDisplay: false,
			isApproveDisplay: false,
		},
		ipd: {
			isSearchPatientDisplay: false,
			isRegisterPatientDisplay: false,
			isClinicalRecordDisplay: false,
		},
		hospitel: {
			isNewDisplay: false,
			isSearchDisplay: false,
			isDoctorOrderDisplay: false,
			isStopDisplay: false,
			isNurseRecordDisplay: false,
			isDoctorNoteDisplay: false,
			isVitalSignDisplay: false,
			isDefaultOrderDisplay: false,
			isLocationDisplay: false,
			isNurseOrderDisplay: false,
			isNurseServiceDisplay: false,
			isAdjustmentDisplay: false,
			isAdjustmentServiceDisplay: false,
			isDoctorServiceDisplay: false,
		},
		cashier: {
			isSearchPatientDisplay: false,
			isLabDisplay: false,
			isCertificateDisplay: false,
			isAdjustmentDisplay: false,
			isDoctorDisplay: false,
			isNurseDisplay: false,
		},
	},
	hospital: {
		clinicalRecord: {
			isClinicalRecordDisplay: false,
			isEditDisplay: false,
			patient: {},
			callback: () => {},
		},
		saveAll: {
			isSaveAllDisplay: false,
			isDisableEndDate: true,
			startDateTime: Utils.generateDate().getTime(),
			endDateTime: Utils.generateDate(0, 0, 0, 23, 59, 59).getTime(),
		},
	},
}

const modalReducer = (state = init, action) => {
	switch (action.type) {
		case PR_NEW:
			return Object.assign({}, state, {
				createPR: action.payload.isDisplay,
			})

		case PR_MIN_STOCK:
			return Object.assign({}, state, {
				isMinStockDisplay: action.payload.isDisplay,
			})

		case PR_DONE_HANDLER:
			return Object.assign({}, state, {
				newPr: {
					...state.newPr,
					type: action.payload.type,
				},
			})

		case SET_WARNING_ID:
			return Object.assign({}, state, {
				warning: {
					...state.warning,
					id: action.payload.id,
				},
			})

		case SET_WARNING_DISPLAY:
			return Object.assign({}, state, {
				isWarningDisplay: action.payload.isDisplay,
			})

		case SET_WARNING_MESSAGE:
			return Object.assign({}, state, {
				warning: {
					...state.warning,
					msg: action.payload.msg,
				},
			})

		case SET_ADD_INVENTORY_DISPLAY:
			return Object.assign({}, state, {
				isAddInventoryDisplay: action.payload.isDisplay,
			})

		case SET_NEW_TYPE_DISPLAY:
			return Object.assign({}, state, {
				isNewTypeDisplay: action.payload.isDisplay,
			})

		case SET_NEW_LOCATION_DISPLAY:
			return Object.assign({}, state, {
				isNewLocationDisplay: action.payload.isDisplay,
			})

		case SET_NEW_LOCATION_PARENT_ORG_DISPLAY:
			return Object.assign({}, state, {
				newLoc: {
					...state.newLoc,
					isParentOrgDisplay: action.payload.isDisplay,
				},
			})

		case SET_NEW_LOCATION_TAX_NO_DISPLAY:
			return Object.assign({}, state, {
				newLoc: {
					...state.newLoc,
					isTaxNoDisplay: action.payload.isDisplay,
				},
			})
		case SET_ADM_LOC_MODAL_NEW_LOCATION_NAME_ENG_DISPLAY:
			return Object.assign({}, state, {
				newLoc: {
					...state.newLoc,
					isNameEngDisplay: action.payload.isDisplay,
				},
			})
		case SET_ADM_LOC_MODAL_NEW_LOCATION_PHARMACY_IND_DISPLAY:
			return Object.assign({}, state, {
				newLoc: {
					...state.newLoc,
					isPharmacyIndDisplay: action.payload.isDisplay,
				},
			})
		case SET_ADM_LOC_MODAL_NEW_ACCOUNTS_RECEIVABLE_DISPLAY:
			return Object.assign({}, state, {
				newLoc: {
					...state.newLoc,
					isAccountsReceivableDisplay: action.payload.isDisplay,
				},
			})
		case SET_ADM_LOC_MODAL_NEW_ACCOUNTS_PAYABLE_DISPLAY:
			return Object.assign({}, state, {
				newLoc: {
					...state.newLoc,
					isAccountsPayableDisplay: action.payload.isDisplay,
				},
			})
		case SET_INVENTORY_NEW_LOCATION_ADDRESS_DISPLAY:
			return Object.assign({}, state, {
				newLoc: {
					...state.newLoc,
					isAddressDisplay: action.payload.isDisplay,
				},
			})
		case SET_ADM_LOC_MODAL_NEW_BILLING_ADDRESS_DISPLAY:
			return Object.assign({}, state, {
				newLoc: {
					...state.newLoc,
					isBillingAddressDisplay: action.payload.isDisplay,
				},
			})
		case SET_INVENTORY_EDIT_INVENTORY_DISPLAY:
			return Object.assign({}, state, {
				isEditInventoryDisplay: action.payload.isDisplay,
			})
		case SET_INVENTORY_MIN_STOCK_DISPLAY:
			return Object.assign({}, state, {
				isInventoryMinStockDisplay: action.payload.isDisplay,
			})
		case SET_PO_EDIT_DISPLAY:
			return Object.assign({}, state, {
				isPOEditDisplay: action.payload.isDisplay,
			})
		case SET_PO_EDIT_ITEM_DISPLAY:
			return Object.assign({}, state, {
				isPOEditItemDisplay: action.payload.isDisplay,
			})
		case SET_PO_REJECT_REASON_DISPLAY:
			return Object.assign({}, state, {
				isPORejectDisplay: action.payload.isDisplay,
			})
		case SET_RC_RECEIVE_DISPLAY:
			return Object.assign({}, state, {
				isRCReceiveDisplay: action.payload.isDisplay,
			})
		case SET_RC_SEARCH_DISPLAY:
			return Object.assign({}, state, {
				isRCSearchDisplay: action.payload.isDisplay,
			})

		case SET_RC_CANCEL_DISPLAY:
			return Object.assign({}, state, {
				isRCCancelDisplay: action.payload.isDisplay,
			})

		case SET_RC_RECEIVE_DETAIL_DISPLAY:
			return Object.assign({}, state, {
				isRCReceiveDetailDisplay: action.payload.isDisplay,
			})

		case SET_RC_CANCEL_REASON_DISPLAY:
			return Object.assign({}, state, {
				isRCCancelReasonDisplay: action.payload.isDisplay,
			})

		case SET_TF_NEW_REQUEST_DISPLAY:
			return Object.assign({}, state, {
				isTFNewRequestDisplay: action.payload.isDisplay,
			})

		case SET_TF_NEW_TRANSFER_DISPLAY:
			return Object.assign({}, state, {
				isTFNewTransferDisplay: action.payload.isDisplay,
			})

		case SET_TF_CANCEL_REQUEST_ITEM_DISPLAY:
			return Object.assign({}, state, {
				isTFCancelRequestItemDisplay: action.payload.isDisplay,
			})

		case SET_ADM_SUPPLIER_NEW_SUPPLIER_DISPLAY:
			return Object.assign({}, state, {
				isAdmSNewSupplierDisplay: action.payload.isDisplay,
			})

		case SET_ADM_UNIT_NEW_UNIT_DISPLAY:
			return Object.assign({}, state, {
				isAdmUNewUnitDisplay: action.payload.isDisplay,
			})

		case SET_ADM_FORM_NEW_FORM_DISPLAY:
			return Object.assign({}, state, {
				isAdmFNewFormDisplay: action.payload.isDisplay,
			})

		case SET_ADM_TAX_NEW_TAX_DISPLAY:
			return Object.assign({}, state, {
				isAdmTNewTaxDisplay: action.payload.isDisplay,
			})

		case SET_ADM_ITEM_NEW_ITEM_DISPLAY:
			return Object.assign({}, state, {
				isAdmINewItemDisplay: action.payload.isDisplay,
			})
		case SET_ADM_ITEM_INFO_DISPLAY:
			return Object.assign({}, state, {
				isAdmIInfoDisplay: action.payload.isDisplay,
			})
		case SET_ADM_ITEM_SOURCE_NEW_ITEM_DISPLAY:
			return Object.assign({}, state, {
				isAdmISNewItemDisplay: action.payload.isDisplay,
			})
		case SET_ADM_USER_NEW_USER_DISPLAY:
			return Object.assign({}, state, {
				isAdmUserNewUserDisplay: action.payload.isDisplay,
			})
		case SET_ADM_USER_CHANGE_PASSWORD_DISPLAY:
			return Object.assign({}, state, {
				isAdmUserChangePasswordDisplay: action.payload.isDisplay,
			})
		case SET_ADM_ROLE_NEW_ROLE_DISPLAY:
			return Object.assign({}, state, {
				isAdmRoleNewRoleDisplay: action.payload.isDisplay,
			})

		case SET_ADM_SERVICE_NEW_SERVICE_DISPLAY:
			return Object.assign({}, state, {
				isAdmServiceNewServiceDisplay: action.payload.isDisplay,
			})

		case SET_REPORT_PO_FILTER_BY_LOCATION:
			return Object.assign({}, state, {
				report: {
					...state.report,
					po: {
						...state.report.po,
						isReportByLocationDisplay: action.payload.isDisplay,
					},
				},
			})

		case SET_REPORT_HOSPITAL_LAB_COVID19_FILTER_DISPLAY:
			return Object.assign({}, state, {
				report: {
					...state.report,
					hospital: {
						...state.report.hospital,
						lab: {
							...state.report.hospital.lab,
							isCovid19Display: action.payload.isDisplay,
						},
					},
				},
			})

		case SET_REPORT_HOSPITAL_HOSPITEL_PATIENT_FILTER_DISPLAY:
			return Object.assign({}, state, {
				report: {
					...state.report,
					hospital: {
						...state.report.hospital,
						hospitel: {
							...state.report.hospital.hospitel,
							isPatientDisplay: action.payload.isDisplay,
						},
					},
				},
			})

		case SET_REPORT_PO_FILTER_BY_ID:
			return Object.assign({}, state, {
				report: {
					...state.report,
					po: {
						...state.report.po,
						isReportByIdDisplay: action.payload.isDisplay,
					},
				},
			})

		case SET_REPORT_TF_FILTER_BY_LOCATION:
			return Object.assign({}, state, {
				report: {
					...state.report,
					tf: {
						...state.report.tf,
						isReportByLocationDisplay: action.payload.isDisplay,
					},
				},
			})

		case SET_REPORT_INV_FILTER_BY_LOCATION:
			return Object.assign({}, state, {
				report: {
					...state.report,
					inv: {
						...state.report.inv,
						isReportByLocationDisplay: action.payload.isDisplay,
					},
				},
			})

		case SET_WARNING_CONTINUE:
			return Object.assign({}, state, {
				warning: {
					...state.warning,
					isContinue: action.payload.isContinue,
				},
			})

		case SET_WARNING_CLOSE:
			return Object.assign({}, state, {
				warning: {
					...state.warning,
					isClose: action.payload.isClose,
				},
			})

		// Hospitel
		case SET_HOSPITEL_LAB_REGISTER_PATIENT:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					lab: {
						...state.hospitel.lab,
						isRegisterPatientDisplay: action.payload.isDisplay,
					},
				},
			})

		case SET_HOSPITEL_LAB_SEARCH_PATIENT:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					lab: {
						...state.hospitel.lab,
						isSearchPatientDisplay: action.payload.isDisplay,
					},
				},
			})

		case SET_CASHIER_DOCTOR_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					cashier: {
						...state.hospitel.cashier,
						isDoctorDisplay: action.payload.isDisplay,
					},
				},
			})

		case SET_CASHIER_NURSE_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					cashier: {
						...state.hospitel.cashier,
						isNurseDisplay: action.payload.isDisplay,
					},
				},
			})

		case SET_HOSPITEL_LAB_POSITIVE_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					lab: {
						...state.hospitel.lab,
						isPositiveDisplay: action.payload.isDisplay,
					},
				},
			})

		case SET_HOSPITEL_LAB_LAB_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					lab: {
						...state.hospitel.lab,
						isNewLabDisplay: action.payload.isDisplay,
					},
				},
			})

		case SET_HOSPITEL_LAB_NEGATIVE_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					lab: {
						...state.hospitel.lab,
						isNegativeDisplay: action.payload.isDisplay,
					},
				},
			})

		case SET_HOSPITEL_LAB_APPROVE_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					lab: {
						...state.hospitel.lab,
						isApproveDisplay: action.payload.isDisplay,
					},
				},
			})

		case SET_HOSPITEL_IPD_REGISTER_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					ipd: {
						...state.hospitel.ipd,
						isRegisterPatientDisplay: action.payload.isDisplay,
					},
				},
			})

		case SET_HOSPITEL_IPD_SEARCH_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					ipd: {
						...state.hospitel.ipd,
						isSearchPatientDisplay: action.payload.isDisplay,
					},
				},
			})

		case SET_HOSPITEL_HOSPITEL_NEW_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					hospitel: {
						...state.hospitel.hospitel,
						isNewDisplay: action.payload.isDisplay,
					},
				},
			})

		case SET_HOSPITEL_HOSPITEL_SEARCH_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					hospitel: {
						...state.hospitel.hospitel,
						isSearchDisplay: action.payload.isDisplay,
					},
				},
			})

		case SET_OPD_MEDICAL_CERTIFICATE_DISPLAY:
			return Object.assign({}, state, {
				isOutpatientMedicalCertificateDisplay: action.payload.isDisplay,
			})

		case SET_CASHIER_SEARCH_PATIENT_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					cashier: {
						...state.hospitel.cashier,
						isSearchPatientDisplay: action.payload.isDisplay,
					},
				},
			})

		case SET_CASHIER_LAB_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					cashier: {
						...state.hospitel.cashier,
						isLabDisplay: action.payload.isDisplay,
					},
				},
			})
		case SET_CASHIER_CERTIFICATE_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					cashier: {
						...state.hospitel.cashier,
						isCertificateDisplay: action.payload.isDisplay,
					},
				},
			})
		case SET_HOSPITEL_REGISTER_CLINICAL_RECORD_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					ipd: {
						...state.hospitel.ipd,
						isClinicalRecordDisplay: action.payload.isDisplay,
					},
				},
			})
		case SET_CASHIER_MODAL_ADJUSTMENT_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					cashier: {
						...state.hospitel.cashier,
						isAdjustmentDisplay: action.payload.isDisplay,
					},
				},
			})
		case SET_HOSPITEL_MODAL_DOCTOR_ORDER_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					hospitel: {
						...state.hospitel.hospitel,
						isDoctorOrderDisplay: action.payload.isDisplay,
					},
				},
			})
		case SET_HOSPITEL_STOP_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					hospitel: {
						...state.hospitel.hospitel,
						isStopDisplay: action.payload.isDisplay,
					},
				},
			})
		case SET_HOSPITEL_MODAL_NURSE_RECORD_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					hospitel: {
						...state.hospitel.hospitel,
						isNurseRecordDisplay: action.payload.isDisplay,
					},
				},
			})
		case SET_HOSPITEL_MODAL_DOCTOR_NOTE_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					hospitel: {
						...state.hospitel.hospitel,
						isDoctorNoteDisplay: action.payload.isDisplay,
					},
				},
			})
		case SET_HOSPITEL_MODAL_VITAL_SIGN_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					hospitel: {
						...state.hospitel.hospitel,
						isVitalSignDisplay: action.payload.isDisplay,
					},
				},
			})
		case SET_HOSPITEL_MODAL_DEFAULT_ORDER_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					hospitel: {
						...state.hospitel.hospitel,
						isDefaultOrderDisplay: action.payload.isDisplay,
					},
				},
			})
		case SET_HOSPITEL_MODAL_LOCATION_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					hospitel: {
						...state.hospitel.hospitel,
						isLocationDisplay: action.payload.isDisplay,
					},
				},
			})
		case SET_HOSPITAL_MODAL_CLINICAL_RECORD_DISPLAY:
			return Object.assign({}, state, {
				hospital: {
					...state.hospital,
					clinicalRecord: {
						...state.hospital.clinicalRecord,
						isClinicalRecordDisplay: action.payload.isDisplay,
					},
				},
			})
		case SET_HOSPITAL_MODAL_CLINICAL_RECORD_EDIT_DISPLAY:
			return Object.assign({}, state, {
				hospital: {
					...state.hospital,
					clinicalRecord: {
						...state.hospital.clinicalRecord,
						isEditDisplay: action.payload.value,
					},
				},
			})
		case SET_HOSPITAL_MODAL_CLINICAL_RECORD_CALLBACK:
			return Object.assign({}, state, {
				hospital: {
					...state.hospital,
					clinicalRecord: {
						...state.hospital.clinicalRecord,
						callback: action.payload.value,
					},
				},
			})
		case SET_HOSPITAL_MODAL_PATIENT_CLINICAL_RECORD_PATIENT:
			return Object.assign({}, state, {
				hospital: {
					...state.hospital,
					clinicalRecord: {
						...state.hospital.clinicalRecord,
						patient: action.payload.value,
					},
				},
			})
		case SET_HOSPITEL_MODAL_NURSE_ORDER_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					hospitel: {
						...state.hospitel.hospitel,
						isNurseOrderDisplay: action.payload.isDisplay,
					},
				},
			})
		case SET_HOSPITEL_MODAL_NURSE_SERVICE_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					hospitel: {
						...state.hospitel.hospitel,
						isNurseServiceDisplay: action.payload.isDisplay,
					},
				},
			})
		case SET_HOSPITEL_MODAL_ADJUSTMENT_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					hospitel: {
						...state.hospitel.hospitel,
						isAdjustmentDisplay: action.payload.isDisplay,
					},
				},
			})
		case SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					hospitel: {
						...state.hospitel.hospitel,
						isAdjustmentServiceDisplay: action.payload.isDisplay,
					},
				},
			})
		case SET_HOSPITEL_MODAL_DOCTOR_SERVICE_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					hospitel: {
						...state.hospitel.hospitel,
						isDoctorServiceDisplay: action.payload.isDisplay,
					},
				},
			})
		case SET_HOSPITAL_MODAL_SAVE_ALL_DISPLAY:
			return Object.assign({}, state, {
				hospital: {
					...state.hospital,
					saveAll: {
						...state.hospital.saveAll,
						isSaveAllDisplay: action.payload.isDisplay,
					},
				},
			})
		case SET_HOSPITAL_MODAL_SAVE_ALL_DISABLE_END_DATE_IND:
			return Object.assign({}, state, {
				hospital: {
					...state.hospital,
					saveAll: {
						...state.hospital.saveAll,
						isDisableEndDate: action.payload.value,
					},
				},
			})
		case SET_HOSPITAL_MODAL_SAVE_ALL_START_DATE_TIME:
			return Object.assign({}, state, {
				hospital: {
					...state.hospital,
					saveAll: {
						...state.hospital.saveAll,
						startDateTime: action.payload.value,
					},
				},
			})
		case SET_HOSPITAL_MODAL_SAVE_ALL_END_DATE_TIME:
			return Object.assign({}, state, {
				hospital: {
					...state.hospital,
					saveAll: {
						...state.hospital.saveAll,
						endDateTime: action.payload.value,
					},
				},
			})
		case SET_REPORT_HOSPITAL_CASHIER_ADMISSION_FILTER_DISPLAY:
			return Object.assign({}, state, {
				report: {
					...state.report,
					hospital: {
						...state.report.hospital,
						cashier: {
							...state.report.hospital.cashier,
							isAdmissionDisplay: action.payload.isDisplay,
						},
					},
				},
			})
		case SET_REPORT_HOSPITAL_CASHIER_USER_ROLE_FILTER_DISPLAY:
			return Object.assign({}, state, {
				report: {
					...state.report,
					hospital: {
						...state.report.hospital,
						cashier: {
							...state.report.hospital.cashier,
							isUserRoleDisplay: action.payload.isDisplay,
						},
					},
				},
			})
		case SET_REPORT_HOSPITAL_CASHIER_BILLING_TO_TODAY_FILTER_DISPLAY:
			return Object.assign({}, state, {
				report: {
					...state.report,
					hospital: {
						...state.report.hospital,
						cashier: {
							...state.report.hospital.cashier,
							isBillingToTodayDisplay: action.payload.isDisplay,
						},
					},
				},
			})
		case SET_ADM_USER_SIGNATURE_DISPLAY:
			return Object.assign({}, state, {
				isAdmUserSignatureDisplay: action.payload.isDisplay,
			})
		case SET_ADM_CUSTOM_DEFAULT_NEW_FOCUS_DISPLAY: {
			return Object.assign({}, state, {
				isAdmCustomDefaultNewFocusDisplay: action.payload.isDisplay,
			})
		}
		case SET_ADM_CUSTOM_DEFAULT_NEW_INTERVENTION_DISPLAY: {
			return Object.assign({}, state, {
				isAdmCustomDefaultNewInterventionDisplay: action.payload.isDisplay,
			})
		}
		case SET_IPD_PRN_RECORD_DISPLAY:
			return Object.assign({}, state, {
				isIPDPRNRecordDisplay: action.payload.isDisplay,
			})
		case SET_IPD_CREATE_PRN_RECORD_DISPLAY:
			return Object.assign({}, state, {
				isIPDCreatePRNRecordDisplay: action.payload.isDisplay,
			})
		case SET_CASHIER_FINANCIAL_PLAN_DISPLAY:
			return Object.assign({}, state, {
				isCashierFinancialPlanDisplay: action.payload.isDisplay,
			})
		case SET_CASHIER_PAYMENT_DISPLAY:
			return Object.assign({}, state, {
				isCashierPaymentDisplay: action.payload.isDisplay,
			})
		case SET_DEPOSIT_SEARCH_PATIENT_DISPLAY:
			return Object.assign({}, state, {
				isDepositSearchPatientDisplay: action.payload.isDisplay,
			})
		case SET_DEPOSIT_SAVING_DISPLAY:
			return Object.assign({}, state, {
				isDepositSavingDisplay: action.payload.isDisplay,
			})
		case SET_DEPOSIT_WITHDRAW_DISPLAY:
			return Object.assign({}, state, {
				isDepositWithdrawDisplay: action.payload.isDisplay,
			})
		case SET_OPD_LOACTION_DISPLAY:
			return Object.assign({}, state, {
				isOutpatientLocationDisplay: action.payload.isDisplay,
			})
		case SET_OPD_APPOINTMENT_DISPLAY:
			return Object.assign({}, state, {
				isOutpatientAppointmentDisplay: action.payload.isDisplay,
			})
		case SET_OPD_SEARCH_DISPLAY:
			return Object.assign({}, state, {
				isOutpatientSearchDisplay: action.payload.isDisplay,
			})
		case SET_OPD_MEDICAL_RECORD_DISPLAY:
			return Object.assign({}, state, {
				isOutpatientMedicalRecordDisplay: action.payload.isDisplay,
			})
		case SET_OPD_DOCTOR_DISPLAY:
			return Object.assign({}, state, {
				isOutpatientDoctorDisplay: action.payload.isDisplay,
			})
		case SET_OPD_MODIFY_DATE_DISPLAY:
			return Object.assign({}, state, {
				isOutpatientModifyDateDisplay: action.payload.isDisplay,
			})
		case SET_MODAL_WARNING_SIZE:
			return Object.assign({}, state, {
				warning: {
					...state.warning,
					size: action.payload.value,
				},
			})
		case SET_OPD_DOCTOR_SERVICE_DISPLAY:
			return Object.assign({}, state, {
				isOutpatientDoctorServiceDisplay: action.payload.isDisplay,
			})
		case SET_OPD_DOCTOR_INSTRUCTION_DISPLAY:
			return Object.assign({}, state, {
				isOutpatientDoctorInstructionDisplay: action.payload.isDisplay,
			})
		case SET_OPD_NURSE_DISPLAY:
			return Object.assign({}, state, {
				isOutpatientNurseDisplay: action.payload.isDisplay,
			})
		case SET_OPD_VITAL_SIGN_DISPLAY:
			return Object.assign({}, state, {
				isOutpatientVitalSignDisplay: action.payload.isDisplay,
			})
		case SET_OPD_NURSE_ORDER_DISPLAY:
			return Object.assign({}, state, {
				isOutpatientNurseOrderDisplay: action.payload.isDisplay,
			})
		case SET_OPD_ADJUSTMENT_DISPLAY:
			return Object.assign({}, state, {
				isOutpatientAdjustmentDisplay: action.payload.isDisplay,
			})
		case SET_OPD_NURSE_SERVICE_DISPLAY:
			return Object.assign({}, state, {
				isOutpatientNurseServiceDisplay: action.payload.isDisplay,
			})
		case SET_OPD_APPOINTMENT_EDIT_DISPLAY:
			return Object.assign({}, state, {
				isOutpatientAppointmentEditDisplay: action.payload.isDisplay,
			})
		case SET_OPD_NURSE_INFO_DISPLAY:
			return Object.assign({}, state, {
				isOutpatientNurseInfoDisplay: action.payload.isDisplay,
			})
		case SET_OPD_DOCTOR_INFO_DISPLAY:
			return Object.assign({}, state, {
				isOutpatientDoctorInfoDisplay: action.payload.isDisplay,
			})
		case SET_IPD_MEDICAL_CERTIFICATE_DISPLAY:
			return Object.assign({}, state, {
				isInpatientMedicalCertificateDisplay: action.payload.isDisplay,
			})
		case SET_CONFIG_ICD_NEW_DISPLAY:
			return Object.assign({}, state, {
				isConfigICDNewDisplay: action.payload.isDisplay,
			})
		case SET_CONFIG_LEVEL_NEW_DISPLAY:
			return Object.assign({}, state, {
				isConfigLevelNewDisplay: action.payload.isDisplay,
			})
		case SET_OPD_ICD_DISPLAY:
			return Object.assign({}, state, {
				isOutpatientICDDisplay: action.payload.isDisplay,
			})
		case SET_IPD_ICD_DISPLAY:
			return Object.assign({}, state, {
				isInpatientICDDisplay: action.payload.isDisplay,
			})
		case SET_REGISTRATION_EXPENSE_ESTIMATION_DISPLAY:
			return Object.assign({}, state, {
				isRegistrationExpenseEstimationDisplay: action.payload.isDisplay,
			})
		case SET_REGISTRATION_ADJUSTMENT_DISPLAY:
			return Object.assign({}, state, {
				isRegistrationAdjustmentDisplay: action.payload.isDisplay,
			})
		case SET_REGISTRATION_BUDGET_DISPLAY:
			return Object.assign({}, state, {
				isRegistrationBudgetDisplay: action.payload.isDisplay,
			})
		case SET_OPD_INTAKE_OUTPUT_DISPLAY:
			return Object.assign({}, state, {
				isOutpatientIntakeOuputDisplay: action.payload.isDisplay,
			})
		case SET_OPD_GCS_DISPLAY:
			return Object.assign({}, state, {
				isOutpatientGCSDisplay: action.payload.isDisplay,
			})
		case SET_IPD_INTAKE_OUTPUT_DISPLAY:
			return Object.assign({}, state, {
				isInpatientIntakeOuputDisplay: action.payload.isDisplay,
			})
		case SET_IPD_GCS_DISPLAY:
			return Object.assign({}, state, {
				isInpatientGCSDisplay: action.payload.isDisplay,
			})
		case SET_OPD_PAIN_SCALE_DISPLAY:
			return Object.assign({}, state, {
				isOutpatientPainScaleDisplay: action.payload.isDisplay,
			})
		case SET_OPD_NURSE_PRE_OPERATIVE_CHECK_LIST_DISPLAY:
			return Object.assign({}, state, {
				isOutpatientNursePreOperativeCheckListDisplay: action.payload.isDisplay,
			})
		case SET_IPD_PAIN_SCALE_DISPLAY:
			return Object.assign({}, state, {
				isInpatientPainScaleDisplay: action.payload.isDisplay,
			})
		case SET_OPD_PUPIL_SIZE_DISPLAY:
			return Object.assign({}, state, {
				isOutpatientPupilSizeDisplay: action.payload.isDisplay,
			})
		case SET_IPD_PUPIL_SIZE_DISPLAY:
			return Object.assign({}, state, {
				isInpatientPupilSizeDisplay: action.payload.isDisplay,
			})
		case SET_IPD_DOCTOR_INFO_DISPLAY: {
			return Object.assign({}, state, {
				isInpatientDoctorInfoDisplay: action.payload.isDisplay,
			})
		}
		case SET_IPD_NURSE_INFO_DISPLAY: {
			return Object.assign({}, state, {
				isInpatientNurseInfoDisplay: action.payload.isDisplay,
			})
		}
		case SET_IPD_DOCTOR_SOAP_INFO_DISPLAY:
			return Object.assign({}, state, {
				isInpatientDoctorSOAPInfoDisplay: action.payload.isDisplay,
			})
		case SET_NOTIFICATION_SEARCH_PATIENT_DISPLAY:
			return Object.assign({}, state, {
				isMenuNotificationSearchDisplay: action.payload.isDisplay,
			})
		case SET_NOTIFICATION_DISPLAY:
			return Object.assign({}, state, {
				isMenuNotificationDisplay: action.payload.isDisplay,
			})
		case SET_SELECTABLE_ACCOUNTS_CODE_DISPLAY:
			return Object.assign({}, state, {
				isMenuSelectableAccountsCodeDisplay: action.payload.isDisplay,
			})
		case SET_NOTIFICATION_NEW_DISPLAY:
			return Object.assign({}, state, {
				isMenuNotificationNewDisplay: action.payload.isDisplay,
			})
		case SET_TIMELINE_DISPLAY:
			return Object.assign({}, state, {
				isMenuTimelineDisplay: action.payload.isDisplay,
			})
		case SET_TIMELINE_SEARCH_PATIENT_DISPLAY:
			return Object.assign({}, state, {
				isMenuTimelineSearchDisplay: action.payload.isDisplay,
			})
		case SET_PHARMACY_LOCATION_DISPLAY:
			return Object.assign({}, state, {
				isPharmacyLocationDisplay: action.payload.isDisplay,
			})
		case SET_PHARMACY_SEARCH_DISPLAY:
			return Object.assign({}, state, {
				isPharmacySearchDisplay: action.payload.isDisplay,
			})
		case SET_FRONT_INVENTORY_EDIT_DISPLAY:
			return Object.assign({}, state, {
				isFrontInventoryEditDisplay: action.payload.isDisplay,
			})
		case SET_FRONT_INVENTORY_WASTE_DISPLAY:
			return Object.assign({}, state, {
				isFrontInventoryWasteDisplay: action.payload.isDisplay,
			})
		case SET_FRONT_INVENTORY_WASTE_EDIT_DISPLAY:
			return Object.assign({}, state, {
				isFrontInventoryWasteEditDisplay: action.payload.isDisplay,
			})
		case SET_CONFIG_LABORATORY_PCR_COMMENT_NEW_DISPLAY:
			return Object.assign({}, state, {
				isConfigLaboratoryPCRCommentNewDisplay: action.payload.isDisplay,
			})
		case SET_CONFIG_LABORATORY_ANTIGEN_COMMENT_NEW_DISPLAY:
			return Object.assign({}, state, {
				isConfigLaboratoryAntigenCommentNewDisplay: action.payload.isDisplay,
			})
		case SET_PHARMACY_VERIFY_DISPLAY:
			return Object.assign({}, state, {
				isPharmacyVerifyDisplay: action.payload.isDisplay,
			})
		case SET_PHARMACY_RETURN_DISPLAY:
			return Object.assign({}, state, {
				isPharmacyReturnDisplay: action.payload.isDisplay,
			})
		case SET_PHARMACY_RETURN_EDIT_DISPLAY:
			return Object.assign({}, state, {
				isPharmacyReturnEditDisplay: action.payload.isDisplay,
			})
		case SET_AUTHORIZATION_DISPLAY:
			return Object.assign({}, state, {
				isAuthorizationDisplay: action.payload.isDisplay,
			})
		case SET_ADM_ITEM_CONFIG_UNIT_DISPLAY: {
			return Object.assign({}, state, {
				isAdmItemConfigUnitDisplay: action.payload.isDisplay,
			})
		}
		case SET_ADM_HEALTH_CARE_PLAN_COMPANY_DISPLAY: {
			return Object.assign({}, state, {
				isAdmHealthCarePlanCompanyDisplay: action.payload.isDisplay,
			})
		}
		case SET_ADM_HEALTH_CARE_PLAN_ACCOUNTS_RECEIVABLE_DISPLAY: {
			return Object.assign({}, state, {
				isAdmHealthCarePlanAccountsReceivableDisplay: action.payload.isDisplay,
			})
		}
		case SET_CONTRACT_BILLING_LOCATION_DISPLAY: {
			return Object.assign({}, state, {
				isContractBillingLocationDisplay: action.payload.isDisplay,
			})
		}
		case SET_CONTRACT_BILLING_NEW_DISPLAY: {
			return Object.assign({}, state, {
				isContractBillingNewDisplay: action.payload.isDisplay,
			})
		}
		case SET_CONTRACT_BILLING_INVOICE_INFO_DISPLAY: {
			return Object.assign({}, state, {
				isContractBillingInvoiceInfoDisplay: action.payload.isDisplay,
			})
		}
		case SET_CONTRACT_BILLING_INFO_DISPLAY: {
			return Object.assign({}, state, {
				isContractBillingInfoDisplay: action.payload.isDisplay,
			})
		}
		case SET_CONTRACT_BILLING_SEARCH_DISPLAY: {
			return Object.assign({}, state, {
				isContractBillingSearchDisplay: action.payload.isDisplay,
			})
		}
		case SET_CASHIER_LOCATION_DISPLAY: {
			return Object.assign({}, state, {
				isCashierLocationDisplay: action.payload.isDisplay,
			})
		}
		case SET_DEBT_NEW_DISPLAY: {
			return Object.assign({}, state, {
				isDebtNewDisplay: action.payload.isDisplay,
			})
		}
		case SET_DEBT_SEARCH_MORE_DISPLAY:
			return Object.assign({}, state, {
				isDebtSearchMoreDisplay: action.payload.isDisplay,
			})
		case SET_DEBT_MODAL_PAYMENT_DISPLAY:
			return Object.assign({}, state, {
				isDebtPaymentDisplay: action.payload.isDisplay,
			})
		case SET_DEBT_MODAL_LOCATION_DISPLAY:
			return Object.assign({}, state, {
				isDebtLocationDisplay: action.payload.isDisplay,
			})
		case SET_ADM_BANK_NEW_BRANCH_DISPLAY:
			return Object.assign({}, state, {
				isAdmBankNewBranchDisplay: action.payload.isDisplay,
			})
		case SET_DEBT_SEARCH_DISPLAY: {
			return Object.assign({}, state, {
				isDebtSearchDisplay: action.payload.isDisplay,
			})
		}
		case SET_DEBT_EDIT_INVOICE_DISPLAY: {
			return Object.assign({}, state, {
				isDebtEditInvoiceDisplay: action.payload.isDisplay,
			})
		}
		case SET_DEBT_ADJUSTMENT_DISPLAY: {
			return Object.assign({}, state, {
				isDebtAdjustmentDisplay: action.payload.isDisplay,
			})
		}
		case SET_AR_MODAL_LOCATION_DISPLAY: {
			return Object.assign({}, state, {
				isAccountsReceivableLocationDisplay: action.payload.isDisplay,
			})
		}
		case SET_AP_MODAL_LOCATION_DISPLAY: {
			return Object.assign({}, state, {
				isAccountsPayableLocationDisplay: action.payload.isDisplay,
			})
		}
		case SET_AR_ADJUSTMENT_DISPLAY: {
			return Object.assign({}, state, {
				isARAdjustmentDisplay: action.payload.isDisplay,
			})
		}
		case SET_AR_EDIT_INVOICE_DISPLAY: {
			return Object.assign({}, state, {
				isAREditInvoiceDisplay: action.payload.isDisplay,
			})
		}
		case SET_ADM_COA_ASSETS_ADD_DISPLAY: {
			return Object.assign({}, state, {
				isConfigAssetsAddDisplay: action.payload.isDisplay,
			})
		}
		case SET_ADM_COA_LIABILITIES_ADD_DISPLAY: {
			return Object.assign({}, state, {
				isConfigLiabilitiesAddDisplay: action.payload.isDisplay,
			})
		}
		case SET_ADM_COA_EQUITY_ADD_DISPLAY: {
			return Object.assign({}, state, {
				isConfigEquityAddDisplay: action.payload.isDisplay,
			})
		}
		case SET_ADM_COA_EXPENSES_ADD_DISPLAY: {
			return Object.assign({}, state, {
				isConfigExpenseAddDisplay: action.payload.isDisplay,
			})
		}
		case SET_ADM_COA_REVENUES_ADD_DISPLAY: {
			return Object.assign({}, state, {
				isConfigRevenueAddDisplay: action.payload.isDisplay,
			})
		}
		case SET_ADM_CATEGORY_COA_RECEIABLE_DISPLAY: {
			return Object.assign({}, state, {
				isAdmCategoryInventoryReceivableDisplay: action.payload.isDisplay,
			})
		}
		case SET_ADM_CATEGORY_COA_PAYABLE_DISPLAY: {
			return Object.assign({}, state, {
				isAdmCategoryInventoryPayableDisplay: action.payload.isDisplay,
			})
		}
		case SET_AR_MODAL_GENERAL_LEDGER_DISPLAY: {
			return Object.assign({}, state, {
				isARGeneralLedgerDisplay: action.payload.isDisplay,
			})
		}
		case SET_AP_MODAL_GENERAL_LEDGER_DISPLAY: {
			return Object.assign({}, state, {
				isAPGeneralLedgerDisplay: action.payload.isDisplay,
			})
		}
		case SET_ADM_GL_RECEIVABLE_DISPLAY:
			return Object.assign({}, state, {
				isConfigGLReceivableDisplay: action.payload.isDisplay,
			})
		case SET_ADM_GL_PAYABLE_DISPLAY:
			return Object.assign({}, state, {
				isConfigGLPayableDisplay: action.payload.isDisplay,
			})
		case SET_AR_SEARCH_DISPLAY:
			return Object.assign({}, state, {
				isAccountsReceivableSearchDisplay: action.payload.isDisplay,
			})
		case SET_AP_SEARCH_DISPLAY:
			return Object.assign({}, state, {
				isAccountsPayableSearchDisplay: action.payload.isDisplay,
			})
		case SET_PS_LOCATION_DISPLAY: {
			return Object.assign({}, state, {
				isPaymentSlipLocationDisplay: action.payload.isDisplay,
			})
		}
		case SET_PS_NEW_DISPLAY:
			return Object.assign({}, state, {
				isPaymentSlipNewDisplay: action.payload.isDisplay,
			})
		case SET_PS_SEARCH_DISPLAY:
			return Object.assign({}, state, {
				isPaymentSlipSearchDisplay: action.payload.isDisplay,
			})
		case SET_PS_PAYMENT_PLAN_DISPLAY:
			return Object.assign({}, state, {
				isPaymentSlipPaymentPlanDisplay: action.payload.isDisplay,
			})
		case SET_PS_GENERAL_LEDGER_DISPLAY:
			return Object.assign({}, state, {
				isPSGeneralLedgerDisplay: action.payload.isDisplay,
			})
		case SET_FO_LOCATION_DISPLAY:
			return Object.assign({}, state, {
				isFinancialOperationsLocationDisplay: action.payload.isDisplay,
			})
		case SET_FO_NEW_DISPLAY:
			return Object.assign({}, state, {
				isFinancialOperationsNewDisplay: action.payload.isDisplay,
			})
		case SET_FO_SEARCH_MORE_DISPLAY:
			return Object.assign({}, state, {
				isFinancialOperationsSearchMoreDisplay: action.payload.isDisplay,
			})
		case SET_FO_EDIT_REQUEST_DISPLAY:
			return Object.assign({}, state, {
				isFinancialOperationsEditRequestDisplay: action.payload.isDisplay,
			})
		case SET_FO_SEARCH_DISPLAY:
			return Object.assign({}, state, {
				isFinancialOperationsSearchDisplay: action.payload.isDisplay,
			})
		case SET_PI_LOCATION_DISPLAY:
			return Object.assign({}, state, {
				isPurchaseInvoiceLocationDisplay: action.payload.isDisplay,
			})
		case SET_PI_NEW_DISPLAY:
			return Object.assign({}, state, {
				isPurchaseInvoiceNewDisplay: action.payload.isDisplay,
			})
		case SET_PI_PURCHASE_DISPLAY:
			return Object.assign({}, state, {
				isPurchaseInvoicePurchaseDisplay: action.payload.isDisplay,
			})
		case SET_PI_SEARCH_DISPLAY:
			return Object.assign({}, state, {
				isPurchaseInvoiceSearchDisplay: action.payload.isDisplay,
			})
		case SET_PI_LINK_DISPLAY:
			return Object.assign({}, state, {
				isPurchaseInvoiceLinkDisplay: action.payload.isDisplay,
			})
		case SET_PV_LOCATION_DISPLAY:
			return Object.assign({}, state, {
				isPaymentVoucherLocationDisplay: action.payload.isDisplay,
			})
		case SET_PV_SEARCH_DISPLAY:
			return Object.assign({}, state, {
				isPaymentVoucherSearchDisplay: action.payload.isDisplay,
			})
		case SET_PV_NEW_DISPLAY:
			return Object.assign({}, state, {
				isPaymentVoucherNewDisplay: action.payload.isDisplay,
			})
		case SET_PV_PURCHASE_DISPLAY:
			return Object.assign({}, state, {
				isPaymentVoucherPurchaseDisplay: action.payload.isDisplay,
			})
		case SET_ARC_MODAL_LOCATION_DISPLAY:
			return Object.assign({}, state, {
				isAccountsReceiveLocationDisplay: action.payload.isDisplay,
			})
		case SET_ARC_MODAL_GENERAL_LEDGER_DISPLAY:
			return Object.assign({}, state, {
				isAccountsReceiveGeneralLedgerDisplay: action.payload.isDisplay,
			})
		case SET_ADM_CATEGORY_PAYMENT_PAYABLE_DISPLAY:
			return Object.assign({}, state, {
				isAdmCategoryPaymentAccountsPayableDisplay: action.payload.isDisplay,
			})
		case SET_ARC_SEARCH_DISPLAY:
			return Object.assign({}, state, {
				isAccountsReceiveSearchDisplay: action.payload.isDisplay,
			})
		case SET_PGL_LOCATION_DISPLAY:
			return Object.assign({}, state, {
				isPostingGLLocationDisplay: action.payload.isDisplay,
			})
		case SET_PGL_NEW_DISPLAY:
			return Object.assign({}, state, {
				isPostingGLNewDisplay: action.payload.isDisplay,
			})
		case SET_PGL_ENTRY_DISPLAY:
			return Object.assign({}, state, {
				isPostingGLEntryDisplay: action.payload.isDisplay,
			})
		case SET_PGL_SEARCH_DISPLAY:
			return Object.assign({}, state, {
				isPostingGLSearchDisplay: action.payload.isDisplay,
			})
		case SET_FRONT_INVENTORY_SPLIT_DISPLAY:
			return Object.assign({}, state, {
				isFrontInventorySplitDisplay: action.payload.isDisplay,
			})
		case SET_FRONT_INVENTORY_SPLIT_EDIT_DISPLAY:
			return Object.assign({}, state, {
				isFrontInventorySplitEditDisplay: action.payload.isDisplay,
			})
		case SET_REPORT_FINANCE_GL_FILTER_DISPLAY:
			return Object.assign({}, state, {
				isReportFinanceGeneralLedgerDisplay: action.payload.isDisplay,
			})
		case SET_REPORT_FINANCE_APA_FILTER_DISPLAY:
			return Object.assign({}, state, {
				isReportFinanceAccountsPayableAgingDisplay: action.payload.isDisplay,
			})
		case SET_REPORT_FINANCE_ARA_FILTER_DISPLAY:
			return Object.assign({}, state, {
				isReportFinanceAccountsReceivableAgingDisplay: action.payload.isDisplay,
			})
		case SET_OPD_OPERATING_ROOM_DISPLAY:
			return Object.assign({}, state, {
				isOutpatientOperatingRoomDisplay: action.payload.isDisplay,
			})
		case SET_OPD_OPERATING_ROOM_REQUEST_INFO_DISPLAY:
			return Object.assign({}, state, {
				isOutpatientOperatingRoomRequestInfoDisplay: action.payload.isDisplay,
			})
		case SET_OR_LOCATION_DISPLAY:
			return Object.assign({}, state, {
				isOperatingRoomLocationDisplay: action.payload.isDisplay,
			})
		case SET_OPD_OPERATING_ROOM_CALENDAR_DISPLAY:
			return Object.assign({}, state, {
				isOutpatientOperatingRoomCalendarDisplay: action.payload.isDisplay,
			})
		case SET_SA_LOCATION_DISPLAY:
			return Object.assign({}, state, {
				isSurgicalAuthorizationLocationDisplay: action.payload.isDisplay,
			})
		case SET_SA_BUDGET_DISPLAY:
			return Object.assign({}, state, {
				isSurgicalAuthBudgetDisplay: action.payload.isDisplay,
			})
		case SET_SA_COST_DISPLAY:
			return Object.assign({}, state, {
				isSurgicalAuthCostDisplay: action.payload.isDisplay,
			})
		case SET_SA_REJECT_DISPLAY:
			return Object.assign({}, state, {
				isSurgicalAuthRejectDisplay: action.payload.isDisplay,
			})
		case SET_SA_INSPECT_DISPLAY:
			return Object.assign({}, state, {
				isSurgicalAuthInspectDisplay: action.payload.isDisplay,
			})
		case SET_SA_SEARCH_DISPLAY:
			return Object.assign({}, state, {
				isSurgicalAuthSearchDisplay: action.payload.isDisplay,
			})
		case SET_OR_SCHEDULE_DISPLAY:
			return Object.assign({}, state, {
				isOperatingRoomScheduleDisplay: action.payload.isDisplay,
			})
		case SET_OR_REMARK_DISPLAY:
			return Object.assign({}, state, {
				isOperatingRoomRemarkDisplay: action.payload.isDisplay,
			})
		case SET_OR_CANCEL_DISPLAY:
			return Object.assign({}, state, {
				isOperatingRoomCancelDisplay: action.payload.isDisplay,
			})
		case SET_OR_EDIT_REQUEST_DISPLAY:
			return Object.assign({}, state, {
				isOperatingRoomEditRequestDisplay: action.payload.isDisplay,
			})
		case SET_OR_ANESTHESIOLOGIST_EDIT_PRE_DISPLAY:
			return Object.assign({}, state, {
				isOperatingRoomEditAnesthesiologistPreDisplay: action.payload.isDisplay,
			})
		case SET_OR_VITAL_SIGN_DISPLAY:
			return Object.assign({}, state, {
				isOperatingRoomVitalSignDisplay: action.payload.isDisplay,
			})
		case SET_OR_NURSE_EDIT_PRE_OPERATIVE_CHECK_LIST:
			return Object.assign({}, state, {
				isOperatingRoomNurseEditPreOperativeCheckListDisplay: action.payload.isDisplay,
			})
		case SET_OR_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_DISPLAY:
			return Object.assign({}, state, {
				isOperatingRoomSurgicalSafetyCheckListSignInDisplay: action.payload.isDisplay,
			})
		case SET_OR_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_DISPLAY:
			return Object.assign({}, state, {
				isOperatingRoomSurgicalSafetyCheckListTimeOutDisplay: action.payload.isDisplay,
			})
		case SET_OR_SURGICAL_SAFETY_CHECKLIST_SIGN_OUT_DISPLAY:
			return Object.assign({}, state, {
				isOperatingRoomSurgicalSafetyCheckListSignOutDisplay: action.payload.isDisplay,
			})
		case SET_OR_INTRA_ANESTHESIA_PATIENT_SAFETY_DISPLAY:
			return Object.assign({}, state, {
				isOperatingRoomIntraAnesthesiaPatientSafetyDisplay: action.payload.isDisplay,
			})
		case SET_OR_INTRA_ANESTHESIA_PREINDUCTION_ASSESSMENT_DISPLAY:
			return Object.assign({}, state, {
				isOperatingRoomIntraAnesthesiaPreinductionAssessmentDisplay: action.payload.isDisplay,
			})
		case SET_OR_INTRA_ANESTHESIA_PROBLEM_DISPLAY:
			return Object.assign({}, state, {
				isOperatingRoomIntraAnesthesiaProblemDisplay: action.payload.isDisplay,
			})
		case SET_OR_INTRA_ANESTHESIA_PREMEDICATION_DISPLAY:
			return Object.assign({}, state, {
				isOperatingRoomIntraAnesthesiaPremedicationDisplay: action.payload.isDisplay,
			})
		case SET_OR_INTRA_ANESTHESIA_PATIENT_CONDITION_DISPLAY:
			return Object.assign({}, state, {
				isOperatingRoomIntraAnesthesiaPatientConditionDisplay: action.payload.isDisplay,
			})
		case SET_OR_INTRA_ANESTHESIA_TECHNIQUE_DISPLAY:
			return Object.assign({}, state, {
				isOperatingRoomIntraAnesthesiaTechniqueDisplay: action.payload.isDisplay,
			})
		case SET_OR_INTRA_ANESTHESIA_AIRWAY_MANAGEMENT_DISPLAY:
			return Object.assign({}, state, {
				isOperatingRoomIntraAnesthesiaAirwayManagementDisplay: action.payload.isDisplay,
			})
		case SET_OR_INTRA_ANESTHESIA_BABY_DELIVERY_DISPLAY:
			return Object.assign({}, state, {
				isOperatingRoomIntraAnesthesiaBabyDeliveryDisplay: action.payload.isDisplay,
			})
		case SET_OR_INTRA_ANESTHESIA_TRANSFER_DISPLAY:
			return Object.assign({}, state, {
				isOperatingRoomIntraAnesthesiaTransferDisplay: action.payload.isDisplay,
			})
		case SET_OR_INTRA_ANESTHESIA_RECORD_DISPLAY:
			return Object.assign({}, state, {
				isOperatingRoomIntraAnesthesiaRecordDisplay: action.payload.isDisplay,
			})
		case SET_OR_INTRA_ANESTHESIA_RECORD_TIME_DISPLAY:
			return Object.assign({}, state, {
				isOperatingRoomIntraAnesthesiaRecordTimeDisplay: action.payload.isDisplay,
			})
		case SET_OR_INTRA_ANESTHESIA_INTAKE_OUTPUT_DISPLAY:
			return Object.assign({}, state, {
				isOperatingRoomIntraAnesthesiaIntakeOuputDisplay: action.payload.isDisplay,
			})
		case SET_OR_INTRA_ANESTHESIA_MEDICATION_DISPLAY:
			return Object.assign({}, state, {
				isOperatingRoomIntraAnesthesiaMedicationDisplay: action.payload.isDisplay,
			})
		case SET_OR_INTRA_ANESTHESIA_SERVICE_DISPLAY:
			return Object.assign({}, state, {
				isOperatingRoomIntraAnesthesiaServiceDisplay: action.payload.isDisplay,
			})
		case SET_OR_INTRA_ANESTHESIA_VITAL_SIGN_DISPLAY:
			return Object.assign({}, state, {
				isOperatingRoomIntraAnesthesiaVitalSignDisplay: action.payload.isDisplay,
			})
		case SET_OR_INTRA_ANESTHESIA_NEW_VITAL_SIGN_DISPLAY:
			return Object.assign({}, state, {
				isOperatingRoomIntraAnesthesiaNewVitalSignDisplay: action.payload.isDisplay,
			})
		case SET_OR_POST_ANESTHESIA_NURSE_DISPLAY:
			return Object.assign({}, state, {
				isOperatingRoomPostAnesthesiaNurseDisplay: action.payload.isDisplay,
			})
		case SET_OR_POST_ANESTHESIA_COMPLICATION_DISPLAY:
			return Object.assign({}, state, {
				isOperatingRoomPostAnesthesiaComplicationDisplay: action.payload.isDisplay,
			})
		case SET_OR_POST_ANESTHESIA_DISCHARGE_DISPLAY:
			return Object.assign({}, state, {
				isOperatingRoomPostAnesthesiaDischargeDisplay: action.payload.isDisplay,
			})
		case SET_OR_POST_ANESTHESIA_TRANSFER_DISPLAY:
			return Object.assign({}, state, {
				isOperatingRoomPostAnesthesiaTransferDisplay: action.payload.isDisplay,
			})
		case SET_OR_POST_ANESTHESIA_PADSS_DISPLAY:
			return Object.assign({}, state, {
				isOperatingRoomPostAnesthesiaPADSSDisplay: action.payload.isDisplay,
			})
		case SET_OR_POST_ANESTHESIA_RECORD_DISPLAY:
			return Object.assign({}, state, {
				isOperatingRoomPostAnesthesiaRecordDisplay: action.payload.isDisplay,
			})
		case SET_OR_POST_ANESTHESIA_RECORD_TIME_DISPLAY:
			return Object.assign({}, state, {
				isOperatingRoomPostAnesthesiaRecordTimeDisplay: action.payload.isDisplay,
			})
		case SET_OR_POST_ANESTHESIA_MEDICATION_DISPLAY:
			return Object.assign({}, state, {
				isOperatingRoomPostAnesthesiaMedicationDisplay: action.payload.isDisplay,
			})
		case SET_OR_POST_ANESTHESIA_SERVICE_DISPLAY:
			return Object.assign({}, state, {
				isOperatingRoomPostAnesthesiaServiceDisplay: action.payload.isDisplay,
			})
		case SET_OR_POST_ANESTHESIA_INTAKE_OUTPUT_DISPLAY:
			return Object.assign({}, state, {
				isOperatingRoomPostAnesthesiaIntakeOuputDisplay: action.payload.isDisplay,
			})
		case SET_OR_POST_ANESTHESIA_VITAL_SIGN_DISPLAY:
			return Object.assign({}, state, {
				isOperatingRoomPostAnesthesiaVitalSignDisplay: action.payload.isDisplay,
			})
		case SET_OR_POST_ANESTHESIA_NEW_VITAL_SIGN_DISPLAY:
			return Object.assign({}, state, {
				isOperatingRoomPostAnesthesiaNewVitalSignDisplay: action.payload.isDisplay,
			})
		default:
			return state
	}
}

export default modalReducer
