import { ResourceAssistance } from "~/i18n"

export * from "./type/accounting"
export * from "./type/ADMIN"
export * from "./type/component"
export * from "./type/context-menu"
export * from "./type/hospital"
export * from "./type/INVENTORY"
export * from "./type/menu"
export * from "./type/MODAL"
export * from "./type/PO"
export * from "./type/PR"
export * from "./type/RC"
export * from "./type/report/CASHIER"
export * from "./type/report/HOSPITEL"
export * from "./type/report/INV"
export * from "./type/report/LAB"
export * from "./type/report/PO"
export * from "./type/report/TF"
export * from "./type/TF"
export * from "./type/administration"
export * from "./type/report"

export const ADD_PR_NEW_ITEM = "ADD_PR_NEW_ITEM"
export const APPROVE = "APPROVE"
export const CHANGE_DATE = "CHANGE_DATE"
export const CREATE_PR = "CREATE_PR"
export const LANGUAGE_CHANGE = "CHANGE"
export const LOAD_ITEMS = "LOAD_ITEMS"
export const LOAD_OUT_OF_STOCK_INVENTORY = "LOAD_OUT_OF_STOCK_INVENTORY"
export const LOAD_PRS = "LOAD_PRS"
export const LOAD_PR_TYPES = "LOAD_PR_TYPES"
export const LOGIN = "LOGIN"
export const LOGIN_ERROR = "LOGIN_ERROR"
export const LOGIN_PASSWORD = ResourceAssistance.LoginForm.password
export const LOGIN_USERNAME = ResourceAssistance.LoginForm.username
export const LOGOUT = "LOGOUT"
export const RESET = "RESET"
export const SELECT_INVENTORY = "SELECT_INVENTORY"
export const SELECT_MIN_STOCK = "SELECT_MIN_STOCK"
export const SELECT_NEW_ITEM = "SELECT_NEW_ITEM"
export const SELECT_PR = "SELECT_PR"
export const SELECT_PR_DETAIL = "SELECT_PR_DETAIL"
export const SELECT_PR_ITEM = "SELECT_PR_ITEM"
export const SELECT_TYPE = "SELECT_TYPE"
export const SET_COMMENT = "SET_COMMENT"
export const SET_DISPLAY_ALL = "SET_DISPLAY_ALL"
export const SET_HEADER = "SET_HEADER"
export const SET_ORG_ID = "SET_ORG_ID"
export const SET_ORGS = "SET_ORGS"
export const SET_INVENTORY_PROPERTY = "SET_INVENTORY_PROPERTY"
export const SET_ITEM_LOADING = "SET_ITEM_LOADING"
export const SET_PAGE_CONTINUE_LOADING = "SET_PAGE_CONTINUE_LOADING"
export const SET_PAGE_LOADING = "SET_PAGE_LOADING"
export const SET_PROPERTY = "SET_PROPERTY"
export const SET_PROPERTY_AMOUNT = "SET_PROPERTY_AMOUNT"
export const SET_PROPERTY_DOSE = "SET_PROPERTY_DOSE"
export const SET_PROPERTY_FORM = "SET_PROPERTY_FORM"
export const SET_PROPERTY_LABEL = "SET_PROPERTY_LABEL"
export const SET_PROPERTY_SUPPLIER = "SET_PROPERTY_SUPPLIER"
export const SET_PROPERTY_UNIT = "SET_PROPERTY_UNIT"
export const SET_PROPERTY_UNIT_PRICE = "SET_PROPERTY_UNIT_PRICE"
export const SET_PR_LOADING = "SET_PR_LOADING"
export const SET_REASON = "SET_REASON"
export const SET_SEARCH_NUM = "SET_SEARCH_NUM"
export const SET_SELECTED_INVENTORIES = "SET_SELECTED_INVENTORIES"
export const SET_TYPE_LOADING = "SET_TYPE_LOADING"
