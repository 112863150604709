import { Divider, Select } from "antd"
import _ from "lodash"
import React, { Fragment } from "react"
import { Button, Col, Row } from "react-bootstrap"
import { IoMdDoneAll } from "react-icons/io"
import { MdRemoveDone } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected, setValue } from "~/redux/action"
import { SET_OPD_MODAL_NURSE_FOCUS, SET_OPD_MODAL_NURSE_FOCUS_SELECTED_CATEGORY } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import GInput from "~/view/component/input/GInput"

class OPDModalNurseFocus extends React.Component {
	constructor(props) {
		super(props)

		this.onFocus = this.onFocus.bind(this)
		this.onCategory = this.onCategory.bind(this)
		this.onSelectFocus = this.onSelectFocus.bind(this)
		this.onDeselectFocus = this.onDeselectFocus.bind(this)
		this.onSelectAll = this.onSelectAll.bind(this)
	}

	componentDidMount() {
		if (this.props.opd.selectedNurseNote.nurseNote) {
			this.props.setValue(SET_OPD_MODAL_NURSE_FOCUS, this.props.opd.selectedNurseNote.nurseNote.focus)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.opd.modalNurse.selectedFocusCategory, this.props.opd.modalNurse.selectedFocusCategory)) {
			if (this.props.opd.modalNurse.selectedFocusCategory.category) {
			} else {
			}
		}
	}

	componentWillUnmount() {
		this.reset()
	}

	reset() {
		this.props.setValue(SET_OPD_MODAL_NURSE_FOCUS, "")
		this.props.setSelected(SET_OPD_MODAL_NURSE_FOCUS_SELECTED_CATEGORY, -1, null)
	}

	getValues() {
		let values = []
		if (this.props.opd.modalNurse.selectedFocusCategory.category) {
			this.props.opd.modalNurse.selectedFocusCategory.category.focuses.forEach((focus, idx) => {
				if (_.includes(this.props.opd.modalNurse.focus, focus.description)) {
					values.push(idx)
				}
			})
		}
		return values
	}

	modifyFocus(focus, isAdd) {
		let value = this.props.opd.modalNurse.focus
		if (isAdd) {
			value = value.concat(_.isEmpty(value) ? "" : "\n", focus)
		} else {
			focus.split("\n").forEach((each) => {
				value = value.replace(each, "")
			})
		}
		value = Utils.replaceDuplicateEmptyLine(value)
		this.props.setValue(SET_OPD_MODAL_NURSE_FOCUS, Utils.trim(value))
	}

	onFocus(event) {
		this.props.setValue(SET_OPD_MODAL_NURSE_FOCUS, event.target.value)
	}

	onCategory(value) {
		this.props.setSelected(
			SET_OPD_MODAL_NURSE_FOCUS_SELECTED_CATEGORY,
			value > -1 ? Number(value) : -1,
			value > -1 ? this.props.opd.modalNurse.categories[value] : null
		)
	}

	onSelectFocus(value) {
		this.modifyFocus(this.props.opd.modalNurse.selectedFocusCategory.category.focuses[value].description, true)
	}

	onDeselectFocus(value) {
		this.modifyFocus(this.props.opd.modalNurse.selectedFocusCategory.category.focuses[value].description, false)
	}

	onSelectAll(event) {
		let focuses = ""
		this.props.opd.modalNurse.selectedFocusCategory.category.focuses.forEach((each) => {
			if (!_.includes(this.props.opd.modalNurse.focus, each.description)) {
				focuses = focuses.concat(each.description, "\n")
			}
		})
		this.modifyFocus(
			!_.isEmpty(focuses)
				? focuses
				: this.props.opd.modalNurse.selectedFocusCategory.category.focuses.reduce((total, cur) => {
						return (total = total.concat(cur.description, "\n"))
				  }, ""),
			!_.isEmpty(focuses)
		)
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Message.focus)}</legend>
				<Row>
					<Col>
						<Select
							showSearch
							value={this.props.opd.modalNurse.selectedFocusCategory.category ? this.props.opd.modalNurse.selectedFocusCategory.index : undefined}
							size={ResourceAssistance.Select.size.small}
							style={{ flexGrow: 1, textAlign: "center" }}
							optionFilterProp="children"
							filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
							onChange={this.onCategory}
						>
							{Utils.renderSelects(this.props.opd.modalNurse.categories, false)}
						</Select>
					</Col>
				</Row>
				<Row>
					<Col>
						<Select
							mode={ResourceAssistance.Select.mode.multiple}
							size={ResourceAssistance.Select.size.small}
							style={{ flexGrow: 1, textAlign: "center" }}
							disabled={!this.props.opd.modalNurse.selectedFocusCategory.category}
							value={this.getValues()}
							maxTagCount="responsive"
							optionFilterProp="children"
							filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
							onSelect={this.onSelectFocus}
							onDeselect={this.onDeselectFocus}
							dropdownRender={(menu) => (
								<Fragment>
									{menu}
									{this.props.opd.modalNurse.selectedFocusCategory.category && !_.isEmpty(this.props.opd.modalNurse.selectedFocusCategory.category.focuses) && (
										<Fragment>
											<Divider style={{ margin: "4px 0" }} />
											<Row>
												<Col />
												<Col md="auto">
													<Button
														variant={ResourceAssistance.Button.variant.primary}
														disabled={
															!this.props.opd.modalNurse.selectedFocusCategory.category ||
															_.isEmpty(this.props.opd.modalNurse.selectedFocusCategory.category.focuses)
														}
														onMouseDown={(e) => e.preventDefault()}
														onClick={this.onSelectAll}
													>
														{this.props.opd.modalNurse.selectedFocusCategory.category &&
														this.props.opd.modalNurse.selectedFocusCategory.category.focuses.every((focus) =>
															this.props.opd.modalNurse.focus.includes(focus.description)
														) ? (
															<Fragment>
																<MdRemoveDone size={ResourceAssistance.ReactIcon.size} />
																{translate(ResourceAssistance.Message.deselectAll)}
															</Fragment>
														) : (
															<Fragment>
																<IoMdDoneAll size={ResourceAssistance.ReactIcon.size} />
																{translate(ResourceAssistance.Message.selectAll)}
															</Fragment>
														)}
													</Button>
												</Col>
												<Col />
											</Row>
										</Fragment>
									)}
								</Fragment>
							)}
						>
							{Utils.renderSelects(
								this.props.opd.modalNurse.selectedFocusCategory.category ? this.props.opd.modalNurse.selectedFocusCategory.category.focuses : [],
								false,
								-1,
								"description"
							)}
						</Select>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput autoSize minRows={18} value={this.props.opd.modalNurse.focus} onChange={this.onFocus} />
					</Col>
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OPDModalNurseFocus))
