import React from "react"
import Chart from "chart.js/auto"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Utils } from "~/utils/Utils"
import { ResourceAssistance } from "~/i18n"
import _ from "lodash"

class IPDRightVitalSignGraph extends React.Component {
	vitalSignChart = null

	constructor(props) {
		super(props)
		this.state = {
			componentName: "IPDRightVitalSignGraph",
		}

		this.chartRef = React.createRef()
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.hospitel.selectedHospitel, this.props.hospitel.selectedHospitel)) {
			if (this.props.hospitel.selectedHospitel.hospitel) {
				this.buildChart()
			}
		}
	}

	componentDidMount() {
		this.buildChart()
	}

	buildChart() {
		const ctx = this.chartRef.current.getContext("2d")
		this.chartRef.current.style.maxHeight = "134px"
		this.chartRef.current.style.maxWidth = this.chartRef.current.clientWidth + "px"

		if (this.vitalSignChart) {
			this.vitalSignChart.destroy()
		}

		let vitalSigns = this.props.hospitel.selectedHospitel.hospitel.admission.clinicalRecord.vitalSigns.sort((a, b) => Utils.sort(a.dateTime, b.dateTime))

		this.vitalSignChart = new Chart(ctx, {
			type: "line",
			data: {
				labels: vitalSigns.map((vitalSign) => {
					return Utils.formatDateTime(Utils.generateDateFromLong(vitalSign.dateTime))
				}),
				datasets: [
					{
						data: vitalSigns.map((vitalSign) => {
							return vitalSign.pr
						}),
						label: "Pulse",
						yAxisID: "pulse",
						borderColor: ResourceAssistance.CSS.Color.red,
						backgroundColor: "white",
						fill: false,
					},
					{
						data: vitalSigns.map((vitalSign) => {
							return vitalSign.temperature
						}),
						label: ResourceAssistance.Symbol.degreeCelsius,
						yAxisID: "temperature",
						borderColor: ResourceAssistance.CSS.Color.blue,
						backgroundColor: "white",
						fill: false,
					},
				],
			},
			options: {
				stacked: false,
				responsive: true,
				maintainAspectRatio: false,
				interaction: {
					mode: "index",
					intersect: false,
				},
				scales: {
					pulse: {
						type: "linear",
						display: true,
						position: "left",

						suggestedMin: Math.min(...vitalSigns.map((vs) => vs.pr)),
						suggestedMax: Math.max(...vitalSigns.map((vs) => vs.pr)),
						ticks: {
							precision: 1,
							stepSize: 1,
							count: 3,
							// fontColor: "#29b6f6",
							// callback: function (value, index, values) {
							// return value + " kg"
							// },
						},
						title: {
							display: true,
							align: "end",
							text: this.props.intl.formatMessage({ id: ResourceAssistance.Message.pulse }),
							padding: { top: 0, left: 0, right: 0, bottom: 0 },
						},
					},
					temperature: {
						type: "linear",
						display: true,
						position: "left",
						// grid line settings
						grid: {
							drawOnChartArea: false, // only want the grid lines for one axis to show up
						},
						// min: 35,
						// max: 39,
						suggestedMin: Math.min(...vitalSigns.map((vs) => vs.temperature)),
						suggestedMax: Math.max(...vitalSigns.map((vs) => vs.temperature)),
						ticks: {
							precision: 1,
							stepSize: 0.1,
							count: 3,
							// fontColor: "#29b6f6",
							// callback: function (value, index, values) {
							// return value + " kg"
							// },
						},
						scaleLabel: {
							display: false,
						},
						title: {
							display: true,
							align: "end",
							text: ResourceAssistance.Symbol.degreeCelsius,
							padding: { top: 0, left: 0, right: 0, bottom: 0 },
						},
					},
				},
			},
		})
	}

	render() {
		return (
			<Row className={"full-size"}>
				<Col>
					{/* <div style={{ flexGrow: 1 }}> */}
					<canvas height={142} id={this.state.componentName} ref={this.chartRef} />
					{/* </div> */}
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(IPDRightVitalSignGraph))
