import { LOCALES } from "../locale"
import IntraAnesthesiaRecord from "./en/IntraAnesthesiaRecord"
import PostAnesthesiaRecord from "./en/PostAnesthesiaRecord"
import PreOperativeCheckList from "./en/PreOperativeCheckList"
import SurgicalSafetyCheckList from "./en/SurgicalSafetyCheckList"
import Warning from "./en/Warning"

const en = {
	[LOCALES.ENGLISH]: {
		...PreOperativeCheckList,
		...SurgicalSafetyCheckList,
		...IntraAnesthesiaRecord,
		...PostAnesthesiaRecord,
		...Warning,

		//Remark
		remarkPrimaryHealthCarePlan: "The Health Care Plan receiving payment directly from the patient by Cash, Credit Card, etc.",
		remarkReactionToLight: "***Brisk +, No Reaction -, Sluggish s, Eye Closed c",
		remarkConfigureAtCategoryBank: "Configure at Category Bank",
		remarkTheReceiptWillBeValidOnceThePaymentHasBeenReceived: "The receipt will be valid once the payment has been received.",
		
		
		
		//Report
		reportWithholdingTaxWithholder: "Person Responsible for Withholding Tax",
		reportWithholdingTaxPayer: "Person Subject to Withholding Tax",
		reportFormNumClarify: "To be able to reference or verify each other between the sequences that follow. Certificate etc. with the tax deduction form  submitted",
		reportTypeOfIncomePaid: "Type of Income Paid",
		reportSalaryWagesEtcSection401: "Salary, wages, allowances, bonuses, etc. according to Section 40(1)", 
		reportFeeCommissionsSection402: "Fees, commissions, etc. according to Section 40(2)",
		reportCopyrightSection403: "Copyright fees, etc. according to Section 40(3)",
		reportInterestSection404a: "Interest expenses, etc. according to Section 40(4)(a)",
		reportDividendsSection404b: "Dividends, share of profits, etc. according to Section 40(4)(b)",
		reportDividendEntitledToTaxCreditByRate: "In the case where the receipent of the dividend is entitled to a tax credit because the dividends is paid from net profit of business which has paid income tax in these following tax rate",
		report30PercentOfNetProfit: "30% of net profit",
		report25PercentOfNetProfit: "25% of net profit",
		report20PercentOfNetProfit: "20% of net profit",
		reportOtherPercentOfNetProfit: "Other percent of net profit",
		reportDividendEntitledToTaxCreditBy: "In the case where the receipent of the dividend is not entitled to a tax credit because the dividends is paid from",
		reportNetProfitOfBusinessExemptFromCorporateIncomeTax: "Net profits of businesses exempt from corporate income tax",
		reportDividendOrProfitSharesAreExcept: "Dividends or shares of profits that are exempt from being included in the calculation of corporate income tax",
		reportNetProfitDeductedFromNetLosses: "Net profits deducted from net losses carried forward not more than 5 years before the current accounting period",
		reportProfitRecognizedByTheEquityMethod: "Profit recognized by the equity method",
		reportPaymentOfIncomeSubjectToWithholdingTaxAccordingTo: "Payment of income subject to withholding tax according to the Revenue Department's Instruction issued under Section 3 Tredecim, such as prizes, any eductions or benefits due to sales promotions, prices received from contests, reductions or benefits due to sales promotions, prices received from contests, competitions or lucky draws, public entertainers' income, income derived from  performance of work, advertisement fees, rent transportation fees, services fees, insurance premiums against loss, etc.",
		reportGovernmentPensionFundOrGovernmentPermanentExployee:"Government Pension Fund/Government Permanent Employee Fund/Private Teachers Aid Fund",
		reportWithholdAtSource: "Withhold at source",
		reportPayEveryTime:"Pay every time",
		reportPayOneTime: "Pay one time",
		reportIHereByCertify: "I hereby certify that the above statements and amounts are correct and consistent with the truth in all respects",
		reportDayMonthYearThatTheCertificateWasIssued: "Day/Month/Year that the certificate was issued", 
		reportStampedByLegalEntity: "Stamped by Legal Entity",
		reportThePersonResponsibleForIssuingWithholdingTaxCertificate: "The person responsible for issuing withholding tax certificates who violate and fail to comply with Section 50 Bis of the Revenue Code are subject to criminal penalties according to Section 35 of the Revenue Code",
		reportTotalAmountIncomeAndWithholdingTaxIntl: "Total amount of income and total amount of withholding tax",
		reportTotalWithholdingTaxInLetters:"Total Withholding Tax (in letters)",
		
		// Message
		"": "",
		ICD: "ICD",
		abdomen: "Abdomen",
		abnormalFlexion: "Abnormal Flexion",
		accidentHistory: "Accident History",
		accounting: "Accounting",
		accountsCode: "Accounts Code",
		accountsPayable: "Accounts Payable",
		accountsReceivable: "Accounts Receivable",
		active: "Active",
		adHocOrder: "Ad Hoc Order",
		add: "Add",
		address: "Address",
		addressEng: "Address (Eng)",
		adjustments: "Adjustments",
		admin: "Administration",
		admission: "Admission",
		admissionDate: "Admissiont Date",
		admissionPlan: "Admission Plan",
		adpType: "ADP Type",
		afterMeal: "After Meal",
		age: "Age",
		aie: "AIE",
		alcohol: "Alcohol",
		allergies: "Allergies",
		amount: "Amount",
		amountML: "Amount (mL)",
		amountOfPackage: "Amount of Package",
		amountPerOrder: "Amount/Order",
		analyte: "Analyte",
		antigen: "Antigen",
		antigenCOI: "Antigen (COI)",
		antigenComment: "Antigen Comment",
		appointment: "Appointment",
		appointmentDate: "Appointment Date",
		appointmentTime: "Appointment Time",
		approve: "Approve",
		approved: "Approved",
		approvedBy: "Approved By",
		approvedInventories: "Approved Inventories",
		approver: "Approver",
		approverCheck: "A",
		arrival: "Arrival",
		assessment: "Assessment",
		assets: "Assets",
		attachment: "Attachment",
		authenNumber: "Authentication Number",
		authentication: "Authentication",
		authorization: "Authorization",
		authorize: "Authorize",
		back: "Back",
		balance: "Balance",
		bank: "Bank",
		bankAccNum: "Bank Acc Num",
		bankBranch: "Bank Branch",
		bankCode: "Bank Code",
		bankName: "Bank Name",
		batchRecordFormat: "Batch Record Format",
		beforeBed: "Before Bed",
		beforeMeal: "Before Meal",
		bill: "Bill",
		billByCategory: "Bill (Category)",
		billByHealthCarePlan: "Bill (Health Care Plan)",
		billDetails: "Bill Details",
		billSummary: "Bill Summary",
		billTo: "Bill To",
		billingAddress: "Billing Address",
		billingContact: "Billing Contact",
		billingToTodayByAdmission: "Billing To Today By Admission",
		bloodTest: "Blood Test",
		bonus: "Bonus",
		bpmmHg: "BP. (mmHg)",
		branch: "Branch",
		branchCode: "Branch Code",
		brandName: "Brand",
		brandNameAndCode: "Brand/Code",
		budget: "Budget",
		by: "By",
		byAdmission: "By Admission",
		byUser: "By User",
		calculation: "Calculation",
		cancel: "Cancel",
		cancelDoctorPrescriptionOnly: "Cancel Doctor's Prescription Only",
		cancelNotReceived: "Cancel Not Received",
		cancelled: "Cancelled",
		cannotDownloadLogo: "Cannot download logo",
		cannotReadSMC: "Cannot read the ID!",
		carAccident: "Car Accident",
		carAccidentReference: "Car Accident Ref.",
		career: 'Career',
		cashier: "Cashier",
		category: "Category",
		categoryNum: "Category #",
		certified: "Certified",
		certifiedBy: "Certified By",
		certifiesThat: "Certifies that",
		change: "Change",
		changeEndDate: "Modify End Date",
		changePassword: "Change Password",
		chartOfAccounts: "Chart of Accounts",
		checkIn: "Check In",
		checkInDate: "Check-In Date",
		checkInTime: "Check-In Time",
		checkNum: "Check Num",
		checkOut: "Check Out",
		checkOutDate: "Check-Out Date",
		checkOutTime: "Check-Out Time",
		checkbox: "C\u00A0",
		checkout: "Check Out",
		cheifFinancialOfficer: "Cheif Financial Officer",
		chrgItem: "CHRGITEM (E-claim)",
		chiefComplaint: "Chief Complaint (CC.)",
		chromatography: "Chromatography",
		city: "City",
		cityEng: "City (Eng)",
		claimFile: "Claim File",
		clear: "Clear",
		clinicalRecord: "Clinical Record",
		close: "Close",
		code: "Code",
		companyOrResponsiblePersonContact: "Company/Responsible Person Contact",
		complete: "Complete",
		confirm: "Confirm",
		confirmPassword: "Confirm Password",
		confused: "Confused",
		contact: "Contact",
		contactInfo: "Contact Info",
		contactPerson: "Contact Person",
		continue: "Continue",
		contractBilling: "Contract Billing",
		contractBillingId: "Contract Billing ID",
		contraindicationsAndWarnings: "Contraindications/Warnings",
		coordinator: "Coordinator",
		costPerOrder: "Cost/Order",
		covid19Lab: "Covid-19 Lab",
		covid19Negative: "Covid-19 Negative",
		covid19Positive: "Covid-19 Positive",
		createNewPR: "Add Items for PR",
		creator: "Creator",
		credit: "Credit",
		creditCard: "Credit Card",
		currencySign: "\u0024",
		current: "Current",
		currentBalance: "Current Balance",
		currentLevel: "Current Level",
		date: "Date",
		dateOfBirth: "DOB",
		dateOfPo: "Date of PO",
		day: "Day",
		days: "Days",
		daysOfRest: "Days of rest.",
		debit: "Debit",
		debt: "Debt Payment",
		default: "Default",
		delete: "Delete",
		deleteSkippedPharmacyOrder: "Delete Skipped Pharmacy Order",
		deleteVerifiedPharmacyOrder: "Delete Verified Pharmacy Order",
		department: "Department",
		deposit: "Deposit",
		depressionAssessment: "Depression Assessment",
		description: "Description",
		deselectAll: "Deselect All",
		detailRecordFormat: "Detail Record Format",
		diagnosis: "Diagnosis",
		discharge: "Discharge",
		dischargeDate: "Discharge Date",
		discharged: "Discharged",
		discount: "Discount",
		discountBill: "Discount (Bill)",
		discountItem: "Discount (Item)",
		discountPricePerOrder: "D-Price/Order",
		discountPricePerUnit: "D-Price/Unit",
		discountService: "Discount (Service)",
		discountTaxPricePerUnit: "DT-Price/Unit",
		discountWithSymbol: "Discount %",
		dispense: "Dispense",
		dispensed: "Dispensed",
		dispensedBy: "Dispensed By",
		dispensingTime: "Dispensing Time",
		displayAll: "Display All",
		displayExpire: "Display Expire",
		displayInactive: "Display Inactive",
		displayMinStock: "Display Min Stock",
		doctor: "Doctor",
		doctorConsultation: "Doctor Consultation",
		doctorName: "Doctor Name",
		doctorNote: "Doctor Note",
		doctorOpinion: "Doctor's Opinion",
		doctorOptionAfter: "After receiving complete treatment for 14 days, you can go to work and live normally.",
		doctorPrefix: "Dr.",
		doctorPrescription: "Doctor Prescription",
		doctorPrescriptionOnly: "Doctor's Prescription Only",
		doctorStandingOrder: "Doctor Order",
		documentNum: "Document Num",
		done: "Done",
		dose: "Dose",
		doubleClickToDelete: "Double click to delete the item.",
		download: "Download",
		drinking: "Drinking",
		driver: "Driver",
		dueDate: "Due Date",
		dupplicateCodeWarning: "Code exist already!",
		dupplicateNameWarning: "Name exist already!",
		dupplicateWarning: "Exist Already!",
		duration: "Duration",
		eclaim: "E-Claim",
		eclaimFiles: "E-Claim Files",
		eclaimUc: "E-Claim (UC)",
		eclaimVariable: "E-Claim Variable",
		eGene: "E gene:CT",
		edit: "Edit",
		email: "Email",
		empty: "",
		emptyWarning: "Cannot be empty!",
		endDate: "End Date",
		endDateTime: "End Date Time",
		equity: "Equity",
		estimatedDateOfConfinement: "Estimate Date of Confinement (EDC)",
		evaluation: "Evaluation",
		every: "Every",
		examinationDate: "Examination Date",
		expected: "Expected",
		expenseEstimation: "Expense Estimation",
		expenses: "Expenses",
		expire: "Expire",
		expired: "Expired",
		expiredDate: "Expired Date",
		export: "Export",
		exportTypes: "Export Types",
		extension: "Extension",
		extremities: "Extremities",
		eyeOpeningResponse: "Eye Opening Response",
		factoryArea: "Factory Area",
		fallRisk: "Fall Risk",
		familyHealthHistory: "Family Health History",
		fax: "Fax",
		faxNo: "Fax No.",
		fee: "Fee",
		female: "Female",
		fileName: "File Name",
		files: "Files",
		fillWithCharacter: "Fill The Space With Character",
		filter: "Filter",
		finance: "Finance",
		financialOfficer: "Financial Officer",
		financialOperations: "Financial Operations",
		financialPlan: "Financial Plan",
		financialTransaction: "Financial Transaction",
		firstAssessment: "First Assessment",
		firstName: "First Name",
		firstNameEng: "First Name (Eng)",
		fluorescence: "Fluorescence",
		focus: "Focus",
		form: "Form",
		frequency: "Frequency",
		friday: "Fri",
		from: "From",
		fsCode: "F/S Code",
		gender: "Gender",
		general: "General",
		generalAppearance: "General Appearance (GA)",
		generalInfo: "General Information",
		generalLedger: "General Ledger",
		generic: "Generic",
		genericName: "Generic Name",
		genericNameOrTradeName: "Generic Name/Trade Name",
		glasgowComaScale: "Glasgow Coma Scale (GCS)",
		googleDrive: "G-Drive",
		governmentCode: 'Government Code',
		grandTotal: "Grand Total",
		graph: "Graph",
		hasExaminedOn: "Has examined on",
		hashSymbol: "\u00A0#",
		health: "Health",
		healthCare: "Health Care",
		healthCarePlan: "Health Care Plan",
		healthHistory: "Health History",
		heart: "Heart",
		heent: "HEENT",
		height: "Height",
		hn: "HN",
		home: "Home",
		hospital: "Hospital",
		hospitalBilling: "Hospital Billing",
		hospitalCode: "Hospital Code",
		hospitalInformation: 'Hospital Information',
		hospitel: "Hospitel",
		hours: "Hours",
		iPay: "iPay",
		iPayFormat: "iPay Format",
		id: "ID",
		idCard: "ID Card",
		identification: "Identification",
		idType: "ID Type",
		inPatient: "Inpatient",
		inProgress: "In Progress",
		inactive: "Inactive",
		incomplete: "Incomplete",
		inpatientPlan: "Inpatient Plan",
		inspector: "Inspector",
		inspectorCheck: "I",
		instruction: "Instruction",
		insuranceCompany: "Insurance Company",
		insuranceReference: "Insurance Ref.",
		intake: "Intake",
		intakeOutput: "Intake/Output",
		internationalClassificationDiseases: "International Classification of Diseases (ICD)",
		intervention: "Intervention",
		inventory: "Inventory",
		inventoryByLocation: "Inventory By Location",
		investigation: "Investigation",
		invoice: "Invoice",
		invoiceId: "Invoice ID",
		invoiceInfo: "Invoice Info.",
		invoiceNum: "Invoice #",
		ioBalance: "I/O Balance",
		ipd: "IPD",
		isolationPrecaution: "Isolation Precaution",
		issued: "Issued",
		issuedBy: "Issued By",
		item: "Item",
		itemSupplier: "Item Supplier",
		itemType: "Item Type",
		journalVoucher: "Journal Voucher (JV)",
		keyword: "Keyword",
		lab: "Laboratory",
		labTest: "Lab Test",
		labNumber: "Lab Num",
		label: "Label",
		language: "Language",
		last: "Last",
		lastFor: "Last For",
		lastFourDigitOfCreditCard: "Last 4 Digit of Credit Card",
		lastLocation: "Last Location",
		lastMenstrualPeriod: "Last Menstrual Period (LMP)",
		lastModifiedDate: "Last Modified Date",
		lastName: "Last Name",
		lastNameEng: "Last Name (Eng)",
		lastPurchasedFrom: "Last Purchased From",
		lastReceivedDate: "Last Received Date",
		left: "Left (L)",
		leftEye: "Left Eye (L)",
		leftEyePupilSizeMM: "Pupil Size (mm) (L)",
		leftEyeReactionToLight: "Reaction to Light (L)",
		letterOfConsent: "Letter of Consent",
		level: "Level",
		liabilities: "Liabilities",
		licenseNo: "License Num",
		licenseNum: "License Num",
		linkGL: "Link GL",
		loadPreviousMedicalRecords: "Load Previous Medical Records",
		loading: "Loading...",
		localising: "Localising",
		location: "Location",
		login: "Login",
		loginError: "Username or password is wrong!",
		loginName: "Login Name",
		logo: 'Logo',
		logoDragDropTitle: 'Click or drag logo to this area',
		logoDragDropDescription: 'Logo must be PNG or JPEG file and size not over 200KB.',
		logout: "Logout",
		lot: "Lot",
		lotNum: "Lot #",
		lung: "Lung",
		male: "Male",
		management: "Management",
		marriage: "Marriage",
		max: "Max",
		maxCharacters: "Max Characters",
		maxLevel: "Max Level",
		md: "MD.",
		medicalCertificate: "Medical Certificate",
		medicalCertificateEng: "Medical Certificate (Eng)",
		medicalCertificateIPD: "Medical Certificate (IPD)",
		medicalCertificateOPD: "Medical Certificate (OPD)",
		medicalRecord: "Medical Record",
		medicalSupply: "Medical Supply",
		medicationSheet: "Medication Sheet",
		medicationUsage: "Medication Usage",
		medicine: "Medicine",
		medicineHistory: "Medicine History",
		memoId: "Memo ID",
		menopause: "Menopause",
		menstruation: "Menstruation",
		message: "Message",
		method: "Method",
		min: "Min",
		minLevel: "Min Level",
		minQtyPerOrder: "Qty/Order",
		minStock: "Min Stock",
		minutes: "Minutes",
		modified: "Modified",
		modifyDateRange: "Modify Date Range",
		monday: "Mon",
		moneyCheck: "Check",
		month: "Month",
		motorResponse: "Motor Response",
		multiTime: "Multiple Time",
		n2geneCT: "N2 gene:CT",
		nGene: "N gene:CT",
		nation: 'Nation',
		name: "Name",
		nameEng: "Name (Eng)",
		nameSurname: "Name-Surname",
		nameTitle: "Ms./Mrs./Mr.",
		negative: "Negative",
		neuro: "Neuro",
		new: "New",
		newForm: "New Form",
		newItem: "New Item",
		newItemSupplier: "New Item Supplier",
		newLab: "New Lab",
		newReceive: "New Receive",
		newRecord: "New Record",
		newRequest: "New Request",
		newRole: "New Role",
		newSupplier: "New Supplier",
		newTax: "New Tax",
		newTransfer: "New Transfer",
		newUnit: "New Unit",
		newUser: "New User",
		nickName: "Nickname",
		no: "No",
		noEyeOpening: "None",
		noMotor: "None",
		noVerbal: "None",
		normalFlexion: "Normal Flexion",
		note: "Note",
		notification: "Notification",
		novelDiseaseInvestigationSheet: "Novel 3 Disease Investigation Sheet",
		number: "Num",
		nurse: "Nurse",
		nurseName: "Nurse Name",
		nurseOrder: "Nurse Order",
		nurseProgressNote: "Nurse Progress Note",
		o2Sat: "O\u2082 Sat (%)",
		oGene: "ORF1ab gene:CT",
		obeysCommands: "Obeys Commands",
		objective: "Objective",
		oneMonth: "One Month",
		oneWeek: "One Week",
		opd: "OPD",
		opdClinicalRecord: "OPD Clinical Record",
		operationalService: "Operational Service",
		operationType: 'Operation Type',
		or: "Or",
		order: "Order",
		org: "Organization",
		orientated: "Orientated",
		other: "Other",
		output: "Output",
		package: "Package",
		paid: "Paid",
		painScale: "Pain Scale",
		parentLoc: "Parent Location",
		passenger: "Passenger",
		passiveSmokeExposure: "Passive Smoke Exposure",
		password: "Password",
		passwordDoesNotMatch: "Password does not match!",
		pastIllnesses: "Past Illnesses",
		pastSurgicalHistory: "Past Surgical History",
		patient: "Patient",
		patientId: "PID",
		patientInfo: "Patient Info",
		patientName: "Patient Name",
		patientSaving: "Patient Saving",
		pay: "Pay",
		payee: "Payee",
		payment: "Payment",
		paymentPlan: "Payment Plan",
		paymentSlip: "Payment Slip",
		paymentTerms: "Payment Terms",
		paymentType: "Payment Type",
		pcr: "PCR",
		pcrComment: "PCR Comment",
		pedestrian: "Pedestrian",
		pending: "Pending",
		pendingItems: "Pending Items",
		pendingOrder: "Pending Order",
		pendingTransfers: "Pending Transfers",
		percent: "Percent",
		percentSymbol: "%",
		permission: "Permission",
		pharmacist: "Pharmacist",
		pharmacistVerified: "Pharmacist Verified",
		pharmacy: "Pharmacy",
		pharmacyDispensed: "Pharmacy Dispensed",
		pharmacyInventory: "Pharmacy Inventory",
		phone: "Phone",
		physicalExamination: "Physical Examination",
		placeOfIncident: "Place of Incident",
		plan: "Plan",
		poDate: "PO Date",
		poId: "PO ID",
		poNumber: "PO #",
		policeName: "Police Name",
		policeStation: "Police Station",
		positive: "Positive",
		postcode: "Postcode",
		prNumber: "PR #",
		prPerMin: "PR./min",
		pregnancy: "Pregnancy",
		presentIllness: "Present Illness (PI.)",
		pricePerOrder: "Price/Order",
		pricePerOrderBeforeTax: "Price/Order",
		pricePerUnit: "Price/Unit",
		pricePerUnitBeforeTax: "Price/Unit",
		primaryHospital: "Primary Hospital",
		primaryHospitalCode:"Primary Hospital Code",
		primaryHospitalName:"Primary Hospital Name",
		primaryPlan: "Primary Plan",
		print: "Print",
		printDate: "Print Date",
		printLabel: "Print Label",
		prnRecord: "PRN Record",
		professionalServiceExpenseByUser: "Professional Service Expense By User",
		province: "Province",
		provinceEng: "Province (Eng)",
		psId: "PS ID",
		pulse: "PR",
		pupilSize: "Pupil Size",
		purchase: "Purchase",
		purchaseFrom: "Purchase From",
		purchaseInvoice: "Purchase Invoice",
		purchaseInvoiceId: "Purchase Invoice ID",
		purchaseOrder: "Purchase Order",
		purchaseOrderById: "Purchase Order By ID",
		purchaseOrderByLocation: "Purchase Order By Location",
		purchaseRequest: "Purchase Request",
		qty: "Qty",
		qtyPerDay: "Qty/Day",
		questionnaire: "Questionnaire",
		radiology: "Radiology",
		rc: "RC",
		reactionToLight: "Reaction to Light",
		reason: "Reason",
		receipt: "Receipt",
		receiptCategory: "Receipt Category",
		receiptId: "Receipt ID",
		receive: "Receive",
		receiveId: "Receive ID",
		receiveItemDetails: "Receive Item Details",
		received: "Received",
		receivedBy: "Received By",
		references: "References",
		refresh: "Refresh",
		refund: "Refund",
		registerOPD: "Register OPD",
		registerPatient: "Register Patient",
		registration: "Registration",
		registrationDate: "Registration Date",
		reject: "Reject",
		rejectReason: "Reason for Reject",
		relationship: "Relationship",
		relativeInfo: "Relative Info",
		remark: "Remark",
		report: "Report",
		reportById: "Report By ID",
		reportByLocation: "Report By Location",
		request: "Request",
		requestCovid19MedicalCertificate: "Request Covid-19 Medical Certificate",
		requestFrom: "Request From",
		requestId: "Request ID",
		requestedInventories: "Requested Inventories",
		requester: "Requester",
		requesterCheck: "R",
		requiredByDate: "Required By Date",
		reset: "Reset",
		result: "Result",
		return: "Return",
		returned: "Returned",
		returning: "Returning",
		revenues: "Revenues",
		right: "Right (R)",
		rightEye: "Right Eye (R)",
		rightEyePupilSizeMM: "Pupil Size (mm) (R)",
		rightEyeReactionToLight: "Reaction to Light (R)",
		riskOfAbuse: "Risk of Abuse",
		role: "Role",
		room: "Room",
		rrPerMin: "RR./min",
		run: "Run",
		sGene: "S gene:CT",
		sarsCov2RnaPcr: "SARS-CoV-2-RNA-PCR",
		satCode: "SAT Code",
		saturday: "Sat",
		save: "Save",
		saveAll: "Save All",
		saveFail: "Save data failed, please try again.",
		saveSuccess: "Data have been saved successfully",
		scheduled: "Scheduled",
		scheduledDate:"Scheduled Date",
		score: "Score",
		search: "Search",
		searchIncomplete: "Search Incomplete",
		searchPatient: "Search Patient",
		selectAll: "Select All",
		sell: "Sell",
		sellingPricePerUnit: "Selling Price/Unit",
		seq: "SEQ",
		sequence: "Sequence",
		service: "Service",
		serviceType: "Service Type",
		setAmount: "Set amount...",
		shipAddress: "Shipping Address",
		shippingContact: "Shipping Contact",
		signature: "Signature",
		singleTime: "Single Time",
		size: "Size",
		skipPayment: "Skip Payment",
		skipPercent: "Skip Percent",
		skipVerify: "Skip Verify",
		skipped: "Skipped",
		smoking: "Smoking",
		soap: "SOAP",
		somethingWrong: "Something wrong!",
		sounds: "Sounds",
		spontaneous: "Spontaneous",
		startDate: "Start Date",
		startDateTime: "Start Date Time",
		status: "Status",
		stock: "Stock",
		stockAndPurchasing: "Stock & Purchasing",
		stockManager: "Stock Manager",
		stools: "Stools",
		stop: "Stop",
		stretcher: "Stretcher",
		subLevel: "Sub-Level",
		subjective: "Subjective",
		subunit: "Subunit",
		sunday: "Sun",
		supplementOrHerbsHistory: "Supplement/Herbs History",
		supplier: "Supplier",
		supplierCode: "Vendor ID",
		supplierDiscount: "V-Discount",
		supplierName: "Supplier Name",
		symptoms: "Symptoms",
		table: "Table",
		taxId: "Tax ID",
		taxRate: "Tax",
		taxRateWithSymbol: "Tax %",
		tel: "Tel",
		telNo: "Tel No.",
		temperature: "T. (C)",
		terms: "Terms",
		thai: "TH",
		theAboveNamedPersonRequires: "The above named person requires",
		thisMonth: "This Month",
		thursday: "Thurs",
		time: "Time",
		timeOfIncident: "Time of Incident",
		timeline: "Timeline",
		title: "Title",
		tmltTmtCode: "TMLT/TMT Code",
		to: "To",
		toPressure: "To Pressure",
		toSound: "To Sound",
		tobacco: "Tobacco",
		today: "Today",
		tomorrow: "Tomorrow",
		total: "Total",
		totalAdjustments: "Total Adjustments",
		totalBalance: "Total Balance",
		totalBudget: "Total Budget",
		totalDeposit: "Total Deposit",
		totalEstimationCost: "Total Estimation Cost",
		totalIntake: "Total Intake",
		totalOutput: "Total Output",
		totalPaid: "Total Paid",
		totalPrice: "Total Price",
		totalPriceDiscounted: "D-Total",
		totalPriceDiscountedTaxed: "DT-Total",
		totalPriceTaxed: "T-Total",
		totalUnpaid: "Total Unpaid",
		totalWithdraw: "Total Withdraw",
		tradeName: "Trade Name",
		tradeNameOrBrand: "Trade Name/Brand",
		transaction: "Transaction",
		transactionDescription: "Transaction Description",
		transactionFee: "Transaction Fee",
		transfer: "Transfer",
		transferBankBranch: "Transfer Bank Branch",
		transferBankName: "Transfer Bank Name",
		transferByLocation: "Transfer By Location",
		transferFrom: "Transfer From",
		transferId: "Transfer ID",
		transferingItems: "Transfering Items",
		transferred: "Transferred",
		translator: "Translator",
		treatment: "Treatment",
		triage: "Triage (ESI Index)",
		tuesday: "Tues",
		twoWeeks: "Two Weeks",
		type: "Type",
		typeOfIncident: "Type of Incident",
		typesOfDiagnosis: "Types of Diagnosis",
		ucae: "UC Accident/Emergency",
		unapproveReason: "Unapproved Reason:\u00A0",
		unapproved: "Disapprove",
		underlyingDisease: "Underlying Disease",
		unit: "Unit",
		unitConversion: "Unit Conversion",
		unitPrice: "Unit Price",
		unlinkGL: "Unlink GL",
		unpaid: "Unpaid",
		uom: "UOM",
		upload: "Upload",
		uploaded: "Uploaded",
		urine: "Urine",
		url: 'URL',
		user: "User",
		username: "Username",
		usingContraceptive: "Using Contraceptive",
		uuc: "Rights Usage",
		vaccine: "Vaccine",
		value: "Value",
		variable: "Variable",
		variablesFormat: "Variables Format",
		vat: "VAT",
		verbalResponse: "Verbal Response",
		verified: "Verified",
		verify: "Verify",
		verifyAllPrescription: "Verify All Prescription",
		verifyTodayPrescription: "Verify Today's Prescription",
		victim: "Victim",
		view: "View",
		visitId: "VID",
		vitalSign: "Vital Sign",
		volume: "Volume",
		walk: "Walk",
		waste: "Waste",
		wednesday: "Wed",
		weight: "Weight",
		wheelchair: "Wheelchair",
		withdraw: "Withdraw",
		withholdingTax: "Withholding Tax (WHT)",
		womenOnly: "Women Only",
		words: "Words",
		workAccidentReference: "Work Accident Ref.",
		wrongWithdrawAmount: "Wrong Withdraw Amount!",
		xpertXpressSarsCov2: "Xpert-Xpress-SARS-CoV-2",
		year: "Year",
		yes: "Yes",
		withholdingTaxCertificate: "Withholding Tax Certificate",
		section50BisOfTheRevenueCode:"Section 50 Bis of The Revenue Code",
		formNum: "Form No.",
		pnd1a: "P.N.D.1a",
		pnd1aEx: "P.N.D.1a Ex",
		pnd2: "P.N.D.2",
		pnd3: "P.N.D.3",
		pnd2a: "P.N.D.2a",
		pnd3a: "P.N.D.3a",
		pnd53: "P.N.D.53",
		socialSecurityFund: "Social Security Fund",
		providentFund: "Provident Fund",
		socialSecurityCard: "Social Security Card",
		employerAccountNum: "Employer Account Num",
		payer: "Payer",
		accountsPayableRC: "Accounts Payable (RC)",
		accountsPayablePI: "Accounts Payable (PI)",
		accountsPayablePV: "Accounts Payable (PV)",
		accountsReceive: "Accounts Receive",
		list: "List",
		subTotal: "Sub Total",
		collectFrom: "Collect From",
		postingGL: "Posting GL",
		glDocument: "GL Document",
		journalEntry: "Journal Entry",
		byLocation: "By Location",
		byId: "By ID",
		split: "Split",
		STAT: "STAT",
		taxWithheld: "Tax Withheld",
		takeHome: "Take Home",
		totalBroughtForward: "Brought Forward Total",
		january: 'January',
		february: 'February',
		march: 'March',
		april:'April',
		may: 'May',
		june: 'June',
		july: 'July',
		august:'August',
		september: 'September',
		october: 'October',
		november: 'November',
		december: 'December',
		accountsPayableAging: "Accounts Payable Aging",
		accountsReceivableAging:"Accounts Receivable Aging",
		pastDue1To30: "1 - 30",
		pastDue31To90: "31 - 90",
		pastDue91To180: "91 - 180",
		pastDue181To360: "181 - 360",
		pastDueOver360: "> 360",
		numberOfInvoices:"Num of Invoices",
		fundOrClaimNum: "Fund/Claim Num",
		contractingCompany: "Contracting Company",
		acceptedBy:"Accepted By",
		operatingRoom: "Operating Room",
		anesthesia: "Anesthesia",
		fastingDateTime: "Fasting Date Time",
		preOperativeDiag: "Pre-Operative Diagosis",
		operativeDoctor: "Operative Doctor",
		operationProcedure: "Operation Procedure",
		anesthesiologist: "Anesthesiologist",
		confirmation: "Confirmation",
		orCase: "OR Case",
		notDueYet: "Current",
		overDue: "Over Due",
		overDueDays: "Over Due (Days)",
		calendar: "Calendar",
		week:"Week",
		balanceAdjustment: "Balance Adjustment",
		exclusiveWokflow: "Exclusive Workflow",
		selectable: "Selectable",
		cashierPayment: "Cashier Payment",
		supplierPricePerOrder: "S-Price/Order",
		discountReceipt: "R-Discount",
		doctorFee: "Doctor Fee",
		anesthesiologistFee: "Anesthesiologist Fee",
		estimatedLengthOfStay: "Estimated Length Of Stay",
		surgicalAuthorization:"Surgical Authorization",
		load: "Load",
		preOperativeDiagICD: "Pre-Operative Diagosis (ICD)",
		operationProcedureICD: "Operation Procedure (ICD)",
		DRG:"DRG",
		pricePerDRG: "Price/DRG",
		rejectedBy: "Rejected By",
		inspectedBy: "Inspected By",
		cancelledBy: "Cancelled By",
		totalEstimatedBudget: "Total Estimated Budget",
		workflowProperty:"Workflow Property",
		minimumCost: "Minimum Cost",
		success: "Success",
		failed: "Failed",
		surgeon:"Surgeon",
		reschedule: "Reschedule",
		appointmentHistory: "Appointment History",
		preAnesthesiaRecord: "Pre-Anesthesia Record",
		intraAnesthesiaRecord: "Intra-Anesthesia Record",
		postAnesthesiaRecord:"Post-Anesthesia Record",
		ga: "GA",
		ASAClass: "ASA Class",
		mouthOpen: "Mouth Open",
		dentures: "Dentures",
		dentalDamage: "Dental Damage",
		thyromental: "Thyromental",
		hyomentalDistance: "Hyomental Distance",
		neck: "Neck",
		mallampatiScore: "Mallampati Score",
		expectedDifficultAirway: "Expected Difficult Airway",
		neurologicalAssessment: "Neurological Assessment",
		backExamination: "Back Examination",
		cardiovascular: "Cardiovascular",
		respiratory: "Respiratory",
		gastrointestinal: "Gastrointestinal",
		endocrine: "Endocrine",
		hematoOncology: "Hemato/Oncology",
		renal: "Renal",
		completeBloodCount: "Complete Blood Count",
		WBC:"WBC",
		hgb: "Hgb",
		hct: "Hct",
		platelets:"Platelets",
		electrolytes: "Electrolytes",
		na: "Na",
		cl: "Cl",
		k: "K",
		hco3: "HCO3",
		bun: "BUN",
		cr: "Cr",
		glucose: "Glucose",
		liverFunctionTest: "Liver Function Test",
		albumin: "Albumin",
		globulin: "Globulin",
		AST: "AST",
		ALT: "ALT",
		totalBilirubin: "Total Bilirubin",
		directBilirubin: "Direct Bilirubin",
		alkalinePhosphatase: "Alkaline Phosphatase",
		coagulation: "Coagulation",
		PT: "PT",
		PTT:"PTT",
		INR:"INR",
		EKG: "EKG",
		CXR: "CXR",
		antiHIV: "Anti-HIV",
		crossMatching: "Cross-Matching",
		packedRedCell: "Packed Red Cell",
		freshFrozenPlasma: "Fresh Frozen Plasma",
		plateletConcentrate: "Platelet Concentrate",
		anesthesiaPlan: "Anesthesia Plan",
		anesthesiaPremedication: "Anesthesia Premedication",
		airwayAssessment: "Airway Assessment",
		normal: "Normal",
		abnormal: "Abnormal",
		obese: "Obese",
		cachexia: "Cachexia",
		pale: "Pale",
		jaundice: "Jaundice",
		cyanosis: "Cyanosis",
		emergency: "Emergency",
		limited: "Limited",
		ventilation: "Ventilation",
		intubation: "Intubation",
		mentalRetard: "Mental Retard",
		oldCVAs: "Old CVAs",
		seizuresEpilepsy: "Seizures/Epilepsy",
		chronicPain: "Chronic Pain",
		myopathy: "Myopathy",
		hypertension: "Hypertension",
		controlled: "Controlled",
		poorlyControlled: "Poorly Controlled",
		functionalCapacity: "Functional Capacity",
		CAD: "CAD",
		arrhythmia: "Arrhythmia",
		dyslipidemia: "Dyslipidemia",
		asthma: "Asthma",
		COPD: "COPD",
		TB: "TB",
		recentURI: "Recent URI",
		fullStomach: "Full Stomach",
		hepatitis: "Hepatitis",
		cirrhosis: "Cirrhosis",
		DM: "DM",
		hypoHyperthyroid: "Hypo/Hyperthyroid",
		steroidUse: "Steroid Use",
		anemia: "Anemia",
		bleeding: "Bleeding",
		recentChemotherapy: "Recent Chemotherapy",
		insufficiency: "Insufficiency",
		ESRD: "ESRD",
		hemodialysis: "Hemodialysis",
		bloodComponentsPrepared: "Blood/Components Prepared",
		generalAnesthesia:"General Anesthesia",
		totalIntravenousAnesthesia:"Total Intravenous Anesthesia",
		regionalAnesthesia: "Regional Anesthesia",
		monitorAnesthesiaCare: "Monitor Anesthesia Care",
		sedate: "Sedate",
		class1: "Class 1",
		class2: "Class 2",
		class3: "Class 3",
		class4: "Class 4",
		packPerDay: "Pack/Day",
		preOperativeCheckList: "Pre-Operative Checklist",
		surgicalSafetyChecklist: "Surgical Safety Checklist",
		sender: "Sender",
		receiver: "Receiver",
		signIn: "Sign In",
		timeOut: "Time Out",
		signOut: "Sign Out",
		BMI: "BMI",
		HRbpm: "HR(bpm)",
		SpO2: "SpO2(%)",
		NPOHour: "NPO(hr)",
		premedication: "Premedication",
		anesthesiaTechnique: "Anesthesia Technique",
		NPO: "NPO",
		problem: "Problem",
		oxygen: "Oxygen",
		anestheticAgent: "Anesthetic Agent",
		monitoring:"Monitoring",
		anesthesiaRecord: "Anesthesia Record",
		anesthesiaStartDateTime: "Anesthesia Start Time",
		anesthesiaEndDateTime: "Anesthesia End Time",
		operativeStartDateTime: "Operative Start Time",
		operativeEndDateTime: "Operative End Time",
		medication: "Medication",
		fixAmount: "Fix Amount",
		operation: "Operation",
		NIBP: "NIBP",
		ALine: "A-Line",
		upper: "Upper",
		lower: "Lower",
		systolic: "Systolic",
		diastolic: "Diastolic",
		surgeonRecord: "Surgeon Record",
		nurseNote: "Nurse Note",
		ambulation: "Ambulation",
		nauseaOrVomitting: "Nausea/Vomitting",
		surgicalBleeding: "Surgical Bleeding",
		pain: "Pain",
		dischargeCriteria: "Discharge Criteria",
		hypotension: "Hypotension",
		airwayObstruction: "Airway Obstruction",
		hypoxia: "Hypoxia",
		urinaryRetention: "Urinary Retention",
		shivering: "Shivering",
		hypoventilation: "Hypoventilation",
		SBU: "SBU",
		anestheticTime: "Anesthetic Time",
		PACURecord: "PACU Record",
		invoiceDate: "Invoice Date",
		nonDispensingRx: "Non-Dispensing Rx",
	},
}

export default en