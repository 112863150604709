import { combineReducers } from "redux"
import notificationReducer from "./notificationReducer"
import timelineReducer from "./timelineReducer"
import selectableAccountsCodeReducer from "./selectableAccountsCodeReducer"

export default combineReducers({
	notification: notificationReducer,
	timeline: timelineReducer,
	selectableAccountsCode: selectableAccountsCodeReducer,
})
