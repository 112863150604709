import { Card, Descriptions, Select } from "antd"
import _ from "lodash"
import React from "react"
import { Button, Col, Container, Row, Spinner } from "react-bootstrap"
import { FcDownload, FcUpload } from "react-icons/fc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setLoadingAction,
	setOperatingRoomRequests,
	setORModalPostAneMedicationItems,
	setORModalPostAneMedicationPendingItems,
	setSelected,
	setValue,
	setWarningId,
	setWarningMsgAction,
} from "~/redux/action"
import {
	SET_MODAL_WARNING_SIZE,
	SET_OR_MODAL_POST_ANE_MEDICATION_FILTER_GENERIC_NAME,
	SET_OR_MODAL_POST_ANE_MEDICATION_SELECTED_ITEM,
	SET_OR_MODAL_POST_ANE_MEDICATION_SELECTED_TYPE,
	SET_PAGE_LOADING,
	SET_WARNING_DISPLAY,
} from "~/redux/type"
import { BigNumberRoundingMode, Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"
import SearchBox from "~/view/component/search_box/SearchBox"

class ORModalPostAneMedicationItem extends React.Component {
	constructor(props) {
		super(props)

		this.onType = this.onType.bind(this)
		this.onFilterGenericName = this.onFilterGenericName.bind(this)
		this.onSelectItem = this.onSelectItem.bind(this)
		this.onUpBtn = this.onUpBtn.bind(this)
		this.onDownBtn = this.onDownBtn.bind(this)
	}

	componentDidMount() {
		if (!_.isEmpty(this.props.operatingRoom.doctorItemTypes)) {
			console.log(this.props.operatingRoom)
			this.props.setSelected(SET_OR_MODAL_POST_ANE_MEDICATION_SELECTED_TYPE, 0, this.props.operatingRoom.doctorItemTypes[0])
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue && _.isEqual(this.props.warning.id, ResourceAssistance.ID.HOSPITAL.operatingRoom.deletePostAneDoctorOrder)) {
				this.deleteDoctorOrder()
			}
		}
		if (
			prevProps.operatingRoom.modalPostAneMedication.filterGenericName !== this.props.operatingRoom.modalPostAneMedication.filterGenericName ||
			!_.isEqual(prevProps.operatingRoom.modalPostAneMedication.selectedType, this.props.operatingRoom.modalPostAneMedication.selectedType)
		) {
			this.props.setSelected(SET_OR_MODAL_POST_ANE_MEDICATION_SELECTED_ITEM, -1, null)
			this.props.setORModalPostAneMedicationItems(this.props.operatingRoom.modalPostAneMedication.itemTable.original)
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_OR_MODAL_POST_ANE_MEDICATION_SELECTED_TYPE, -1, null)
		this.props.setValue(SET_OR_MODAL_POST_ANE_MEDICATION_FILTER_GENERIC_NAME, "")
		this.props.setSelected(SET_OR_MODAL_POST_ANE_MEDICATION_SELECTED_ITEM, -1, null)
	}

	isDownBtnDisabled() {
		return (
			!this.props.operatingRoom.modalPostAneMedication.selectedItem.item ||
			!this.props.operatingRoom.modalPostAneMedication.durationQty ||
			!this.props.operatingRoom.modalPostAneMedication.selectedUnit.unit ||
			!this.props.operatingRoom.modalPostAneMedication.notes ||
			(this.props.operatingRoom.modalPostAneMedication.selectedRate.rate && !this.props.operatingRoom.modalPostAneMedication.rateDuration)
		)
	}

	isUpBtnDisabled() {
		return (
			!this.props.operatingRoom.modalPostAneMedication.selectedPendingItem.item ||
			!_.isEmpty(this.props.operatingRoom.modalPostAneMedication.selectedPendingItem.item.pharmacyOrders)
		)
	}

	deleteDoctorOrder() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.operatingRoom.deleteAneDoctorOrders,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				operatingRoomRequestId: this.props.operatingRoom.selectedRequest.request.id,
				doctorOrders: [
					{
						id: this.props.operatingRoom.modalPostAneMedication.selectedPendingItem.item.id,
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.requests.length > 0) {
				let requests = this.props.operatingRoom.operatingRequestTable.original.filter((each) => each.id !== res.data.requests[0].id)
				requests.push(res.data.requests[0])
				this.props.setOperatingRoomRequests(requests)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Container fluid="small" className={ResourceAssistance.CSS.fullFlex}>
				<Row>
					<Col md={3}>
						<Select
							showSearch
							style={{ textAlign: "center", margin: 0 }}
							optionFilterProp="children"
							filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
							placeholder={translate(ResourceAssistance.Message.itemType)}
							loading={this.props.operatingRoom.isDoctorItemTypesLoading}
							value={
								this.props.operatingRoom.modalPostAneMedication.selectedType.type
									? this.props.operatingRoom.modalPostAneMedication.selectedType.index
									: undefined
							}
							onChange={this.onType}
						>
							{Utils.renderSelects(this.props.operatingRoom.doctorItemTypes, false)}
						</Select>
					</Col>
					<Col>
						<SearchBox
							num={1}
							lableName=""
							controlTypes={[ResourceAssistance.FormControl.type.text]}
							placeholders={[ResourceAssistance.Message.genericName]}
							callbacks={[this.onFilterGenericName]}
						/>
					</Col>
				</Row>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<ScrollableTable
							striped
							hover
							enableHighlight
							onClick={this.onSelectItem}
							data={this.props.operatingRoom.modalPostAneMedication.itemTable}
							isClearHighlight={this.props.operatingRoom.modalPostAneMedication.selectedItem.index === -1}
							highlightedRow={this.props.operatingRoom.modalPostAneMedication.selectedItem.index}
						/>
					</Col>
					{this.props.operatingRoom.isItemsLoading && (
						<Spinner animation={ResourceAssistance.Spinner.border} variant={ResourceAssistance.Button.variant.primary} />
					)}
				</Row>
				<Row>
					<Col />
					<Col md="auto">
						<Button
							type={ResourceAssistance.Button.type.submit}
							variant={ResourceAssistance.Button.variant.link}
							disabled={this.isDownBtnDisabled()}
							onClick={this.onDownBtn}
						>
							<FcDownload size={"3rem"} />
						</Button>
					</Col>
					<Col />
					<Col md={"auto"}>
						<Button variant={ResourceAssistance.Button.variant.link} disabled={this.isUpBtnDisabled()} onClick={this.onUpBtn}>
							<FcUpload size={"3rem"} />
						</Button>
					</Col>
					<Col />
				</Row>
			</Container>
		)
	}
	onType(v) {
		if (v === undefined) {
			this.props.setSelected(SET_OR_MODAL_POST_ANE_MEDICATION_SELECTED_TYPE, -1, null)
			return
		}
		this.props.setSelected(SET_OR_MODAL_POST_ANE_MEDICATION_SELECTED_TYPE, v, this.props.operatingRoom.doctorItemTypes[v])
	}
	onFilterGenericName(event) {
		this.props.setValue(SET_OR_MODAL_POST_ANE_MEDICATION_FILTER_GENERIC_NAME, Utils.trim(event.target.value))
	}
	onSelectItem(item, index) {
		this.props.setSelected(SET_OR_MODAL_POST_ANE_MEDICATION_SELECTED_ITEM, index, this.props.operatingRoom.modalPostAneMedication.itemTable.filtered[index])
	}
	onDownBtn(event) {
		event.preventDefault()
		event.stopPropagation()
		let qty = Utils.calculateDoctorOrderQtyByRate(
			this.props.operatingRoom.modalPostAneMedication.durationQty,
			this.props.operatingRoom.modalPostAneMedication.rateDuration,
			this.props.operatingRoom.modalPostAneMedication.selectedUnit.unit.amount,
			this.props.operatingRoom.modalPostAneMedication.selectedUnit.unit.oneTime
		)
		let selectedItem = this.props.operatingRoom.modalPostAneMedication.selectedItem.item
		let newItem = Object.assign({}, selectedItem, {
			...selectedItem,
			startDateTime: this.props.operatingRoom.modalPostAneMedication.dateTime,
			endDateTime: this.props.operatingRoom.modalPostAneMedication.dateTime,
			notes: this.props.operatingRoom.modalPostAneMedication.notes,
			categoryId: this.props.operatingRoom.modalPostAneMedication.selectedType.type.id,
			categoryName: this.props.operatingRoom.modalPostAneMedication.selectedType.type.description,
			durationQty: qty.dp(0, BigNumberRoundingMode.ROUND_UP),
			duration: 0,
			unit: { displayName: this.props.operatingRoom.modalPostAneMedication.selectedUnit.unit.displayName },
			sellPricePerUnit: this.props.operatingRoom.modalPostAneMedication.selectedUnit.unit.oneTime
				? selectedItem.sellPricePerUnit
				: this.props.operatingRoom.modalPostAneMedication.selectedUnit.unit.sellingPricePerUnit,
			isSTAT: true,
			isOneTimeOnly: true,
			isNonDisposable:
				this.props.operatingRoom.modalPostAneMedication.selectedUnit.unit.oneTime !== undefined &&
				!this.props.operatingRoom.modalPostAneMedication.selectedUnit.unit.oneTime,
		})
		let pendingItems = Array.from(this.props.operatingRoom.modalPostAneMedication.pendingItemTable.filtered)
		pendingItems.push(newItem)
		this.props.setORModalPostAneMedicationPendingItems(pendingItems)
	}

	onUpBtn() {
		let selectedPendingItem = this.props.operatingRoom.modalPostAneMedication.selectedPendingItem.item
		if (selectedPendingItem.isExistOrder) {
			this.props.setWarningId(ResourceAssistance.ID.HOSPITAL.operatingRoom.deletePostAneDoctorOrder)
			this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.md)
			this.props.setWarningMsgAction(
				<Card
					title={translate(ResourceAssistance.Message.delete)}
					bordered={false}
					style={{
						backgroundColor: "inherit",
					}}
					styles={{
						header: {
							fontSize: "28px",
						},
					}}
				>
					<Descriptions style={{ flex: "unset", display: "unset" }} size={"small"} contentStyle={{ fontStyle: "italic", fontWeight: "normal" }} column={1}>
						<Descriptions.Item label={translate(ResourceAssistance.Message.date)} span={1}>
							{`${Utils.formatDateTime(selectedPendingItem.startDateTime)} - ${Utils.formatDateTime(selectedPendingItem.endDateTime)}`}
						</Descriptions.Item>
						<Descriptions.Item label={translate(ResourceAssistance.Message.genericName)} span={1}>
							{selectedPendingItem.description}
						</Descriptions.Item>
						<Descriptions.Item label={translate(ResourceAssistance.Message.note)} span={1}>
							{selectedPendingItem.notes}
						</Descriptions.Item>
					</Descriptions>
				</Card>
			)
			this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
		} else {
			let pendingItems = Array.from(this.props.operatingRoom.modalPostAneMedication.pendingItemTable.filtered)
			pendingItems.splice(this.props.operatingRoom.modalPostAneMedication.selectedPendingItem.index, 1)
			this.props.setORModalPostAneMedicationPendingItems(pendingItems)
		}
	}
}

const mapStateToProps = (state) => ({
	warning: state.modal.warning,
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setORModalPostAneMedicationItems,
			setORModalPostAneMedicationPendingItems,
			setOperatingRoomRequests,
			setSelected,
			setValue,
			setWarningId,
			setWarningMsgAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ORModalPostAneMedicationItem))
