import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance } from "~/i18n"
import { setLoadingAction, setObjArray } from "~/redux/action"
import { SET_HOSPITEL_MODAL_NURSE_RECORD_CUSTOM_DEFAULT_CATEGORIES, SET_PAGE_LOADING } from "~/redux/type"
import HospitelModalNurseRecordAction from "./HospitelModalNurseRecordAction"
import HospitelModalNurseRecordAIE from "./HospitelModalNurseRecordAIE"
import HospitelModalNurseRecordDate from "./HospitelModalNurseRecordDate"
import HospitelModalNurseRecordFocus from "./HospitelModalNurseRecordFocus"

class HospitelModalNurseRecordDetails extends React.Component {
	componentDidMount() {
		this.loadCustomDefaultCategories()
	}

	loadCustomDefaultCategories() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.admin.customDefault.getCategories,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: { excludeInActive: true },
		}
		let callback = (res) => {
			this.props.setObjArray(SET_HOSPITEL_MODAL_NURSE_RECORD_CUSTOM_DEFAULT_CATEGORIES, res.data.types)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Container fluid className={"full-flex space-top-each-row-parent"}>
				{!this.props.ipd.selectedNurseNote.nurseNote && <HospitelModalNurseRecordAction />}
				<HospitelModalNurseRecordDate />
				<Row className="full-size">
					<Col md={4}>
						<HospitelModalNurseRecordFocus />
					</Col>
					<Col>
						<HospitelModalNurseRecordAIE />
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	ipd: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setObjArray,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(HospitelModalNurseRecordDetails)
