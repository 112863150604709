import { AppstoreOutlined, FieldTimeOutlined, FilePdfOutlined, HomeOutlined, NotificationOutlined } from "@ant-design/icons"
import { Menu } from "antd"
import { Button, Col, Form, Navbar } from "react-bootstrap"
import { injectIntl, useIntl } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import { ResourceAssistance, translate } from "~/i18n"
import { LOCALES } from "~/i18n/locale"
import { IconFont } from "~/icons"
import { store } from "~/redux/Store"
import { onModalDisplayAction, resetAction } from "~/redux/action"
import { changeLocale } from "~/redux/action"
import {
	SET_NOTIFICATION_DISPLAY,
	SET_REPORT_FINANCE_APA_FILTER_DISPLAY,
	SET_REPORT_FINANCE_ARA_FILTER_DISPLAY,
	SET_REPORT_FINANCE_GL_FILTER_DISPLAY,
	SET_REPORT_HOSPITAL_CASHIER_ADMISSION_FILTER_DISPLAY,
	SET_REPORT_HOSPITAL_CASHIER_BILLING_TO_TODAY_FILTER_DISPLAY,
	SET_REPORT_HOSPITAL_CASHIER_USER_ROLE_FILTER_DISPLAY,
	SET_REPORT_HOSPITAL_HOSPITEL_PATIENT_FILTER_DISPLAY,
	SET_REPORT_HOSPITAL_LAB_COVID19_FILTER_DISPLAY,
	SET_REPORT_INV_FILTER_BY_LOCATION,
	SET_REPORT_PO_FILTER_BY_ID,
	SET_REPORT_PO_FILTER_BY_LOCATION,
	SET_REPORT_TF_FILTER_BY_LOCATION,
	SET_TIMELINE_DISPLAY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import Logout from "./Logout"

const DOCTOR = "doctor"
const NURSE = "nurse"
const EItemKey = {
	NOTIFICATION: "NOTIFICATION",
	TIMELINE: "TIMELINE",
	REPORT: "REPORT",
}
const EReportHospitalCashierKey = {
	ByAdmission: "REPORT_HOSPITAL_CASHIER_BY_ADMISSION",
	ProfessionalServiceExpenseByUser: "REPORT_HOSPITAL_CASHIER_PROFESSIONAL_SERVICE_EXPENSE_BY_USER",
	BillingToTodayByAdmission: "REPORT_HOSPITAL_CASHIER_BILLING_TO_TODAY_BY_ADMISSION",
}
const EReportHospitalIPDKey = {
	Patient: "REPORT_HOSPITAL_IPD_PATIENT",
}
const EReportHospitalLabKey = {
	CovidLab: "REPORT_HOSPITAL_LAB_COVID_LAB",
}
const EReportStockInventoryKey = {
	ByLocation: "Report_Stock_Inventory_By_Location",
}
const EReportStockTransferKey = {
	ByLocation: "Report_Stock_Transfer_By_Location",
}
const EReportStockPurchaseOrderKey = {
	ByLocation: "Report_Stock_PO_By_Location",
	ById: "Report_Stock_PO_By_ID",
}
const EReportFinanceKey = {
	GeneralLedger: "Report_Finance_General_Ledger",
	AccountsPayableAging: "Report_Finance_Accounts_Payable_Aging",
	AccountsReceivableAging: "Report_Finance_Accounts_Receivable_Aging",
}

// export default class Header extends Component {
export default injectIntl(function Header(props) {
	const dispatch = useDispatch()
	const intl = useIntl()
	const user = useSelector((state) => state.login.user)
	const common = useSelector((state) => state.common)

	let reportSubMenus = []
	if (Utils.hasPrivilege(ResourceAssistance.Privilege.Report.View, user.roles)) {
		if (Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.Report.Cashier, user.roles)) {
			reportSubMenus.push({
				label: intl.formatMessage({ id: ResourceAssistance.Hospitel.cashier }),
				popupOffset: [-3, 0],
				children: [
					{
						key: EReportHospitalCashierKey.ByAdmission,
						label: intl.formatMessage({ id: ResourceAssistance.Message.byAdmission }),
						disabled: true,
					},
					{
						key: EReportHospitalCashierKey.ProfessionalServiceExpenseByUser,
						label: intl.formatMessage({ id: ResourceAssistance.Message.professionalServiceExpenseByUser }),
					},
					{
						key: EReportHospitalCashierKey.BillingToTodayByAdmission,
						label: intl.formatMessage({ id: ResourceAssistance.Message.billingToTodayByAdmission }),
					},
				].sort((a, b) => Utils.sort(a.label, b.label)),
			})
		}
		if (Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.Report.Hospitel, user.roles)) {
			reportSubMenus.push({
				label: intl.formatMessage({ id: ResourceAssistance.Hospitel.ipd }),
				popupOffset: [-3, 0],
				children: [
					{
						key: EReportHospitalIPDKey.Patient,
						label: intl.formatMessage({ id: ResourceAssistance.Hospitel.patient }),
					},
				].sort((a, b) => Utils.sort(a.label, b.label)),
			})
		}
		if (Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.Report.Lab, user.roles)) {
			reportSubMenus.push({
				label: intl.formatMessage({ id: ResourceAssistance.Hospitel.lab }),
				popupOffset: [-3, 0],
				children: [
					{
						key: EReportHospitalLabKey.CovidLab,
						label: intl.formatMessage({ id: ResourceAssistance.Hospitel.covid19Lab }),
					},
				].sort((a, b) => Utils.sort(a.label, b.label)),
			})
		}
		if (Utils.hasPrivilege(ResourceAssistance.Privilege.Management.View, user.roles)) {
			reportSubMenus.push({
				label: intl.formatMessage({ id: ResourceAssistance.Message.inventory }),
				popupOffset: [-3, 0],
				children: [
					{
						key: EReportStockInventoryKey.ByLocation,
						label: intl.formatMessage({ id: ResourceAssistance.Message.byLocation }),
					},
				].sort((a, b) => Utils.sort(a.label, b.label)),
			})
			reportSubMenus.push({
				label: intl.formatMessage({ id: ResourceAssistance.Message.transfer }),
				popupOffset: [-3, 0],
				children: [
					{
						key: EReportStockTransferKey.ByLocation,
						label: intl.formatMessage({ id: ResourceAssistance.Message.byLocation }),
					},
				].sort((a, b) => Utils.sort(a.label, b.label)),
			})
			reportSubMenus.push({
				label: intl.formatMessage({ id: ResourceAssistance.Message.purchaseOrder }),
				popupOffset: [-3, 0],
				children: [
					{
						key: EReportStockPurchaseOrderKey.ById,
						label: intl.formatMessage({ id: ResourceAssistance.Message.byId }),
					},
					{
						key: EReportStockPurchaseOrderKey.ByLocation,
						label: intl.formatMessage({ id: ResourceAssistance.Message.byLocation }),
					},
				].sort((a, b) => Utils.sort(a.label, b.label)),
			})
		}
		if (Utils.hasPrivilege(ResourceAssistance.Privilege.Finance.Report.View, user.roles)) {
			reportSubMenus.push({
				label: intl.formatMessage({ id: ResourceAssistance.Message.finance }),
				popupOffset: [-3, 0],
				children: [
					{
						key: EReportFinanceKey.GeneralLedger,
						label: intl.formatMessage({ id: ResourceAssistance.Message.generalLedger }),
					},
					{
						key: EReportFinanceKey.AccountsPayableAging,
						label: intl.formatMessage({ id: ResourceAssistance.Message.accountsPayableAging }),
					},
					{
						key: EReportFinanceKey.AccountsReceivableAging,
						label: intl.formatMessage({ id: ResourceAssistance.Message.accountsReceivableAging }),
					},
				].sort((a, b) => Utils.sort(a.label, b.label)),
			})
		}
	}

	const items = [
		{
			key: EItemKey.NOTIFICATION,
			icon: <NotificationOutlined />,
			label: translate(ResourceAssistance.Message.notification),
			// disabled: true,
		},
		{
			key: EItemKey.TIMELINE,
			icon: <FieldTimeOutlined />,
			label: translate(ResourceAssistance.Message.timeline),
		},
		{
			type: "divider",
		},
		{
			key: EItemKey.REPORT,
			icon: <FilePdfOutlined />,
			label: translate(ResourceAssistance.Message.report),
			popupOffset: [-3, 0],
			children: reportSubMenus.sort((a, b) => Utils.sort(a.label, b.label)),
		},
	]

	const onMenu = (e) => {
		switch (e.key) {
			case EItemKey.NOTIFICATION:
				dispatch(onModalDisplayAction(SET_NOTIFICATION_DISPLAY, true))
				break
			case EItemKey.TIMELINE:
				dispatch(onModalDisplayAction(SET_TIMELINE_DISPLAY, true))
				break
			// Report Hospital Cashier
			case EReportHospitalCashierKey.ByAdmission:
				dispatch(onModalDisplayAction(SET_REPORT_HOSPITAL_CASHIER_ADMISSION_FILTER_DISPLAY, true))
				break
			case EReportHospitalCashierKey.ProfessionalServiceExpenseByUser:
				dispatch(onModalDisplayAction(SET_REPORT_HOSPITAL_CASHIER_USER_ROLE_FILTER_DISPLAY, true))
				break
			case EReportHospitalCashierKey.BillingToTodayByAdmission:
				dispatch(onModalDisplayAction(SET_REPORT_HOSPITAL_CASHIER_BILLING_TO_TODAY_FILTER_DISPLAY, true))
				break
			// Report Hospital IPD
			case EReportHospitalIPDKey.Patient:
				dispatch(onModalDisplayAction(SET_REPORT_HOSPITAL_HOSPITEL_PATIENT_FILTER_DISPLAY, true))
				break
			//Report Hospital Lab
			case EReportHospitalLabKey.CovidLab:
				dispatch(onModalDisplayAction(SET_REPORT_HOSPITAL_LAB_COVID19_FILTER_DISPLAY, true))
				break
			//Report Stock Inventory
			case EReportStockInventoryKey.ByLocation:
				dispatch(onModalDisplayAction(SET_REPORT_INV_FILTER_BY_LOCATION, true))
				break
			//Report Stock Transfer
			case EReportStockTransferKey.ByLocation:
				dispatch(onModalDisplayAction(SET_REPORT_TF_FILTER_BY_LOCATION, true))
				break
			//Report Stock PO
			case EReportStockPurchaseOrderKey.ByLocation:
				dispatch(onModalDisplayAction(SET_REPORT_PO_FILTER_BY_LOCATION, true))
				break
			case EReportStockPurchaseOrderKey.ById:
				dispatch(onModalDisplayAction(SET_REPORT_PO_FILTER_BY_ID, true))
				break
			//Report FInance
			case EReportFinanceKey.GeneralLedger:
				dispatch(onModalDisplayAction(SET_REPORT_FINANCE_GL_FILTER_DISPLAY, true))
				break
			case EReportFinanceKey.AccountsPayableAging:
				dispatch(onModalDisplayAction(SET_REPORT_FINANCE_APA_FILTER_DISPLAY, true))
				break
			case EReportFinanceKey.AccountsReceivableAging:
				dispatch(onModalDisplayAction(SET_REPORT_FINANCE_ARA_FILTER_DISPLAY, true))
				break
			default:
				break
		}
	}

	const getRoleIcon = (role) => {
		if (role.toLowerCase() === DOCTOR) {
			return <IconFont type={"icon-ali-pmgcare-yisheng_1"} style={{ fontSize: ResourceAssistance.ReactIcon.size }} />
		} else if (role.toLowerCase() === NURSE) {
			return <IconFont type={"icon-ali-pmgcare-hushi"} style={{ fontSize: ResourceAssistance.ReactIcon.size }} />
		} else {
			return <IconFont type={"icon-ali-pmgcare-yuangong1"} style={{ fontSize: ResourceAssistance.ReactIcon.size }} />
		}
	}

	// render() {
	return (
		<>
			<header>
				<Navbar bg="dark" variant="dark" style={{ padding: "0.5rem 1rem" }}>
					{/* <Navbar.Brand href={ResourceAssistance.Path.home}> */}
					{props.history.location.pathname !== ResourceAssistance.Path.login && (
						<>
							<Menu
								style={{
									maxWidth: "100px",
									backgroundColor: "inherit",
									lineHeight: "14px",
									marginLeft: "-20px",
									marginRight: "-10px",
								}}
								selectable={false}
								overflowedIndicator={<AppstoreOutlined style={{ fontSize: ResourceAssistance.ReactIcon.size_md }} />}
								mode="horizontal"
								theme="dark"
								items={items}
								onClick={onMenu}
							/>
						</>
					)}
					<Navbar.Brand
						style={{ marginRight: "5rem", display: "flex", alignItems: "center" }}
						title={
							Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.View, user.roles ? user.roles : []) ||
							props.history.location.pathname === ResourceAssistance.Path.login
								? common.header
								: ResourceAssistance.Message.stockManagement.text
						}
						onClick={() => {
							const unSubscribe = store.subscribe(() => {})
							// document.title = props.intl.formatMessage({
							// id: ResourceAssistance.Message.purchaseRequest,
							// })
							if (Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.View, user.roles ? user.roles : [])) {
								document.title = ResourceAssistance.CONSTANT.PMGCARE
							} else {
								document.title = intl.formatMessage({ id: ResourceAssistance.Message.stockManager })
							}
							props.history.push(ResourceAssistance.Path.home)
							store.dispatch(resetAction())
							unSubscribe()
							Utils.notification()
						}}
					>
						{<HomeOutlined style={{ fontSize: ResourceAssistance.ReactIcon.size }} />}
						<span style={{ marginLeft: "0.3rem" }}>
							{Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.View, user.roles ? user.roles : []) ||
							props.history.location.pathname === ResourceAssistance.Path.login
								? common.header
								: ResourceAssistance.Message.stockManagement.text}
						</span>
					</Navbar.Brand>
					<Form
						style={{
							flexGrow: 1,
							display: "flex",
							flexWrap: "nowrap",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "flex-end",
						}}
					>
						<Col md="auto" style={{ padding: "0 15px" }}>
							<Navbar.Brand style={{ marginRight: 0, display: "flex", alignItems: "center" }} title={user.displayName}>
								{user.userType && getRoleIcon(user.userType.name)}
								<span style={{ marginLeft: "0.3rem" }}>{user.displayName}</span>
							</Navbar.Brand>
						</Col>
						<Col md="auto" style={{ padding: "0 15px" }}>
							<Button variant={ResourceAssistance.Button.variant.info} onClick={() => dispatch(changeLocale(LOCALES.ENGLISH))}>
								{ResourceAssistance.Message.english}
							</Button>
						</Col>
						<Col md="auto" style={{ padding: "0 15px" }}>
							<Button variant={ResourceAssistance.Button.variant.info} onClick={() => dispatch(changeLocale(LOCALES.THAI))}>
								{translate(ResourceAssistance.Message.thai)}
							</Button>
						</Col>
						{props.history.location.pathname !== ResourceAssistance.Path.login && (
							<Col sm="auto" style={{ padding: "0 15px" }}>
								<Button href={ResourceAssistance.Path.login} onClick={() => Logout(props)}>
									{translate(ResourceAssistance.Message.logout)}
								</Button>
							</Col>
						)}
					</Form>
				</Navbar>
			</header>
		</>
	)
})
