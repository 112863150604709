import { Descriptions, Select } from "antd"
import React, { Fragment } from "react"
import { Col, FormControl, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected, setValue } from "~/redux/action"
import {
	SET_DEPOSIT_MODAL_SAVE_AMOUNT,
	SET_DEPOSIT_MODAL_SAVE_REMARK,
	SET_DEPOSIT_MODAL_SAVE_SELECTED_PAYMENT,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import GInput from "~/view/component/input/GInput"

class DepositModalSaveDescription extends React.Component {
	constructor(props) {
		super(props)

		this.onAmount = this.onAmount.bind(this)
		this.onPayment = this.onPayment.bind(this)
		this.onRemark = this.onRemark.bind(this)
	}

	componentDidMount() {
		this.props.loadPayments()
	}

	componentWillUnmount() {
		this.props.setSelected(SET_DEPOSIT_MODAL_SAVE_SELECTED_PAYMENT, -1, null)
		this.props.setValue(SET_DEPOSIT_MODAL_SAVE_AMOUNT, 0)
		this.props.setValue(SET_DEPOSIT_MODAL_SAVE_REMARK, "")
	}

	onAmount(event) {
		this.props.setValue(SET_DEPOSIT_MODAL_SAVE_AMOUNT, event.target.value)
	}

	onPayment(value) {
		this.props.setSelected(
			SET_DEPOSIT_MODAL_SAVE_SELECTED_PAYMENT,
			value > -1 ? Number(value) : -1,
			value > -1 ? this.props.deposit.modalSave.payments[value] : null
		)
	}

	onRemark(event) {
		this.props.setValue(SET_DEPOSIT_MODAL_SAVE_REMARK, event.target.value)
	}

	render() {
		return (
			<Fragment>
				<Row>
					<Col />
					<Col md={6}>
						<Descriptions>
							<Descriptions.Item>
								<Select
									id={ResourceAssistance.ID.HOSPITAL.deposit.modalSave.payment}
									showSearch
									placeholder={translate(ResourceAssistance.Message.payment)}
									value={
										this.props.deposit.modalSave.selectedPayment.payment
											? this.props.deposit.modalSave.selectedPayment.index
											: undefined
									}
									style={{ flexGrow: 1, textAlign: "center" }}
									optionFilterProp="children"
									filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
									filterSort={(optionA, optionB) =>
										optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
									}
									onChange={this.onPayment}
								>
									{Utils.renderSelects(this.props.deposit.modalSave.payments, false)}
								</Select>
							</Descriptions.Item>
						</Descriptions>
					</Col>
					<Col />
				</Row>
				<Row>
					<Col />
					<Col md={6}>
						<Descriptions column={1}>
							<Descriptions.Item
								label={translate(ResourceAssistance.Message.amount)}
								labelStyle={{ alignSelf: "center" }}
							>
								<FormControl
									required
									type={ResourceAssistance.FormControl.type.number}
									size={ResourceAssistance.FormControl.size.sm}
									step={0.01}
									value={this.props.deposit.modalSave.amount}
									onChange={this.onAmount}
								/>
							</Descriptions.Item>
						</Descriptions>
					</Col>
					<Col />
				</Row>
				<Row>
					<Col />
					<Col md="auto">
						<Descriptions style={{ flex: "unset", display: "unset" }}>
							<Descriptions.Item
								label={translate(ResourceAssistance.Hospitel.balance)}
								labelStyle={{ fontSize: "24px" }}
								contentStyle={{ fontSize: "24px", color: ResourceAssistance.CSS.Color.dark_green }}
							>
								{this.props.deposit.modalSave.amount
									? Utils.formatNumWithComma(Utils.BigNumber(this.props.deposit.modalSave.amount).toFixed(2))
									: Utils.BigNumber(0).toFixed(2)}
							</Descriptions.Item>
						</Descriptions>
					</Col>
					<Col />
				</Row>
				<Row>
					<Col>
						<GInput
							autoSize
							minRows={3}
							placeholder={translate(ResourceAssistance.Message.remark)}
							onChange={this.onRemark}
							value={this.props.deposit.modalSave.remark}
						/>
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	deposit: state.hospital.deposit,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(DepositModalSaveDescription)
