import { LOCALES } from "../locale"
import IntraAnesthesiaRecord from "./th/IntraAnesthesiaRecord"
import PreOperativeCheckList from "./th/PreOperativeCheckList"
import SurgicalSafetyCheckList from "./th/SurgicalSafetyCheckList"
import PostAnesthesiaRecord from "./th/PostAnesthesiaRecord"
import Warning from "./th/Warning"

const th = {
	[LOCALES.THAI]: {
		...PreOperativeCheckList,
		...SurgicalSafetyCheckList,
		...IntraAnesthesiaRecord,
		...PostAnesthesiaRecord,
		...Warning,
		//Remark
		remarkReactionToLight: "***เร็ว +, ไม่มีปฏิกิริยา -, ช้า s, ปิดตา c",
		remarkPrimaryHealthCarePlan: "แผนประกันสุขภาพที่ได้รับการชำระเงินโดยตรงจากผู้ป่วยด้วยเงินสด บัตรเครดิต ฯลฯ",
		remarkConfigureAtCategoryBank: 'ตั้งค่าที่หมวดหมู่ "ธนาคาร"',
		remarkTheReceiptWillBeValidOnceThePaymentHasBeenReceived: " ใบเสร็จรับเงินจะสมบูรณ์ก็ต่อเมื่อได้รับเงินจากธนาคารเรียบร้อยแล้ว",
		
		//Report
		reportWithholdingTaxWithholder: "ผู้มีหน้าที่หักภาษี ณ ที่จ่าย",
		reportWithholdingTaxPayer: "ผู้ถูกหักภาษี ณ ที่จ่าย",
		reportFormNumClarify: "ให้สามารถอ้างอิงหรือสอบยันกันได้ระหว่างลำดับที่ตาม หนังสือรับรองฯ กับแบบยื่นรายการภาษีหักที่จ่าย",
		reportTypeOfIncomePaid: "ประเภทเงินได้พึงประเมินที่จ่าย",
		reportSalaryWagesEtcSection401: "เงินเดือน ค่าจ้าง เบี้ยเลี้ยง โบนัส ฯลฯ ตามมาตรา 40(1)", 
		reportFeeCommissionsSection402: "ค่าธรรมเนียม ค่านายหน้า ฯลฯ ตามมาตรา 40(2)",
		reportCopyrightSection403: "ค่าแห่งลิขสิทธิ์ ฯลฯ ตามมาตรา 40(3)",
		reportInterestSection404a: "ค่าดอกเบี้ย ฯลฯ ตามมาตรา 40(4)(ก)",
		reportDividendsSection404b: "เงินปันผล ส่วนแบ่งของกำไร ฯลฯ ตามมาตรา 40(4)(ข)",
		reportDividendEntitledToTaxCreditByRate: "กรณีผู้ได้รับเงินปันผลได้รับบัตรเครดิตภาษี โดยจ่ายจาก กำไรสุทธิของกิจการที่ต้องเสียภาษีเงินได้นิติบุคคลในอัตราดังนี้",
		report30PercentOfNetProfit: "อัตราร้อยละ 30 ของกำไรสุทธิ",
		report25PercentOfNetProfit: "อัตราร้อยละ 25 ของกำไรสุทธิ",
		report20PercentOfNetProfit: "อัตราร้อยละ 20 ของกำไรสุทธิ",
		reportOtherPercentOfNetProfit: "อัตราอื่น ๆ (ระบุ) ของกำไรสุทธิ",
		reportDividendEntitledToTaxCreditBy: "กิจการที่ได้รับยกเว้นภาษีเงินได้นิติบุคคลซึ่ง ผู้รับเงินปันผลไม่ได้รับเครดิตภาษี",
		reportNetProfitOfBusinessExemptFromCorporateIncomeTax: "กำไรสุทธิของกิจการที่ได้รับยกเว้นภาษีเงินได้นิติบุคคล",
		reportDividendOrProfitSharesAreExcept: "เงินปันผลหรือเงินส่วนแบ่งของกำไรที่ได้รับยกเว้นไม่ต้องนำมารวมคำนวณเป็นรายได้เพื่อเสียภาษีนิติบุคคล",
		reportNetProfitDeductedFromNetLosses: "กำไรสุทธิส่วนที่ได้หักผลขาดทุนสุทธิยกมาไม่เกิน 5 ปีก่อนรอบระยะเวลาบัญชีปัจจุบัน",
		reportProfitRecognizedByTheEquityMethod: "กำไรที่รับรู้ทางบัญชีโดยวิธีส่วนได้เสีย",
		reportPaymentOfIncomeSubjectToWithholdingTaxAccordingTo: "การจ่ายเงินได้ต้องหักภาษี ณ. ที่จ่าย ตามคำสั่งกรมการากรที่ออกตามมาตรา 3 เตรส เช่น รางวัล ส่วนลดหรือประโยชน์ใดๆ เนื่องจากการส่งเสริมการขายรางวัลในการประกวด การแข่งขัน การชิงโชค ค่าแสดงของนักแสดงสาธารณะค่าบริการ ค่าขนส่ง ค่าจ้างทำของ ค่าจ้างโฆษณา ค่าเช่า ค่าเบี้ยประกันวินาศภัย ฯลฯ",
		reportGovernmentPensionFundOrGovernmentPermanentExployee: "เงินสะสมจ่ายเข้ากองทุนสำรองเลี้ยงชีพ ใบอนุญาตเลขที่",
		reportWithholdAtSource: "หักภาษี ณ ที่จ่าย",
		reportPayEveryTime:"ออกภาษีให้ตลอดไป",
        reportPayOneTime: "ออกภาษีให้ครั้งเดียว",
		reportIHereByCertify: "ขอรับรองว่าข้อความและตัวเลขดังกล่าวข้างต้น ถูกต้องตรงกับความจริงทุกประการ",
		reportDayMonthYearThatTheCertificateWasIssued:"วัน เดือน ปี ที่ออกหนังสือรับรองฯ", 
		reportStampedByLegalEntity: "ประทับตรานิติบุคค",
		reportThePersonResponsibleForIssuingWithholdingTaxCertificate: "ผู้มีหน้าที่ออกหนังสือรับรองการหักภาษี ณ. ที่จ่าย ฝ่าฝืนไม่ปฏิบัติตามมาตรา 50 ทวิ แห่งประมวลรัษฎากรต้องรับโทษทางอาญา ตามมาตรา 35 แห่งประมวลรัษฎากร",
		reportTotalAmountIncomeAndWithholdingTaxIntl: "รวมเงินที่จ่าย และ ภาษีที่นำส่ง",
		reportTotalWithholdingTaxInLetters:"รวมเงินภาษีที่นำส่ง (ตัวอักษร)",
		
		//Message
		"": "",
		ICD: "ICD",
		LMP: "LMP",
		abdomen: "Abdomen",
		abnormalFlexion: "แขนงอเข้าหาตัวผิดปกติ",
		accidentHistory: "ประวัติอุบัติเหตุ",
		accidentReference: "Accident Ref.",
		accounting: "การบัญชี",
		accountsCode: "รหัสบัญชี",
		accountsPayable: "บัญชีที่สามารถจ่ายได้",
		accountsPayableRC: "บัญชีที่สามารถจ่ายได้ (RC)",
		accountsPayablePI: "บัญชีที่สามารถจ่ายได้ (PI)",
		accountsPayablePV: "บัญชีที่สามารถจ่ายได้ (PV)",
		accountsReceivable: "บัญชีลูกหนี้",
		active: "ใช้งานอยู่",
		adHocOrder: "Ad Hoc Order",
		add: "เพิ่ม",
		address: "ที่อยู่",
		addressEng: "ที่อยู่ (Eng)",
		adjustments: "ส่วนลด",
		admin: "ผู้จัดการระบบ",
		admission: "การรักษาในโรงพยาบาล",
		admissionDate: "วันที่เข้ารับการรักษา",
		admissionPlan: "เข้านอนในโรงพยาบาล",
		adpType: "ชนิด ADP",
		afterMeal: "หลังอาหาร",
		age: "อายุ",
		aie: "AIE",
		alcohol: "แอลกอฮอล์",
		allergies: "แพ้ยา/อาหาร",
		amount: "จำนวน",
		amountML: "จำนวน (mL)",
		amountOfPackage: "จำนวนรับหน่วยใหญ่",
		amountPerOrder: "Amount/Order",
		analyte: "การตรวจวิเคราะห์",
		antigen: "Antigen",
		antigenCOI: "Antigen (COI)",
		antigenComment: "ข้อสังเกต Antigen",
		appointment: "นัดใหม่",
		appointmentDate: "วันที่นัดหมาย",
		appointmentTime: "เวลานัดหมาย",
		approve: "อนุมัติ",
		approved: "อนุมัติ",
		approvedBy: "อนุมัติโดย",
		approvedInventories: "รายการที่อนุมัติ",
		approver: "ผู้อนุมัติ",
		approverCheck: "A",
		arrival: "มาโดย",
		assessment: "Assessment",
		assets: "สินทรัพย์",
		attachment: "เอกสารแนบ",
		authenNumber: "หมายเลขยืนยันตัวตน",
		authentication: "การยืนยันตัวตน",
		authorization: "การอนุญาต",
		authorize: "อนุญาต",
		back: "Back",
		balance: "จำนวนเงิน",
		bank: "ธนาคาร",
		bankAccNum: "หมายเลขบัญชีธนาคาร",
		bankBranch: "สาขาธนาคาร",
		bankCode: "รหัสธนาคาร",
		bankName: "ชื่อธนาคาร",
		batchRecordFormat: "Batch Record Format",
		beforeBed: "ก่อนนอน",
		beforeMeal: "ก่อนอาหาร",
		bill: "ใบเสร็จ/ใบแจ้งหนี้",
		billByCategory: "ใบเสร็จ/ใบแจ้งหนี้ (หมวดหมู่)",
		billByHealthCarePlan: "ใบเสร็จ/ใบแจ้งหนี้ (แผนดูแลสุขภาพ)",
		billDetails: "รายละเอียดใบเสร็จ/ใบแจ้งหนี้",
		billSummary: "สรุปการเรียกเก็บเงิน/ใบแจ้งหนี้",
		billTo: "สถานที่วางบิล",
		billingAddress: "ที่อยู่เรียกเก็บเงิน",
		billingContact: "ติดต่อเรียกเก็บเงิน",
		billingToTodayByAdmission: "ประมาณการค่ารักษาพยาบาลล่าสุด",
		bloodTest: "ตรวจเลือด",
		bonus: "แถม",
		bpmmHg: "BP. (mmHg)",
		branch: "สาขา",
		branchCode: "รหัสสาขา",
		brandName: "ชื่อสินค้า",
		brandNameAndCode: "ชื่อสินค้า/รหัส",
		budget: "งบประมาณ",
		by: "โดย",
		byAdmission: "โดยการรับเข้าเรียน",
		byUser: "โดยผู้ใช้",
		calculation: "การคำนวณ",
		cancel: "ยกเลิก",
		cancelDoctorPrescriptionOnly: "ยกเลิกใบสั่งแพทย์เท่านั้น",
		cancelNotReceived: "รายการที่กำลังจะยกเลิก",
		cancelled: "ยกเลิก",
		cannotDownloadLogo: "ไม่สามารถโหลดโลโก้ได้",
		cannotReadSMC: "อ่านไอดีไม่ได้!",
		carAccident: "อุบัตติเหตุทางรถ",
		carAccidentReference: "เลขเคลม พรบ.",
		career: "อาชีพ",
		cashier: "แคชเชียร์",
		category: "หมวดหมู่",
		categoryNum: "ลำดับที่หมวดใบเสร็จ",
		certified: "ตรวจสอบ",
		certifiedBy: "ตรวจสอบโดย",
		certifiesThat: "ขอรับรองว่า",
		change: "ทอนเงินให้กับลูกค้า",
		changeEndDate: "แก้ไขวันที่สิ้นสุด",
		changePassword: "เปลี่ยนรหัสผ่าน",
		chartOfAccounts: "ผังบัญชี",
		checkIn: "ลงทะเบียน",
		checkInDate: "วันที่เริ่มรักษา",
		checkInTime: "เวลาเริ่มรักษา",
		checkNum: "ตรวจสอบหมายเลข",
		checkOut: "ออกจาก",
		checkOutDate: "วันที่สิ้นสุด",
		checkOutTime: "เวลาสิ้นสุด",
		checkbox: "C",
		checkout: "คิดค่าใช้จ่าย",
		cheifFinancialOfficer: "หัวหน้าเจ้าหน้าที่การเงิน (CFO)",
		chiefComplaint: "อาการแรกรับ(CC)",
		chrgItem: "CHRGITEM (E-claim)",
		chromatography: "Chromatography",
		city: "เมือง",
		cityEng: "เมือง (Eng)",
		claimFile: "เอกสารเรียกร้อง",
		clear: "ลบออก",
		clinicalRecord: "บันทึกการรักษา",
		close: "ปิด",
		code: "รหัส",
		companyOrResponsiblePersonContact: "ชื่อบริษัท/ผู้รับผิดชอบ และเบอร์ติดต่อ",
		complete: "เสร็จสิ้น",
		confirm: "ยืนยัน",
		confirmPassword: "ยืนยันรหัสผ่าน",
		confused: "พูดคุยสับสน",
		contact: "ติดต่อ",
		contactInfo: "ข้อมูลการติดต่อ",
		contactPerson: "ชื่อ-สกุล ผู้ติดต่อ",
		continue: "ดำเนินการต่อ",
		contractBilling: "การเรียกเก็บเงินตามสัญญา",
		contractBillingId: "การเรียกเก็บเงินตามสัญญา(ID)",
		contraindicationsAndWarnings: "ข้อห้าม/คำเตือน",
		coordinator: "ผู้ประสานงาน",
		costPerOrder: "ราคาทุนต่อหน่วยใหญ่ ก่อนVAT",
		covid19Lab: "Covid-19 แล็บ",
		covid19Negative: "ผลการตรวจไม่พบเชื้อ Covid-19",
		covid19Positive: "ผลการตรวจพบเชื้อ Covid-19",
		createNewPR: "เพิ่มสินค้าในใบขอซื้อ ",
		creator: "ผู้สร้าง",
		credit: "ยอดเครดิต",
		creditCard: "บัตรเครดิต",
		currencySign: "\u0E3F",
		current: "จำนวนคงคลัง",
		currentBalance: "ยอดเงินปัจจุบัน",
		currentLevel: "จำนวนปัจจุบัน",
		date: "วันที่",
		dateOfBirth: "วันเกิด",
		dateOfPo: "วันที่สั่งซื้อ",
		day: "วัน",
		days: "วัน",
		daysOfRest: "วัน",
		debit: "ยอดเดบิต",
		debt: "หนี้",
		default: "ค่าตั้งต้น",
		delete: "ลบ",
		deleteSkippedPharmacyOrder: "ลบใบสั่งยาที่ข้ามไป",
		deleteVerifiedPharmacyOrder: "ลบใบสั่งยาที่ยืนยันแล้ว",
		department: "แผนก",
		deposit: "เงินฝาก",
		depressionAssessment: "แบบประเมินภาวะซึมเศร้า",
		description: "คำอธิบาย",
		deselectAll: "ยกเลิกการเลือกทั้งหมด",
		detailRecordFormat: "Detail Record Format",
		diagnosis: "การวินิจฉัยโรค",
		discharge: "ให้ผู้ป่วยออก",
		dischargeDate: "วันที่ให้ผู้ป่วยออก",
		discharged: "ผู้ป่วยที่ปลดประจำการ",
		discount: "ลดราคา",
		discountBill: "ลดราคา (ใบแจ้งหนี้)",
		discountItem: "ลดราคา (ไอเท็ม)",
		discountPricePerOrder: "ราคาหลังส่วนลดรวม",
		discountPricePerUnit: "ราคาหลังส่วนลด",
		discountService: "ลดราคา (บริการ)",
		discountTaxPricePerUnit: "ราคาหลังภาษีและส่วนลด",
		discountWithSymbol: "ส่วนลด %",
		dispense: "แจกจ่าย",
		dispensed: "ได้ถูกส่งไป",
		dispensedBy: "ส่งโดย",
		dispensingTime: "เวลาจ่าย",
		displayAll: "แสดงทั้งหมด",
		displayExpire: "แสดงรายการที่หมดอายุ",
		displayInactive: "แสดงรายการที่ไม่ใช้งาน",
		displayMinStock: "แสดงรายการที่ต่ำกว่า",
		doctor: "แพทย์",
		doctorConsultation: "ปรึกษาแพทย์",
		doctorName: "ชื่อแพทย์",
		doctorNote: "หมอหมายเหตุ",
		doctorOpinion: "ความเห็นแพทย์",
		doctorOptionAfter: "ได้รับการรักษา ครบถ้วน 14 วันแล้ว สามารถไปทำงาน และ ใช้ชีวิตได้ตามปกติ",
		doctorPrefix: "Dr.",
		doctorPrescription: "ใบสั่งแพทย์",
		doctorPrescriptionOnly: "ใบสั่งแพทย์เท่านั้น",
		doctorStandingOrder: "คำสั่งแพทย์",
		documentNum: "หมายเลขเอกสาร",
		done: "เสร็จสิ้น",
		dose: "ขนาดยา",
		doubleClickToDelete: "ดับเบิ้ลคลิ๊กเพื่อลบไอเท็ม",
		download: "Download",
		drinking: "ดื่มสุรา",
		driver: "ผู้ขับขี่",
		dueDate: "วันครบกำหนด",
		dupplicateCode: "มีรหัสนี้แล้ว!",
		dupplicateCodeWarning: "รหัสนี้มีอยู่แล้ว!",
		dupplicateName: "มีชื่อนี้แล้ว!",
		dupplicateNameWarning: "Name exist already!",
		dupplicateWarning: "มีในระบบแล้ว!",
		duration: "ระยะเวลา",
		eclaim: "E-Claim",
		eclaimFiles: "ไฟล์ E-claim",
		eclaimUc: "E-Claim (UC)",
		eclaimVariable: "ตัวแปรของ E-Claim",
		eGene: "E gene:CT",
		edit: "แก้ไข",
		email: "อีเมลล์",
		empty: "",
		emptyCancelReason: "ต้องใส่เหตุผลในการปฏิเสธ",
		endDate: "วันที่สิ้นสุด",
		endDateTime: "วันที่สิ้นสุด",
		equity: "สิทธิครอบครอง",
		estimatedDateOfConfinement: "วันที่จัดส่งโดยประมาณ (EDC)",
		evaluation: "Evaluation",
		every: "ทุก",
		examinationDate: "วันที่รับการรักษา",
		expected: "วันที่กำหนด",
		expenseEstimation: "ประมาณการค่าใช้จ่าย",
		expenses: "ค่าใช้จ่าย",
		expire: "หมดอายุ",
		expired: "หมดอายุ",
		expiredDate: "วันหมดอายุ",
		export: "ส่งออกไฟล์",
		exportTypes: "ชนิดไฟล์ที่ส่งออก",
		extension: "แขนขาเหยียดเกร็ง",
		extremities: "Extremities",
		eyeOpeningResponse: "การตอบสนองของดวงตา (E)",
		factoryArea: "พื้นที่กองทุน",
		fallRisk: "เสี่ยงล้ม",
		familyHealthHistory: "ประวัติสุขภาพครอบครัว",
		fax: "เบอร์แฟกซ์",
		faxNo: "เบอร์แฟกซ์",
		fee: "ค่าธรรมเนียม",
		female: "หญิง",
		fileName: "ชื่อไฟล์",
		files: "ไฟล์",
		fillWithCharacter: "เติมช่องว่างด้วยตัวอักษร",
		filter: "ตัวกรอง",
		finance: "การเงิน",
		financialOfficer: "เจ้าหน้าที่การเงิน",
		financialOperations: "การดำเนินงานทางการเงิน",
		financialPlan: "แผนการเงิน",
		financialTransaction: "การทำธุรกรรมทางการเงิน",
		firstAssessment: "แบบประเมินแรกรับ",
		firstName: "ชื่อจริง",
		firstNameEng: "ชื่อจริง (Eng)",
		fluorescence: "Fluorescence",
		focus: "Focus",
		form: "รูปแบบยา",
		frequency: "ความถี่",
		friday: "Fri",
		from: "จาก",
		fsCode: "F/S Code",
		gender: "เพศ",
		general: "ทั่วไป",
		generalAppearance: "ลักษณะทั่วไป (GA)",
		generalInfo: "ข้อมูลทั่วไป",
		generalLedger: "บัญชีแยกประเภททั่วไป",
		generic: "ทั่วไป",
		genericName: "ชื่อสามัญ",
		genericNameOrTradeName: "ชื่อสามัญ/ชื่อการค้า",
		glasgowComaScale: "Glasgow Coma Scale (GCS)",
		googleDrive: "G-Drive",
		governmentCode: 'รหัสตามที่ สปสช. กำหนด',
		grandTotal: "จำนวนเงินรวมสุทธิ",
		graph: "กราฟ",
		hasExaminedOn: "ได้รับการตรวจ/รักษาเมื่อ",
		hashSymbol: "\u00A0#",
		health: "สุขภาพ",
		healthCare: "สิทธิ",
		healthCarePlan: "แผนดูแลสุขภาพ",
		healthHistory: "ประวัติสุขภาพ",
		heart: "Heart",
		heent: "HEENT",
		height: "ส่วนสูง",
		hello: "สวัสดี",
		hn: "HN",
		home: "หน้าแรก",
		hospital: "โรงพยาบาล",
		hospitalBilling: "การเรียกเก็บเงินของโรงพยาบาล",
		hospitalCode: 'รหัสโรงพยาบาล',
		hospitalInformation: 'ข้อมูลโรงพยาบาล',
		hospitel: "Hospitel",
		hours: "ชั่วโมง",
		iPay: "อินเทอร์เน็ตจ่าย",
		iPayFormat: "iPay Format",
		id: "เลขที่",
		idCard: "บัตรประชาชน",
		identification: "บัตรประชาชน/พาสพอร์ต",
		idType: "ชนิดของ ID",
		inPatient: "ผู้ป่วยใน",
		inProgress: "กำลังดำเนินการ",
		inactive: "ไม่ได้ใช้",
		incomplete: "ไม่สมบูรณ์",
		inpatientPlan: "แผนผู้ป่วยใน",
		inspector: "ผู้ตรวจสอบ",
		inspectorCheck: "I",
		instruction: "คำแนะนำ",
		insuranceCompany: "บริษัท",
		insuranceReference: "เลขกรมธรรม์",
		intake: "อินพุต",
		intakeOutput: "Intake/Output",
		internationalClassificationDiseases: "การจัดจำแนกระหว่างประเทศของโรค (ICD)",
		intervention: "Intervention",
		inventory: "สินค้าคงคลัง",
		inventoryByLocation: "รายงานใบคลังสินค้าตามสถานที่",
		investigation: "การตรวจพิเศษ",
		invoice: "ใบแจ้งหนี้",
		invoiceId: "รหัสใบแจ้งหนี้",
		invoiceInfo: "ข้อมูลใบแจ้งหนี้",
		invoiceNum: "เลขที่ใบแจ้งหนี้",
		ioBalance: "I/O ยอดเงิน",
		ipd: "IPD",
		isolationPrecaution: "ข้อควรระวังการแยกตัว",
		issued: "จัดทำ",
		issuedBy: "จัดทำโดย",
		item: "ไอเท็ม",
		itemSupplier: "ชื่อสินค้า",
		itemType: "ชนิดของไอเท็ม",
		journalVoucher: "ใบสำคัญสมุดรายวัน (JV)",
		keyword: "คำสำคัญ",
		lab: "ห้องทดลอง",
		labNumber: "เลขที่แลบ",
		label: "ป้ายกำกับ",
		labTest: "รหัสการตรวจ",
		language: "ต้องการล่ามภาษา",
		last: "ล่าสุด",
		lastFor: "ระยะเวลา",
		lastFourDigitOfCreditCard: "4 หลักสุดท้ายของบัตรเครดิต",
		lastLocation: "ตำแหน่งสุดท้าย",
		lastMenstrualPeriod: "ประจำเดือนครั้งสุดท้าย (LMP)",
		lastModifiedDate: "วันที่แก้ไขล่าสุด",
		lastName: "นามสกุล",
		lastNameEng: "นามสกุล (Eng)",
		lastPurchasedFrom: "บริษัทที่สั่งซื้อล่าสุด",
		lastReceivedDate: "วันที่ซื้อล่าสุด",
		left: "ด้านซ้าย (L)",
		leftEye: "ตาซ้าย (L)",
		leftEyePupilSizeMM: "ขนาดรูม่านตา (mm) (L)",
		leftEyeReactionToLight: "ปฏิกิริยาต่อแสง (L)",
		letterOfConsent: "หนังสือแสดงความยินยอมรับการรักษาพยาบาลเป็นผู้ป่วยใน",
		level: "ระดับ",
		liabilities: "หนี้สิน",
		licenseNo: "ใบอนุญาตเลขที่",
		licenseNum: "ใบอนุญาต #",
		linkGL: "ลิงค์ GL",
		loadPreviousMedicalRecords: "โหลดเวชระเบียนก่อนหน้า",
		loading: "กำลังดาวโหลด...",
		localising: "ทราบตําแหน่งที่ถูกกระตุ้น/ปัดสิ่งกระตุ้นถูกตําแหน่ง",
		location: "สถานที่",
		login: "เข้าสู่ระบบ",
		loginError: "ชื่อผู้ใช้ หรือ รหัสผิดพลาด!",
		loginName: "ชื่อผู้ใช้",
		logo: 'โลโก้',
		logoDragDropTitle: 'คลิกหรือลากโลโก้มาวางที่นี้',
		logoDragDropDescription: 'โลโก้ต้องเป็นไฟล์ PNG หรือ JPEG และขนาดไม่เกิน 200KB.',
		logout: "ออกจากระบบ",
		lot: "เลขที่ผลิต",
		lotNum: "เลขที่ผลิต",
		lung: "Lung",
		male: "ชาย",
		management: "การจัดการ",
		marriage: "สภาพสมรถ",
		max: "จำนวนสูงสุด",
		maxCharacters: "ตัวละครสูงสุด",
		maxLevel: "ปริมาณสูงสุด",
		md: "แพทย์ผู้ตรวจรักษา",
		medicalCertificate: "ใบรับรองแพทย์",
		medicalCertificateEng: "ใบรับรองแพทย์ (Eng)",
		medicalCertificateIPD: "ใบรับรองแพทย์ (IPD)",
		medicalCertificateOPD: "ใบรับรองแพทย์ (OPD)",
		medicalRecord: "เวชระเบียน",
		medicalSupply: "เวชภัณฑ์",
		medicationSheet: "ใบบันทึกการให้ยา",
		medicationUsage: "วิธีใช้ยา",
		medicine: "ยา",
		medicineHistory: "ประวัติยาเสพติด",
		memoId: "รหัสเอกสาร",
		menopause: "วัยหมดประจำเดือน",
		menstruation: "ภาวะมีประจำเดือน",
		message: "ข้อความ",
		method: "วิธี",
		min: "จำนวนต่ำสุด",
		minLevel: "ปริมาณต่ำสุด",
		minQtyPerOrder: "จำนวนขั้นต่ำในการสั่งซื้อ",
		minStock: "สินค้าคงเหลือน้อยกว่าจำนวนต่ำสุด",
		minutes: "นาที",
		modified: "เปลี่ยนไปแล้ว",
		modifyDateRange: "แก้ไขช่วงวันที่",
		monday: "Mon",
		moneyCheck: "เช็คเงิน",
		month: "เดือน",
		motorResponse: "การตอบสนองของมอเตอร์ (M)",
		multiTime: "ใช้หลายครั้ง",
		n2geneCT: "N2 gene:CT",
		nGene: "N gene:CT",
		name: "ชื่อ",
		nameEng: "ชื่อ (Eng)",
		nameSurname: "ชื่อ-นามสกุล",
		nameTitle: "คำนำหน้า",
		nation: 'สัญชาติ',
		negative: "Negative",
		neuro: "Neuro",
		new: "ใหม่",
		newForm: "รูปแบบการบริหารยาใหม่",
		newItem: "ไอเท็มใหม่",
		newItemSupplier: "ชื่อสินค้าใหม่",
		newItemWarning: "สินค้าได้รับการอนุมัติแล้ว!",
		newLab: "แลบใหม่",
		newReceive: "รายการที่กำลังจะอนุมัติ",
		newRecord: "บันทึกการให้ยา",
		newRequest: "สร้างใบขอ",
		newRole: "บทบาทใหม่",
		newSupplier: "เพิ่มผู้ขายใหม่",
		newTax: "อัตราภาษีใหม่",
		newTransfer: "สร้างใบโอน",
		newUnit: "หน่วยใหม่",
		newUser: "ผู้ใช้ใหม่",
		nickName: "ชื่อเล่น",
		no: "ไม่",
		noEyeOpening: "ไม่ลืมตา",
		noMotor: "ไม่ตอบสนองต่อสิ่งกระตุ้น",
		noVerbal: "ไม่ส่งเสียงใดๆ",
		normalFlexion: "หรืองอแขนเมื่อถูกกระตุ้นด้วยแรงกด",
		note: "หมายเหตุ",
		notification: "การแจ้งเตือน",
		novelDiseaseInvestigationSheet: " ใบสอบสวนโรค Novel 3",
		number: "เลขที่",
		nurse: "พยาบาล",
		nurseName: "ชื่อพยาบาล",
		nurseOrder: "การรักษาทางการพยาบาล",
		nurseProgressNote: "บันทึกทางการพยาบาล",
		o2Sat: "O\u2082 Sat (%)",
		oGene: "ORF1ab gene:CT",
		obeysCommands: "เคลื่อนไหวได้ตามคําสั่ง",
		objective: "Objective",
		oneMonth: "หนึ่งเดือน",
		oneTime: "แบบใช้แล้วทิ้ง",
		oneWeek: "หนึ่งอาทิตย์",
		opd: "OPD",
		opdClinicalRecord: "บันทึกการรักษา OPD",
		operationalService: "การบริการตามจริง",
		operationType: 'ประเภทการให้บริการ',
		or: "หรือ",
		order: "คำสั่ง",
		org: "บริษัท",
		orientated: "พูดคุยได้ไม่สับสน",
		other: "อื่นๆ",
		output: "เอาต์พุต",
		package: "จำนวนย่อยในหน่วยใหญ่",
		paid: "ยอดที่ชำระแล้ว",
		painScale: "ระดับความเจ็บปวด",
		parentLoc: "คลังใหญ่",
		parentType: "",
		passenger: "ผู้โดยสาร/คนซ้อน",
		passiveSmokeExposure: "การเปิดรับควันแบบพาสซีฟ",
		password: "รหัส",
		passwordDoesNotMatch: "รหัสผ่านไม่ตรง!",
		pastIllnesses: "ความเจ็บป่วยในอดีต",
		pastSurgicalHistory: "ประวัติการผ่าตัด",
		patient: "ผู้ป่วย",
		patientId: "PID",
		patientInfo: "ข้อมูลผู้ป่วย",
		patientName: "ชื่อผู้ป่วย",
		patientSaving: "บัญชีออมทรัพย์ผู้ป่วย",
		pay: "จ่ายเงิน",
		payee: "ผู้รับเงิน",
		payment: "การชำระเงิน",
		paymentPlan: "แผนการชำระเงิน",
		paymentSlip: "ใบสำคัญจ่าย",
		paymentTerms: "ระยะเวลาการชำระเงิน",
		paymentType: "วิธีการชำระเงิน",
		pcr: "PCR",
		pcrComment: "ข้อสังเกต PCR",
		pedestrian: "บุคคลภายนอก",
		pending: "รอ",
		pendingItems: "ไอเท็มที่ขอ",
		pendingOrder: "ใบสั่งซื้อที่ยังรอรับ",
		pendingTransfers: "ไอเท็มที่ยังไม่ได้นำส่ง",
		percent: "เปอร์เซ็นต์",
		percentSymbol: "%",
		permission: "การอนุญาต",
		pharmacist: "เภสัชกร",
		pharmacistVerified: "เภสัชกรตรวจสอบแล้ว",
		pharmacy: "ร้านขายยา",
		pharmacyDispensed: "ร้านขายยาได้จ่ายยา",
		pharmacyInventory: "สินค้าคงคลังร้านขายยา",
		phone: "โทรศัพท์",
		physicalExamination: "การตรวจร่างกาย",
		placeOfIncident: "สถานที่เกิดเหตุ",
		plan: "Plan",
		poDate: "วันที่สั่งซื้อ",
		poId: "รหัสใบสั่งซื้อ",
		poNumber: "เลขที่ใบสั่งซื้อ #",
		policeName: "ร้อยเวรเจ้าของคดี",
		policeStation: "ส.น.",
		positive: "Positive",
		postcode: "รหัสไปรษณีย์",
		prNumber: "เลขที่ใบขอซื้อ #",
		prPerMin: "PR./min",
		pregnancy: "ตั้งครรภ์",
		presentIllness: "ความเจ็บป่วยในปัจจุบัน (PI.)",
		pricePerOrder: "ราคาต่อหน่วยซื้อ",
		pricePerOrderBeforeTax: "ราคาต่อหน่วยซื้อ ก่อน VAT",
		pricePerUnit: "ราคา",
		pricePerUnitBeforeTax: "ราคา/หน่วย ก่อน VAT",
		primaryHospital: "โรงพยาบาลหลัก",
		primaryHospitalCode:"รหัสหน่วยบริการหลัก",
		primaryHospitalName:"ชื่อหน่วยบริการหลัก",
		primaryPlan: "แผนหลัก",
		print: "พิมพ์",
		printDate: "พิมพ์วันที่",
		printLabel: "พิมพ์สติกเกอร์",
		prnRecord: "บันทึกการให้ยา PRN",
		professionalServiceExpenseByUser: "สรุปค่าบริการแยกตามบุคลากร",
		province: "จังหวัด",
		provinceEng: "จังหวัด(Eng)",
		psId: "ใบชำระเงิน(ID)",
		pulse: "PR",
		pupilSize: "ขนาดของรูม่านตา",
		purchase: "ซื้อ",
		purchaseFrom: "ซื้อจาก",
		purchaseInvoice: "ใบสำคัญเจ้าหนี้",
		purchaseInvoiceId: "รหัสใบสำคัญเจ้าหนี้",
		purchaseOrder: "ใบสั่งซื้อ",
		purchaseOrderById: "รายงานใบสั่งซื้อโดยบัตรประจำตัว",
		purchaseOrderByLocation: "รายงานใบสั่งซื้อตามสถานที่",
		purchaseRequest: "ใบขอซื้อ",
		qty: "ปริมาณ",
		qtyPerDay: "จำนวน/วัน",
		questionnaire: "แบบสอบถาม",
		radiology: "ถ่ายภาพรังสี",
		rc: "เลขที่ใบรับสินค้า",
		reactionToLight: "ปฏิกิริยาต่อแสง",
		reason: "เหตุผล",
		receipt: "ใบเสร็จ",
		receiptCategory: "หมวดใบเสร็จ",
		receiptId: "ใบเสร็จรับเงิน",
		receive: "ใบรับสินค้า",
		receiveId: "รหัสใบรับสินค้า",
		receiveItemDetails: "รายละเอียดของรายการที่จะอนุมัติ",
		received: "จำนวนที่ได้รับ",
		receivedBy: "ผู้รับสินค้า",
		references: "อ้างอิง",
		refresh: "รีเฟรช",
		refund: "คืนเงิน",
		registerOPD: "ลงทะเบียน OPD",
		registerPatient: "ลงทะเบียนผู้ป่วย",
		registration: "การลงทะเบียน",
		registrationDate: "วันที่ลงทะเบียน",
		reject: "ปฏิเสธ",
		rejectReason: "เหตุผลที่ถูกปฏิเสธ",
		relationship: "ความสัมพันธ์",
		relativeInfo: "ข้อมูลญาติ",
		remark: "หมายเหตุ",
		report: "รายงาน",
		reportById: "รายงานโดยระบุตัวตน",
		reportByLocation: "รายงานแยกตามสถานที่",
		request: "ยืนยันการขอ",
		requestCovid19MedicalCertificate: "ขอใบรับรองแพทย์ตรวจ Covid-19",
		requestFrom: "แผนกที่ขอ",
		requestId: "เลขที่ใบขอโอน",
		requestedInventories: "รายการที่ต้องการ",
		requester: "ผู้ขอซื้อ",
		requesterCheck: "R",
		requiredByDate: "วันที่กำหนดรับสินค้า",
		reset: "รีเซ็ต",
		result: "ผลการทดสอบ",
		return: "คืนสินค้า",
		returned: "จำนวนที่ส่งคืน",
		returning: "พร้อมกลับ",
		revenues: "รายได้",
		right: "ด้านขวา (R)",
		rightEye: "ตาขวา (R)",
		rightEyePupilSizeMM: "ขนาดรูม่านตา (mm) (R)",
		rightEyeReactionToLight: "ปฏิกิริยาต่อแสง (R)",
		riskOfAbuse: "เสี่ยงต่อการถูกทารุณกรรม",
		role: "บทบาท",
		room: "ห้อง",
		rrPerMin: "RR./min",
		run: "เรียกรายงาน",
		sGene: "S gene:CT",
		sarsCov2RnaPcr: "SARS-CoV-2-RNA-PCR",
		satCode: "SAT Code",
		saturday: "Sat",
		save: "บันทึก",
		saveAll: "บันทึกทั้งหมด",
		saveFail: "บันทึกข้อมูลไม่สำเร็จ กรุณาทำรายการอีกครั้ง",
		saveSuccess: "บันทึกข้อมูลสำเร็จ",
		scheduled: "กำหนดการ",
		scheduledDate:"เวลาที่นัดหมายไว้",
		score: "คะแนน",
		search: "ค้นหา",
		searchIncomplete: "หาที่ยังไม่เสร็จ",
		searchPatient: "ค้นหาผู้ป่วย",
		selectAll: "เลือกทั้งหมด",
		sell: "ขาย",
		sellingPricePerUnit: "ราคาขาย/หน่วย",
		seq: "SEQ",
		sequence: "ลำดับที่",
		service: "บริการ",
		serviceType: "สําหรับบริการ",
		setAmount: "ใส่จำนวน...",
		shipAddress: "ที่อยู่จัดส่ง",
		shippingContact: "ติดต่อจัดส่งสินค้า",
		signature: "ลงชื่อ",
		size: "ขนาด",
		skipPayment: "ข้ามการชำระเงิน",
		skipPercent: "ละเว้นเปอร์เซ็นต์",
		skipVerify: "ข้ามการยืนยัน",
		skipped: "ข้ามไป",
		smoking: "สูบบุหรี่",
		soap: "SOAP",
		somethingWrong: "เกิดข้อผิดพลาด",
		sounds: "ส่งเสียงแต่ไม่เป็นคําพูด",
		spontaneous: "ลืมตาได้เอง",
		startDate: "วันที่เริ่มต้น",
		startDateTime: "วันที่เริ่มต้น",
		status: "สถานะ",
		stock: "คลังสินค้า",
		stockAndPurchasing: "คลังและจัดซื้อ",
		stockManager: "Stock Manager",
		stools: "Stools",
		stop: "หยุด",
		stretcher: "รถนอน",
		subLevel: "ระดับย่อย",
		subjective: "Subjective",
		subunit: "หน่วยย่อย",
		sunday: "Sun",
		supplementOrHerbsHistory: "Supplement/Herbs ประวัติ",
		supplier: "ผู้ขาย",
		supplierCode: "รหัสผู้ขาย",
		supplierDiscount: "ส่วนลดจากผู้ขาย",
		supplierName: "ชื่อผู้ขาย",
		symptoms: "เนื่องจากอาการ",
		table: "ตาราง",
		taxId: "เลขประจำตัวผู้เสียภาษีอากร",
		taxRate: "ภาษี",
		taxRateWithSymbol: "อัตราภาษี %",
		tel: "เบอร์โทร",
		telNo: "เบอร์โทร",
		temperature: "T. (C)",
		terms: "ระยะเวลา",
		thai: "ไทย",
		theAboveNamedPersonRequires: "เห็นสมควรให้หยุดพักรักษาตัวเป็นเวลา",
		thisMonth: "เดือนนี้",
		thursday: "Thurs",
		time: "เวลา",
		timeOfIncident: "เวลาที่เกิดเหตุ",
		timeline: "เส้นเวลา",
		title: "คำนำหน้า",
		tmltTmtCode: "รหัส TMLT/TMT",
		to: "ถึง",
		toPressure: "ลืมตาเมื่อได้รับแรงกด",
		toSound: "ลืมตาเมื่อได้ยินเสียง",
		tobacco: "ยาสูบ",
		today: "วันนี้",
		tomorrow: "พรุ่งนี้",
		total: "ยอดรวม",
		totalAdjustments: "การปรับทั้งหมด",
		totalBalance: "ยอดรวม",
		totalBudget: "วงเงินรวม",
		totalDeposit: "ยอดเงินฝาก",
		totalEstimationCost: "ค่าใช้จ่ายรวม",
		totalIntake: "อินพุตทั้งหมด",
		totalOutput: "เอาต์พุตทั้งหมด",
		totalPaid: "ยอดชำระทั้งหมด",
		totalPrice: "ราคารวม",
		totalPriceDiscounted: "ราคารวมหลังส่วนลด",
		totalPriceDiscountedTaxed: "ราคารวมหลังภาษีและส่วนลด",
		totalPriceTaxed: "ราคารวมสุทธิ",
		totalUnpaid: "ยอดค้างชำระทั้งหมด",
		totalWithdraw: "การใช้จ่าย",
		tradeName: "ชื่อการค้า",
		tradeNameOrBrand: "ชื่อการค้า/ยี่ห้อ",
		transaction: "ธุรกรรม",
		transactionDescription: "คำอธิบายธุรกรรม",
		transactionFee: "ค่าธรรมเนียมการทำธุรกรรม",
		transfer: "โอน",
		transferBankBranch: "โอนธนาคารสาขา",
		transferBankName: "ชื่อธนาคารผู้โอน",
		transferByLocation: "รายงานใบโอนตามสถานที่",
		transferFrom: "สาขาที่โอน",
		transferId: "เลขที่ใบโอน",
		transferingItems: "ไอเท็มที่เตรียมนำส่ง",
		transferred: "นำส่งแล้ว",
		translator: "ความต้องการล่าม",
		treatment: "การรักษา",
		triage: "Triage (ESI Index)",
		tuesday: "Tues",
		twoWeeks: "สองสัปดาห์",
		type: "ประเภท",
		typeOfIncident: "ลักษณะการเกิดเหตุ",
		typesOfDiagnosis: "ประเภทของการวินิจฉัย",
		ucae: "รหัสกรณีอุบัติเหตุ/ฉุกเฉิน",
		uid: "UID",
		unapproveReason: "เหตุผลที่ไม่อนุมัติ: ",
		unapproved: "ไม่อนุมัติ",
		underlyingDisease: "โรคประจำตัว",
		unit: "หน่วย",
		unitConversion: "การแปลงหน่วย",
		unitPrice: "ราคาต่อหน่วย",
		unlinkGL: "ยกเลิกการเชื่อมโยง GL",
		unpaid: "ยอดค้างชำระ",
		uom: "หน่วยวัด",
		upload: "ที่อัพโหลด",
		uploaded: "อัพโหลดแล้ว",
		urine: "Urine",
		url: 'URL',
		user: "ผู้ใช้",
		username: "ชื่อผู้ใช้",
		usingContraceptive: "ใช้ยาคุมกำเนิด",
		uuc: "การใช้สิทธิ",
		vaccine: "วัคซีน",
		value: "มูลค่า",
		variable: "ตัวแปรที่มีอยู่",
		variablesFormat: "รูปแบบตัวแปร",
		vat: "ภาษีมูลค่าเพิ่ม",
		verbalResponse: "การตอบสนองทางวาจา (V)",
		verified: "ได้รับการตรวจสอบแล้ว",
		verify: "ตรวจสอบ",
		verifyAllPrescription: "ตรวจสอบใบสั่งยาทั้งหมด",
		verifyTodayPrescription: "ตรวจสอบใบสั่งยาของวันนี้",
		victim: "เหยื่อผู้เคราะห์ร้าย",
		view: "มุมมองรายงาน",
		visitId: "VID",
		vitalSign: "สัญญาณชีพ",
		volume: "ปริมาตร",
		walk: "เดินมาเอง",
		warning: "คำเตือน!",
		waste: "ของเสีย",
		wednesday: "Wed",
		weight: "น้ำหนัก",
		wheelchair: "รถเข็น",
		withdraw: "ถอน",
		withholdingTax: "ภาษีหัก ณ ที่จ่าย (WHT)",
		womenOnly: "ผู้หญิงเท่านั้น",
		words: "พูดเป็นคําๆ",
		workAccidentReference: "เลขที่บัญชีกองทุน",
		xpertXpressSarsCov2: "Xpert-Xpress-SARS-CoV-2",
		year: "ปี",
		yes: "ใช่",
		withholdingTaxCertificate: "หนังสือรับรองการหักภาษี ณ ที่จ่าย",
		section50BisOfTheRevenueCode: "ตามมาตรา 50 ทวิ แห่งประมวลรัษฎากร",
		formNum: "หมายเลขแบบฟอร์ม",
		pnd1a: "ภ.ง.ด. 1ก.",
		pnd1aEx: "ภ.ง.ด. 1ก.พิเศษ",
		pnd2: "ภ.ง.ด.2",
		pnd3: "ภ.ง.ด.3",
		pnd2a: "ภ.ง.ด.2ก.",
		pnd3a: "ภ.ง.ด.3ก.",
		pnd53: "ภ.ง.ด.53",
		socialSecurityFund: "กองทุนประกันสังคม",
		providentFund: "กองทุนสำรองเลี้ยงชีพ",
		socialSecurityCard: "บัตรประกันสังคม",
		employerAccountNum: "หมายเลขบัญชีนายจ้าง",
		payer: "ผู้จ่ายเงิน",
		accountsReceive: "บันทึกการรับชำระ",
		list: "รายการ",
		subTotal: "จำนวนเงินรวม",
		collectFrom: "เรียกเก็บเงินจาก",
		singleTime: "ใช้ครั้งเดียว",
		postingGL: "Posting GL",
		glDocument: "ประเภทเอกสาร GL",
		journalEntry: "Journal Entry",
		byLocation: "ตามสถานที่",
		byId: "ตามหมายเลขเอกสาร",
		split: "แยก",
		STAT: "STAT",
		taxWithheld: "ภาษีที่หัก และนำส่งไว้",
		takeHome: "นำกลับบ้าน",
		totalBroughtForward: "ยอดยกมา",
		january: 'มกราคม',
		february: 'กุมภาพันธ์',
		march: 'มีนาคม',
		april:'เมษายน',
		may: 'พฤษภาคม',
		june: 'มิถุนายน',
		july: 'กรกฎาคม',
		august:'สิงหาคม',
		september: 'กันยายน',
		october: 'ตุลาคม',
		november: 'พฤศจิกายน',
		december: 'ธันวาคม',
		accountsPayableAging: "อายุบัญชีเจ้าหนี้",
		accountsReceivableAging:"อายุลูกหนี้การค้า",
		pastDue1To30: "1 - 30",
		pastDue31To90: "31 - 90",
		pastDue91To180: "91 - 180",
		pastDue181To360: "181 - 360",
		pastDueOver360: "> 360",
		numberOfInvoices: "จำนวนใบแจ้งหนี้ทั้งหมด",
		fundOrClaimNum: "บัญชีกองทุน/เลขที่เคลม",
		contractingCompany: "บริษัทรับเหมา",
		acceptedBy: "ผู้รับวางบิล",
		operatingRoom: "ห้องผ่าตัด",
		anesthesia: "การดมยาสลบ",
		fastingDateTime: "วันที่และเวลาของการถือศีลอด",
		preOperativeDiag: "การวินิจฉัยก่อนการผ่าตัด",
		operativeDoctor: "แพทย์ผู้ทำการผ่าตัด",
		operationProcedure: "ขั้นตอนการผ่าตัด",
		anesthesiologist: "แพทย์เฉพาะทางด้านการดมยาสลบ",
		confirmation: "การยืนยัน",
		orCase: "ประเภทของเคสในห้องผ่าตัด",
		notDueYet: "ยังไม่ถึงกำหนด",
		overDue: "เกินกำหนดชำระ",
		overDueDays: "เกินกำหนดชำระ (วัน)",
		calendar: "ปฏิทิน",
		week:"สัปดาห์",
		balanceAdjustment: "การปรับปรุงยอดเจ้าหนี้",
		exclusiveWokflow: "ไม่รวมอยู่ในกระบวนการ",
		selectable: "ที่สามารถเลือกได้",
		cashierPayment: "วิธีการชำระเงินของแคชเชียร์",
		supplierPricePerOrder: "ราคาต่อหน่วยซื้อ (ผู้จัดหา)",
		discountReceipt: "ลดราคา (ใบเสร็จ)",
		doctorFee: "ค่าหมอ",
		anesthesiologistFee: "ค่าธรรมเนียมผู้เชี่ยวชาญด้านการดมยาสลบ",
		estimatedLengthOfStay: "ระยะเวลาการพักอาศัยที่ประมาณการไว้",
		surgicalAuthorization:"การอนุมัติการผ่าตัด",
		load:"โหลด",
		preOperativeDiagICD: "การวินิจฉัยก่อนการผ่าตัด (ICD)",
		operationProcedureICD: "ขั้นตอนการผ่าตัด (ICD)",
		DRG: "DRG",
		pricePerDRG: "ราคา/DRG",
		rejectedBy: "ถูกปฏิเสธโดย",
		inspectedBy: "ตรวจสอบโดย",
		cancelledBy: "ถูกยกเลิกโดย",
		totalEstimatedBudget: "งบประมาณโดยประมาณรวม",
		workflowProperty: "คุณสมบัติของโมดูล",
		minimumCost: "ต้นทุนต่ำสุด",
		success: "ความสำเร็จ",
		failed: "ล้มเหลว",
		surgeon:"ศัลยแพทย์",
		reschedule: "เลื่อนกำหนดการ",
		appointmentHistory: "ประวัติการนัดหมาย",
		preAnesthesiaRecord: "Pre-Anesthesia Record",
		intraAnesthesiaRecord: "Intra-Anesthesia Record",
		postAnesthesiaRecord:"Post-Anesthesia Record",
		ga: "GA",
		ASAClass: "ASA Class",
		mouthOpen: "Mouth Open",
		dentures: "Dentures",
		dentalDamage: "Dental Damage",
		thyromental: "Thyromental",
		hyomentalDistance: "Hyomental Distance",
		neck: "Neck",
		mallampatiScore: "Mallampati Score",
		expectedDifficultAirway: "Expected Difficult Airway",
		neurologicalAssessment: "Neurological Assessment",
		backExamination: "Back Examination",
		cardiovascular: "Cardiovascular",
		respiratory: "Respiratory",
		gastrointestinal: "Gastrointestinal",
		endocrine: "Endocrine",
		hematoOncology: "Hemato/Oncology",
		renal: "Renal",
		completeBloodCount: "Complete Blood Count",
		WBC:"WBC",
		hgb: "Hgb",
		hct: "Hct",
		platelets:"Platelets",
		electrolytes: "Electrolytes",
		na: "Na",
		cl: "Cl",
		k: "K",
		hco3: "HCO3",
		bun: "BUN",
		cr: "Cr",
		glucose: "Glucose",
		liverFunctionTest: "Liver Function Test",
		albumin: "Albumin",
		globulin: "Globulin",
		AST: "AST",
		ALT: "ALT",
		totalBilirubin: "Total Bilirubin",
		directBilirubin: "Direct Bilirubin",
		alkalinePhosphatase: "Alkaline Phosphatase",
		coagulation: "Coagulation",
		PT: "PT",
		PTT:"PTT",
		INR:"INR",
		EKG: "EKG",
		CXR: "CXR",
		antiHIV: "Anti-HIV",
		crossMatching: "Cross-Matching",
		packedRedCell: "Packed Red Cell",
		freshFrozenPlasma: "Fresh Frozen Plasma",
		plateletConcentrate: "Platelet Concentrate",
		anesthesiaPlan: "Anesthesia Plan",
		anesthesiaPremedication: "Anesthesia Premedication",
		airwayAssessment: "Airway Assessment",
		normal: "Normal",
		abnormal:"Abnormal",
		obese: "Obese",
		cachexia: "Cachexia",
		pale: "Pale",
		jaundice: "Jaundice",
		cyanosis: "Cyanosis",
		emergency: "Emergency",
		limited: "Limited",
		ventilation: "Ventilation",
		intubation: "Intubation",
		mentalRetard: "Mental Retard",
		oldCVAs: "Old CVAs",
		seizuresEpilepsy: "Seizures/Epilepsy",
		chronicPain: "Chronic Pain",
		myopathy: "Myopathy",
		hypertension: "Hypertension",
		controlled: "Controlled",
		poorlyControlled: "Poorly Controlled",
		functionalCapacity: "Functional Capacity",
		CAD: "CAD",
		arrhythmia: "Arrhythmia",
		dyslipidemia: "Dyslipidemia",
		asthma: "Asthma",
		COPD: "COPD",
		TB: "TB",
		recentURI: "Recent URI",
		fullStomach: "Full Stomach",
		hepatitis: "Hepatitis",
		cirrhosis: "Cirrhosis",
		DM: "DM",
		hypoHyperthyroid: "Hypo/Hyperthyroid",
		steroidUse: "Steroid Use",
		anemia: "Anemia",
		bleeding: "Bleeding",
		recentChemotherapy: "Recent Chemotherapy",
		insufficiency: "Insufficiency",
		ESRD: "ESRD",
		hemodialysis: "Hemodialysis",
		bloodComponentsPrepared: "Blood/Components Prepared",
		totalIntravenous:"Total Intravenous",
		regional: "Regional",
		monitor: "Monitor",
		sedate: "Sedate",
		class1: "Class 1",
		class2: "Class 2",
		class3: "Class 3",
		class4: "Class 4",
		packPerDay: "Pack/Day",
		preOperativeCheckList: "แบบบันทึกการเตรียมผู้ป่วยก่อนการผ่าตัด",
		surgicalSafetyChecklist:"รายการตรวจสอบความปลอดภัยในการผ่าตัด",
		sender: "ผู้ส่ง",
		receiver: "ผู้รับ",
		signIn: "Sign In",
		timeOut: "Time Out",
		signOut: "Sign Out",
		BMI: "BMI",
		HRbpm: "HR(bpm)",
		SpO2: "SpO2(%)",
		NPOHour: "NPO(hr)",
		premedication: "Premedication",
		anesthesiaTechnique:"Anesthesia Technique",
		problem: "Problem",
		oxygen: "Oxygen",
		anestheticAgent: "Anesthetic Agent",
		monitoring: "Monitoring",
		anesthesiaRecord: "Anesthesia Record",
		anesthesiaStartDateTime: "Anesthesia Start Time",
		anesthesiaEndDateTime: "Anesthesia End Time",
		operativeStartDateTime: "Operative Start Time",
		operativeEndDateTime: "Operative End Time",
		medication: "Medication",
		fixAmount: "Fix Amount",
		operation: "Operation",
		NIBP: "NIBP",
		ALine: "A-Line",
		upper: "Upper",
		lower: "Lower",
		systolic: "Systolic",
		diastolic:"Diastolic",
		generalAnesthesia:"General Anesthesia",
		regionalAnesthesia: "Regional Anesthesia",
		monitorAnesthesiaCare: "Monitor Anesthesia Care",
		surgeonRecord: "Surgeon Record",
		nurseNote: "Nurse Note",
		ambulation: "Ambulation",
		nauseaOrVomitting: "Nausea/Vomitting",
		surgicalBleeding: "Surgical Bleeding",
		pain: "Pain",
		dischargeCriteria: "Discharge Criteria",
		hypotension: "Hypotension",
		airwayObstruction: "Airway Obstruction",
		hypoxia: "Hypoxia",
		urinaryRetention: "Urinary Retention",
		shivering: "Shivering",
		hypoventilation: "Hypoventilation",
		SBU: "SBU",
		anestheticTime: "Anesthetic Time",
		PACURecord: "PACU Record",
		invoiceDate: "วันที่ใบแจ้งหนี้",
		nonDispensingRx: "Non-Dispensing Rx",
	},
}

export default th
