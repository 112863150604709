import _ from "lodash"
import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { FaRegEdit } from "react-icons/fa"
import { MdDeleteForever, MdLibraryAdd } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setLoadingAction,
	setOPDNurseNotes,
	setOPDPatients,
	setSelected,
	setWarningId,
	setWarningMsgAction,
} from "~/redux/action"
import {
	SET_OPD_NURSE_DISPLAY,
	SET_OPD_NURSE_INFO_DISPLAY,
	SET_OPD_SELECTED_NURSE_NOTE,
	SET_PAGE_LOADING,
	SET_WARNING_DISPLAY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class OutpatientRightNurseNote extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "OutpatientRightNurseNote",
		}

		this.onSelectNurseNote = this.onSelectNurseNote.bind(this)
		this.onNew = this.onNew.bind(this)
		this.onModify = this.onModify.bind(this)
		this.onDelete = this.onDelete.bind(this)
		this.onDoubleNurseOrder = this.onDoubleNurseOrder.bind(this)
	}

	componentDidMount() {
		if (this.props.opd.selectedMedicalRecord.medicalRecord) {
			this.props.setOPDNurseNotes(this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord.nurseNotes)
		} else {
			this.props.setOPDNurseNotes([])
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (
				this.props.warning.isContinue &&
				_.isEqual(this.props.warning.id, ResourceAssistance.ID.HOSPITAL.opd.modalNurse.delete)
			) {
				this.deleteNurseNote()
			}
		}

		if (
			!_.isEqual(prevProps.opd.selectedMedicalRecord.medicalRecord, this.props.opd.selectedMedicalRecord.medicalRecord)
		) {
			if (this.props.opd.selectedMedicalRecord.medicalRecord) {
				this.props.setOPDNurseNotes(this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord.nurseNotes)
			} else {
				this.props.setOPDNurseNotes([])
			}
		}

		if (!_.isEqual(prevProps.opd.nurseNoteTable.filtered, this.props.opd.nurseNoteTable.filtered)) {
			let idx = this.props.opd.selectedNurseNote.nurseNote
				? this.props.opd.nurseNoteTable.filtered.findIndex(
						(each) => each.id === this.props.opd.selectedNurseNote.nurseNote.id
				  )
				: -1
			if (idx > -1) {
				this.props.setSelected(SET_OPD_SELECTED_NURSE_NOTE, idx, this.props.opd.nurseNoteTable.filtered[idx])
			} else {
				this.props.setSelected(SET_OPD_SELECTED_NURSE_NOTE, -1, null)
			}
		}
	}

	deleteNurseNote() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.opd.deleteNurseNote,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				pid: this.props.opd.selectedPatient.patient.id,
				nurseNotes: [
					{
						id: this.props.opd.selectedNurseNote.nurseNote.id,
					},
				],
			},
		}

		let callback = (res) => {
			if (res.data.patients.length > 0) {
				let patients = this.props.opd.patientTable.original.filter((each) => each.id !== res.data.patients[0].id)
				patients.push(res.data.patients[0])
				this.props.setOPDPatients(patients)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onSelectNurseNote(nurseNote, index) {
		this.props.setSelected(SET_OPD_SELECTED_NURSE_NOTE, index, this.props.opd.nurseNoteTable.filtered[index])
	}

	onNew() {
		this.props.onModalDisplayAction(SET_OPD_NURSE_DISPLAY, true)
		this.props.setSelected(SET_OPD_SELECTED_NURSE_NOTE, -1, null)
	}

	onModify(event, row, rIdx, cIdx) {
		this.props.onModalDisplayAction(SET_OPD_NURSE_DISPLAY, true)
	}

	onDelete(event, row, rIdx, cIdx) {
		let selectedNurseNote = this.props.opd.nurseNoteTable.filtered[rIdx]
		this.props.setWarningId(ResourceAssistance.ID.HOSPITAL.opd.modalNurse.delete)
		this.props.setWarningMsgAction(
			this.props.intl.formatMessage({ id: ResourceAssistance.Message.delete }) +
				"\n" +
				this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.nurseProgressNote }) +
				"\n" +
				this.props.intl.formatMessage({ id: ResourceAssistance.Message.date }) +
				": " +
				Utils.formatDateTime(selectedNurseNote.dateTime)
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	onDoubleNurseOrder(row, idx) {
		this.props.onModalDisplayAction(SET_OPD_NURSE_INFO_DISPLAY, true)
	}

	render() {
		return (
			<Row className={"full-size"}>
				<Col>
					<ScrollableTable
						enableOverlay
						enableHighlight
						striped
						hover
						displayBtn
						buttonColors={[
							ResourceAssistance.Button.variant.outlinePrimary,
							ResourceAssistance.Button.variant.outlineRed,
						]}
						btnIcons={[
							<FaRegEdit size={ResourceAssistance.ReactIcon.size} />,
							<MdDeleteForever size={ResourceAssistance.ReactIcon.size} />,
						]}
						onCheckedArray={[this.onModify, this.onDelete]}
						data={this.props.opd.nurseNoteTable}
						onClick={this.onSelectNurseNote}
						onDoubleClick={this.onDoubleNurseOrder}
						extra={
							Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.OPD.Nurse, this.props.login.user.roles) &&
							this.props.opd.selectedMedicalRecord.medicalRecord &&
							this.props.opd.selectedMedicalRecord.medicalRecord.checkInDateTime > 0 &&
							this.props.opd.selectedMedicalRecord.medicalRecord.checkOutDateTime === 0 && (
								<Button
									style={{ fontSize: "medium" }}
									variant={ResourceAssistance.Button.variant.link}
									size={ResourceAssistance.Button.size.sm}
									onClick={this.onNew}
								>
									<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
									{translate(ResourceAssistance.Message.new)}
								</Button>
							)
						}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	warning: state.modal.warning,
	login: state.login,
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setOPDNurseNotes,
			setOPDPatients,
			setSelected,
			setWarningId,
			setWarningMsgAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OutpatientRightNurseNote))
